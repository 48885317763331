import React, { useState, useEffect } from 'react';
import {motion} from 'framer-motion';
import {fadeIn} from '../../Variants'
import { useNavigate } from 'react-router-dom'; 
import axios from 'axios';
import 'react-phone-number-input/style.css'; // Import react-phone-number-input CSS
import PhoneInput from 'react-phone-number-input';
import Calendar from '../../Assets/Calendar.svg'
import USFLAG from '../../Assets/USFLAG.svg'
import Swal from 'sweetalert2';
import ReCAPTCHA from "react-google-recaptcha";
function InitialAppointment() {
    const navigate = useNavigate(); 
    const [values, setValues] = useState({
      FullName: '',
      PhoneNumber: '+1 ',
      EmailAddress: '',
      Age: '',
      Date: '',
      Time: '',
      Message: '',
      AmPm: 'AM',
    });

    const [phoneError, setPhoneError] = useState(''); // Error message for phone validation
    const [recaptchaValue, setRecaptchaValue] = useState(null); // Add reCAPTCHA value state
    const handlePhoneNumberChange = (e) => {
      let input = e.target.value;
  
      // Ensure the input starts with "+1 "
      if (!input.startsWith('+1 ')) {
          input = '+1 ';
      }
  
      // Only keep numeric characters after "+1 "
      input = '+1 ' + input.slice(3).replace(/[^0-9]/g, '');
  
      // Add spaces after the third and sixth digits after "+1 "
      if (input.length > 6) {
          input = input.slice(0, 6) + ' ' + input.slice(6);
      }
      if (input.length > 10) {
          input = input.slice(0, 10) + ' ' + input.slice(10);
      }
  
      // Limit input to "+1 XXX XXX XXXX" format (15 characters total)
      if (input.length > 15) {
          input = input.slice(0, 15);
      }
  
      setValues((prevValues) => ({
          ...prevValues,
          PhoneNumber: input,
      }));
  
      // Update error message based on length
      if (input.length < 15) {
          setPhoneError('Please enter a 10-digit phone number after "+1".');
      } else {
          setPhoneError('');
      }
  };


    const handleTimeChange = (e) => {
        const timeValue = e.target.value;
        const [hours, minutes] = timeValue.split(":");
        const hour = parseInt(hours, 10);
        const formattedTime = hour === 0 ? `12:${minutes} AM` : hour < 12 ? `${hour}:${minutes} AM` : `${hour - 12}:${minutes} PM`;
        
        setValues((prevValues) => ({
          ...prevValues,
          Time: formattedTime, // Store formatted time with AM/PM
          AmPm: hour < 12 ? "AM" : "PM", // Determine AM/PM
        }));
      };


      const handleDateChange = (e) => {
        const selectedDate = e.target.value;
        const [year, month, day] = selectedDate.split('-');
        const formattedDate = `${month}/${day}/${year}`;
        
        setValues({
          ...values,
          Date: formattedDate,
        });
      };
  


      const handleSubmit = (e) => {
        e.preventDefault();
        if (phoneError || values.PhoneNumber.length !== 15) {
          Swal.fire({
            icon: 'error',
            title: 'Invalid Phone Number',
            text: phoneError || 'Please enter a 10-digit phone number after "+1 "',
          });
          return;
        }
        if (!recaptchaValue) {
          Swal.fire({
            icon: 'error',
            title: 'Please complete the reCAPTCHA',
            text: 'You must complete the reCAPTCHA to submit the form.',
          });
          return;
        }
        axios.post('https://phoebe-compass-point-560accfa31d6.herokuapp.com/add_Appointment', values)
          .then(() => {
            navigate('/Success');
            window.location.reload();
          })
          .catch(err => {
            console.error('Error adding appointment:', err);
            navigate('/Unsuccess');
          });
      };
    
      const onRecaptchaChange = (value) => {
        setRecaptchaValue(value);
      };
  
  return (
    <section class="py-4 bg-white sm:py-8 lg:py-14">
    <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div class="max-w-2xl mx-auto text-center">
            <motion.h2 
             variants={fadeIn("down",0.2)}
        initial="hidden"
        whileInView={"show"}
        viewport={{once: false, amount:0.7}}
            class="text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-5xl font-serif">Set up Initial Appointment</motion.h2>
            <motion.p
             variants={fadeIn("zoom",0.2)}
        initial="hidden"
        whileInView={"show"}
        viewport={{once: false, amount:0.7}}
             class="mt-2 text-base font-Montserrat text-gray-800 sm:text-xl font-montserrat">   Start Your Mental Wellness Journey Today Scheduling an Initial Assessment Consultation Now</motion.p>
        </div>

        <div className="flex flex-col mt-4 md:flex-row"> 
      {/* Left Side - Modal Content */}
      <div className="w-full md:w-1/2 p-6 flex items-center justify-center">
        <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
          <form className="max-w-xl mx-auto mt-2" onSubmit={handleSubmit} action="#" method="POST">
            <div className="space-y-2">
              <div className="md:flex md:space-x-2">
                <div className="md:w-1/2">
                  <label htmlFor="FullName" className="text-base font-medium text-gray-900 font-serif">
                    Name
                  </label>
                  <div className="mt-2.5 relative">
                    <input
                      type="text"
                      name="FullName"
                      id="FullName"
                      placeholder="Full Name"
                      className="block w-full px-4 py-4 text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 rounded-md focus:outline-none focus:ring-orange-500 focus:border-orange-500 caret-orange-500 font-montserrat"
                      onChange={(e) => setValues({ ...values, FullName: e.target.value })}
                      required
                    />
                  </div>
                </div>

                <div className="md:w-1/2">
                  <label htmlFor="Age" className="text-base font-medium text-gray-900 font-serif">
                    Age
                  </label>
                  <div className="mt-2.5 relative">
                    <input
                      type="number"
                      id="Age"
                      name="Age"
                      onChange={(e) => setValues({ ...values, Age: e.target.value })}
                      className="block w-full px-4 py-4 text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 rounded-md focus:outline-none focus:ring-orange-500 focus:border-orange-500 caret-orange-500 font-montserrat"
                      placeholder="Enter Age"
                      required
                    />
                  </div>
                </div>
              </div>

              <div>
                      <label
                        htmlFor="PhoneNumber"
                        className="text-base font-medium text-gray-900 font-serif"
                      >
                        Phone Number
                      </label>
                      <div className="mt-2.5 relative">
                        <span className="absolute left-3 top-1/2 transform -translate-y-1/2">
                          <img src={USFLAG} alt="U.S. Flag" className="w-6 h-6" />
                        </span>
                        <input
                          type="text"
                          name="PhoneNumber"
                          id="PhoneNumber"
                          placeholder="Enter Phone Number"
                          value={values.PhoneNumber}
                          maxLength="15"
                          className="block w-full px-12 py-4 text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 rounded-md focus:outline-none focus:ring-orange-500 focus:border-orange-500 caret-orange-500 font-montserrat"
                          onChange={handlePhoneNumberChange}
                          required
                        />
                      </div>
                      {phoneError && (
                          <p className="mt-1 text-red-600 text-sm font-medium">{phoneError}</p>
                        )}
                    </div>

              <div>
                <label htmlFor="EmailAddress" className="text-base font-medium text-gray-900 font-serif">
                  Email Address
                </label>
                <div className="mt-2.5 relative">
                  <input
                    type="email"
                    name="EmailAddress"
                    id="EmailAddress"
                    placeholder="Enter your Email Address"
                    className="block w-full px-4 py-4 text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 rounded-md focus:outline-none focus:ring-orange-500 focus:border-orange-500 caret-orange-500 font-montserrat"
                    onChange={(e) => setValues({ ...values, EmailAddress: e.target.value })}
                    required
                  />
                </div>
              </div>

              <div className="flex flex-col space-y-4 md:flex-row md:space-x-4 md:space-y-0">
              <div className="w-full md:w-1/2">
      <label htmlFor="Date" className="text-base font-medium text-gray-900 font-serif">
        Select Preferred Date
      </label>
      <div className="mt-2.5 relative">
        <input
          type="date"
          name="Date"
          id="Date"
          className="block w-full px-4 py-4 text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 rounded-md focus:outline-none focus:ring-orange-500 focus:border-orange-500 caret-orange-500 font-montserrat"
          onChange={handleDateChange}
          required
        />
      </div>
      <p className='hidden'>Selected Date: {values.Date}</p> {/* Optional display of the formatted date */}
    </div>

  <div className="w-full md:w-1/2">
    <label
      htmlFor="Time"
      className="text-base font-medium text-gray-900 font-serif"
    >
      Select Preferred Time
    </label>
    <div className="mt-2.5 relative">
      <input
        type="time"
        name="Time"
        id="Time"
        className="block w-full px-4 py-4 text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 rounded-md focus:outline-none focus:ring-orange-500 focus:border-orange-500 caret-orange-500 font-montserrat"
        onChange={handleTimeChange}
        required
      />
    </div>
    <div className="mt-2 text-gray-600 hidden">
      {values.Time && <span>{values.AmPm}</span>} {/* Display AM/PM */}
    </div>
  </div>
</div>
<div>
      <label
        htmlFor="Message"
        className="text-base font-medium text-gray-900 font-serif"
      >
        Message
      </label>
      <div className="mt-2.5 relative">
        <textarea
          name="Message"
          id="Message"
          placeholder="Question/Comment"
          className="block w-full px-4 py-4 text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 rounded-md focus:outline-none focus:ring-orange-500 focus:border-orange-500 caret-orange-500 font-montserrat"
          onChange={(e) => setValues({ ...values, Message: e.target.value })}
          required
        />
      </div>
    </div>

    <div className='flex items-center justify-center '>
    <ReCAPTCHA
    sitekey="6LeToXgqAAAAAHAofZ7ugg8wZR35wkuWE5M1-P1O"
    onChange={onRecaptchaChange}
  />
  </div>



              <div>
                <button type="submit" className="inline-flex items-center justify-center w-full px-4 py-3 text-sm font-semibold text-white transition-all duration-200 bg-[#4ADE80] border border-transparent rounded-md hover:bg-[#34C759] focus:bg-[#34C759]">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-5 w-5 mr-2">
                    <path strokeLinecap="round" strokeLinejoin="round" d="m15.75 10.5 4.72-4.72a.75.75 0 0 1 1.28.53v11.38a.75.75 0 0 1-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25h-9A2.25 2.25 0 0 0 2.25 7.5v9a2.25 2.25 0 0 0 2.25 2.25Z" />
                  </svg>
                  Schedule Virtual Appointment
                </button>
              </div>
            </div>
          </form>

          <div className="flex items-center justify-start mt-2 sm:justify-center sm:px-0">
  <svg className="flex-shrink-0 w-5 h-5 text-gray-600 hidden lg:block" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    {/* Hidden on small screens */}
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"></path>
  </svg>
  <span className="ml-2 text-sm text-gray-600 font-montserrat text-center sm:text-left">
    Your data is completely secured with us. We don’t share it with anyone.
  </span>
</div>

        </div>
      </div>

      {/* Right Side - SVG Image */}
      <div className="w-full md:w-1/2 flex items-center justify-center p-6">
        <img
          src={Calendar}
          alt="Mental Wellness Illustration"
          className="w-full h-auto hidden md:block" 
        />
      </div>
    </div>




         



    </div>
</section>
  )
}

export default InitialAppointment