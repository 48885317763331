import Anxiety from "../../../components/WebsiteComponents/ServicesComponent/Anxiety";
import Anxietydetails from "../../../components/WebsiteComponents/ServicesComponent/AnxietyDetails";

export const AnxietyPage = () => {
    return (
        <> 
      <Anxiety/>
      <Anxietydetails/>
        </>
    );
}
export default AnxietyPage;