import { Routes, Route, useLocation, Link } from "react-router-dom";
import AdminNav from '../../components/AdminComponents/AdminDashboardComponent/AdminNav';
import AdminAppointment from '../../components/AdminComponents/AdminDashboardComponent/AdminAppointment';
import AdminNewsLetter from '../../components/AdminComponents/AdminDashboardComponent/AdminNewsLetter';
import Dashboard from '../../components/AdminComponents/AdminDashboardComponent/Dashboard';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Disclosure, Menu } from '@headlessui/react';
import { Bars3Icon, BellIcon, XMarkIcon, HomeIcon, CalendarIcon, DocumentIcon } from '@heroicons/react/24/outline';
import Logo from '../../Assets/Logo.png';
import Swal from 'sweetalert2';
export const AdminDashboard = () => {
  const location = useLocation();
  const [auth, setAuth] = useState(false);
  const [message, setMessage] = useState('');
  const [AdminUsername, setAdminUsername] = useState('');

  const navigate = useNavigate();
  axios.defaults.withCredentials = true;

  useEffect(() => {
    axios.get('https://phoebe-compass-point-560accfa31d6.herokuapp.com/AdminDashboard')
      .then(res => {
        if (res.data.Status === "Success") {
          setAuth(true);
          setAdminUsername(res.data.AdminUsername);
        } else {
          setAuth(false);
          setMessage(res.data.Error);
        }
      })
      .catch(err => console.log(err));
  }, []);
  
  const handleDelete = () => {
    axios.get('https://phoebe-compass-point-560accfa31d6.herokuapp.com/logout', {
      withCredentials: true // Ensure that credentials (cookies) are included in the request
    })
    .then(res => {
      if (res.data.Status === "Success") {
        Swal.fire({
          title: 'Logged out!',
          text: 'You have been successfully logged out.',
          icon: 'success',
          confirmButtonText: 'OK'
        }).then(() => {
          // Reload the page after user confirmation
          window.location.reload(true); // Forces the page to reload from the server
        });
      }
    })
    .catch(err => console.log(err));
  };
  
  // Ensure that location is defined, usually from useLocation() from react-router-dom

  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  

  return (
    <div>
      {
        auth ?
          <div className="min-h-full">
            <Disclosure as="nav" className="bg-gray-800">
              {({ open }) => (
                <>
                  <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                    <div className="flex h-16 items-center justify-between">
                      <div className="flex items-center">
                        <div className="flex-shrink-0">
                          <span className="text-white text-2xl font-bold font-serif">Phoebe Compass Point</span>
                        </div>
                      </div>
                      <div className="hidden md:flex md:items-center md:ml-10">
                        <div className="flex items-baseline space-x-4">
                          <Link to="/AdminDashboard" className="text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-sm font-medium">
                            <HomeIcon className="h-5 w-5 inline-block mr-1 align-middle" aria-hidden="true" /> Dashboard
                          </Link>
                          <Link to="AdminAppointment" className="text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-sm font-medium">
                            <CalendarIcon className="h-5 w-5 inline-block mr-1 align-middle" aria-hidden="true" /> Appointment
                          </Link>
                          <Link to="AdminNewsLetter" className="text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-sm font-medium">
                            <DocumentIcon className="h-5 w-5 inline-block mr-1 align-middle" aria-hidden="true" /> News Letter Email
                          </Link>
                        </div>
                        <div className="ml-4 flex items-center md:ml-6">
                          <Menu as="div" className="relative ml-3">
                            <div>
                              <Menu.Button className="flex max-w-xs items-center rounded-full bg-gray-800 border-2 border-gray-200 px-2 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                                <span className="sr-only">Open user menu</span>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6 text-white ">
  <path fill-rule="evenodd" d="M7.5 6a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0ZM3.751 20.105a8.25 8.25 0 0 1 16.498 0 .75.75 0 0 1-.437.695A18.683 18.683 0 0 1 12 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 0 1-.437-.695Z" clip-rule="evenodd" />
</svg>
                              </Menu.Button>
                            </div>
                            <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                              <Menu.Item>
                                {({ active }) => (
                                  <Link to="#" onClick={() => document.getElementById('my_modal_3').showModal()} className={`block px-4 py-2 text-sm ${active ? 'bg-gray-100' : 'text-gray-700'}`}>
                                    Your Profile
                                  </Link>
                                )}
                              </Menu.Item>
                              <Menu.Item>
                                {({ active }) => (
                                  <Link to="#" className={`block px-4 py-2 text-sm ${active ? 'bg-gray-100' : 'text-gray-700'}`}>
                                    Settings
                                  </Link>
                                )}
                              </Menu.Item>
                              <Menu.Item>
                                {({ active }) => (
                                  <Link to="#" onClick={handleDelete} className={`block px-4 py-2 text-sm ${active ? 'bg-gray-100' : 'text-gray-700'}`}>
                                    Sign out
                                  </Link>
                                )}
                              </Menu.Item>
                            </Menu.Items>
                          </Menu>
                        </div>
                      </div>
                      <div className="-mr-2 flex md:hidden">
                        <Disclosure.Button className="inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                          <span className="sr-only">Open main menu</span>
                          {open ? <XMarkIcon className="block h-6 w-6" aria-hidden="true" /> : <Bars3Icon className="block h-6 w-6" aria-hidden="true" />}
                        </Disclosure.Button>
                      </div>
                    </div>
                  </div>

                  <Disclosure.Panel className="md:hidden">
                    <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
                      <Link to="/AdminDashboard" className="block rounded-md px-3 py-2 text-base font-medium text-white bg-gray-900">Dashboard</Link>
                      <Link to="AdminAppointment" className="block rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white">Appointment</Link>
                      <Link to="AdminNewsLetter" className="block rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white">News Letter</Link>
                    </div>
                    <div className="border-t border-gray-700 pb-3 pt-4">
                      <div className="flex items-center px-5">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6 text-white ">
  <path fill-rule="evenodd" d="M7.5 6a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0ZM3.751 20.105a8.25 8.25 0 0 1 16.498 0 .75.75 0 0 1-.437.695A18.683 18.683 0 0 1 12 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 0 1-.437-.695Z" clip-rule="evenodd" />
</svg>
                        <div className="ml-3">
                          <div className="text-base font-medium leading-none text-white">{AdminUsername.AdminUsername}</div>
                          <div className="text-sm font-medium leading-none text-gray-400">{AdminUsername.AdminEmail}</div>
                        </div>
                        <button type="button" className="ml-auto flex-shrink-0 rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                          <span className="sr-only">View notifications</span>
                          <BellIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                      <div className="mt-3 space-y-1 px-2">
                        <Link to="#" onClick={() => document.getElementById('my_modal_3').showModal()} className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white">
                          Your Profile
                        </Link>
                        <Link to="#" className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white">
                          Settings
                        </Link>
                        <Link to="#" onClick={handleDelete} className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white">
                          Sign out
                        </Link>
                      </div>
                    </div>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>

            <dialog id="my_modal_3" className="modal">
              <div className="modal-box rounded-none">
                <form method="dialog">
                  <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
                </form>
                <h3 className="font-bold text-lg">Welcome {AdminUsername.AdminUsername}</h3>
                <p>Email: {AdminUsername.AdminEmail}</p>
              </div>
            </dialog>

            <Routes>
              {/* This matches /AdminDashboard */}
              <Route path="/" element={<Dashboard />} />
              {/* Nested routes */}
              <Route path="AdminAppointment" element={<AdminAppointment />} />
              <Route path="AdminNewsLetter" element={<AdminNewsLetter />} />
            </Routes>
          </div>
          :
          <div className="grid h-screen place-content-center bg-white px-4">
            <div className="text-center">
              <h1 className="text-9xl font-black text-gray-200">Error 404</h1>
              <p className="text-2xl font-bold tracking-tight text-gray-900 sm:text-4xl">You are not Authenticated</p>
              <p className="mt-4 text-gray-500">We can't find that page.</p>

              <Link to="/Login"
                className="mt-6 inline-block rounded bg-[#4ADE80] px-5 py-3 text-sm font-medium text-white hover:bg-[#34C759] focus:outline-none focus:ring"
              >
                Login an Account
              </Link>
            </div>
          </div>
      }
    </div>
  );
};

export default AdminDashboard;
