import React, { useState } from 'react'
import { Link } from "react-router-dom";
function Signup() {
  return (
    <div className="grid h-screen place-content-center bg-white px-4">
    <div className="text-center">
      <h1 className="text-9xl font-black text-gray-200">Error 404</h1>
      <p className="text-2xl font-bold tracking-tight text-gray-900 sm:text-4xl">You are not Authenticated</p>
      <p className="mt-4 text-gray-500">We can't find that page.</p>

      <Link to="/Login"
        className="mt-6 inline-block rounded bg-[#4ADE80] px-5 py-3 text-sm font-medium text-white hover:bg-[#34C759] focus:outline-none focus:ring"
      >
        Login an Account
      </Link>
    </div>
  </div>
  )
}

export default Signup