import AdminLogin from '../../components/AdminComponents/AdminLoginComponents/Adminlogin'
export const Login = () => {
    return (
        <> 
       <AdminLogin/>
           
        
    </>
       
    );
}
