import Relationship from "../../../components/WebsiteComponents/ServicesComponent/Relationship";
import RelationshipDetails from "../../../components/WebsiteComponents/ServicesComponent/RelationshipDetails";

export const RelationshipPage = () => {
    return (
        <> 
    <Relationship/>
    <RelationshipDetails/>
        </>
    );
}
export default RelationshipPage;