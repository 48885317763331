import Grief from "../../../components/WebsiteComponents/ServicesComponent/Grief";
import GriefDetails from "../../../components/WebsiteComponents/ServicesComponent/GriefDetails";


export const GriefPage = () => {
    return (
        <> 
     <Grief/>
     <GriefDetails/>
        </>
    );
}
export default GriefPage;