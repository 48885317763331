import ADHD from "../../../components/WebsiteComponents/ServicesComponent/ADHD";
import ADHDDetails from "../../../components/WebsiteComponents/ServicesComponent/ADHDDetails";

export const ADHDPage = () => {
    return (
        <> 
<ADHD/>
<ADHDDetails/>
        </>
    );
}
export default ADHDPage;