import React, { useEffect } from 'react';
import Logo from '../../Assets/Logo.png';
function Success() {
  useEffect(() => {
    // Check if gtag is available and trigger conversion event
    if (typeof window.gtag === 'function') {
      window.gtag('event', 'conversion', {
        'send_to': 'AW-16727041545/QFR4CPTx5tsZEInMiag-'
      });
    } else {
      console.warn("gtag is not defined. Make sure Google Analytics is loaded.");
    }
  }, []);

  return (
   
<section class="py-4 bg-white sm:py-8 lg:py-14">
    <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
      
    <div className="relative flex flex-col items-center max-w-5xl mx-auto sm:px-6 lg:px-8">
          {/* Main Content */}
          <div className="flex justify-between items-center max-w-7xl px-4 mx-auto sm:px-6 lg:px-8">
          <div className="max-w-2xl mx-auto text-center">
  <div className="flex items-center justify-center">
    <img alt="Logo" src={Logo} className="h-16 border-2 border-gray-200 w-auto ml-2 bg-white rounded-lg p-2" />
  </div>
  <h2 className="mt-4 text-2xl sm:text-xl md:text-2xl lg:text-3xl font-bold leading-tight text-black lg:mt-2 font-serif">
    Thank you so much for Booking your Appointment with us!
  </h2>
  <p className="mt-2 text-lg p-4 text-gray-800 font-medium flex items-center justify-center border border-gray-200 w-fit mx-auto font-montserrat">
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="size-6 mr-2">
      <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75" />
    </svg>
    Check your Email!
  </p>
  <p className="max-w-6xl mx-auto mt-4 text-md text-gray-600 md:mt-4 font-montserrat">
    We appreciate you choosing us for your virtual appointment. A confirmation email has been sent to the address you provided, 
    which includes all the necessary details for your appointment. Please check your inbox (and spam folder, just in case) for the confirmation email.
  </p>
</div>
        </div>
        </div>
    </div>
</section>



  );
}

export default Success;
