import BehavioralIssues from "../../../components/WebsiteComponents/ServicesComponent/BehavioralIssues";
import BehavioralissuesDetails from "../../../components/WebsiteComponents/ServicesComponent/BehavioralissuesDetails";

export const BehavioralissuePage = () => {
    return (
        <> 
     <BehavioralIssues/>
     <BehavioralissuesDetails/>
        </>
    );
}
export default BehavioralissuePage;