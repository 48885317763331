import React from 'react';
import anxiety from '../../Assets/anxiety.jpg'
import angermanagement from '../../Assets/Anger Management.jpg'
import behaviour from '../../Assets/Behavioral Issues.jpg'
import depression from '../../Assets/Depression.jpg'
import grief from '../../Assets/Grief.jpg'
import medication from '../../Assets/Medication Management.jpg'
import relationship from '../../Assets/Relationship Issues.jpg'
import truama from '../../Assets/Trauma and PTSD.jpg'
import ADHD from '../../Assets/ADHD.jpg'
import { motion } from 'framer-motion';
import { fadeIn } from '../../Variants';
import { useNavigate } from 'react-router-dom';
function Services() {
  const navigate = useNavigate(); 

    const handleNavigation = (path) => {
      navigate(path);
    };
  
  return (
  
  
    <section class="py-10 bg-gradient-to-b from-green-50 to-green-100 sm:py-16 lg:py-16">
    <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div class="max-w-4xl mx-auto text-center">
            <motion.h2 
             variants={fadeIn("down",0.2)}
        initial="hidden"
        whileInView={"show"}
        viewport={{once: false, amount:0.7}}
            class="text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-5xl font-serif">Services We Offer</motion.h2>
<motion.p
  variants={fadeIn("zoom", 0.2)}
  initial="hidden"
  whileInView="show"
  viewport={{ once: false, amount: 0.7 }}
  className="mt-2 text-sm sm:text-base md:text-lg lg:text-xl font-montserrat text-gray-800 font-Montserrat leading-relaxed sm:leading-normal"
>
  At Phoebe Compass Point, we offer personalized mental health care, addressing a range of concerns. We provide a <span className="underline">Free Initial Consultation</span> for adults 18 and older. In order to make your experience as smooth and hassle-free as possible , <span className='font-semibold'>Headway</span> and <span className='font-semibold'>Zocdoc</span> manage our appointment scheduling and billing services.
</motion.p>

 <div className="grid grid-cols-1 justify-center items-center px-10 mt-4 text-center gap-x-8 gap-y-6 sm:grid-cols-2 md:grid-cols-3 sm:px-0">
  {/* Feature list */}
  <motion.div
    variants={fadeIn('zoom', 0.2)}
    initial="hidden"
    whileInView="show"
    viewport={{ once: false, amount: 0.7 }}
    className="flex items-center justify-center"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      className="h-10 w-10 sm:h-12 sm:w-12"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z"
      />
    </svg>
    <p className="ml-2 text-xs sm:text-sm font-semibold text-gray-800 font-montserrat">
      Improved Mental health
    </p>
  </motion.div>

  <motion.div
    variants={fadeIn('zoom', 0.4)}
    initial="hidden"
    whileInView="show"
    viewport={{ once: false, amount: 0.7 }}
    className="flex items-center justify-center"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      className="h-10 w-10 sm:h-12 sm:w-12"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M9 12.75L11.25 15 15 9.75m-3-7.036A11.959 11.959 0 0 1 3.598 6 11.99 11.99 0 0 0 3 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285Z"
      />
    </svg>
    <p className="ml-2 text-xs sm:text-sm font-semibold text-gray-800 font-montserrat">
      Mental health checkups
    </p>
  </motion.div>

  <motion.div
    variants={fadeIn('zoom', 0.6)}
    initial="hidden"
    whileInView="show"
    viewport={{ once: false, amount: 0.7 }}
    className="flex items-center justify-center"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      className="h-10 w-10 sm:h-12 sm:w-12"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M10.05 4.575a1.575 1.575 0 1 0-3.15 0v3m3.15-3v-1.5a1.575 1.575 0 0 1 3.15 0v1.5m-3.15 0 .075 5.925m3.075.75V4.575m0 0a1.575 1.575 0 0 1 3.15 0V15M6.9 7.575a1.575 1.575 0 1 0-3.15 0v8.175a6.75 6.75 0 0 0 6.75 6.75h2.018a5.25 5.25 0 0 0 3.712-1.538l1.732-1.732a5.25 5.25 0 0 0 1.538-3.712l.003-2.024a.668.668 0 0 1 .198-.471 1.575 1.575 0 1 0-2.228-2.228 3.818 3.818 0 0 0-1.12 2.687M6.9 7.575V12m6.27 4.318A4.49 4.49 0 0 1 16.35 15m.002 0h-.002"
      />
    </svg>
    <p className="ml-2 text-xs sm:text-sm font-semibold text-gray-800 font-montserrat">
      Secure online support
    </p>
  </motion.div>
</div>
        </div>

        <div class="flex items-center justify-center flex-col mt-2 md:flex-row md:space-x-4">
        <a 
          href="https://headway.co/providers/febbie-mutindori?utm_source=pem&utm_medium=direct_link&utm_campaign=88474" 
          target="_blank" 
          rel='noreferrer'
          class="btn bg-green-600 inline-flex items-center text-white  hover:bg-green-700 hover:text-white border-green-500 rounded px-5 py-2.5 w-full md:w-auto justify-center font-montserrat mb-4 md:mb-0"
        >
          <img src="https://res.cloudinary.com/dgl9hqn2p/image/upload/v1731334171/jpkr8u9zpc1mewwpzuzf.png" alt="Headway Icon" class="h-5 w-5 mr-2"/>
          Book in Headway Calendar
        </a>

        <a 
          href="https://www.zocdoc.com/doctor/febbie-mutindori-arnp-524312?dd_referrer=https%3A%2F%2Fwww.google.com%2F" 
          target="_blank" 
           rel='noreferrer'
          class="btn bg-green-600 inline-flex items-center text-white  hover:bg-green-700 hover:text-white border-green-500 rounded px-5 py-2.5 w-full md:w-auto justify-center font-montserrat"
        >
          <img src="https://res.cloudinary.com/dgl9hqn2p/image/upload/v1731334455/Zocdoc_tcuasp.png" alt="Zocdoc Icon" class="h-5 w-5 mr-2"/>
          Book in Zocdoc Calendar
        </a>
    </div>

            <div class="grid max-w-md grid-cols-1 mx-auto mt-4 lg:max-w-full lg:mt-8 lg:grid-cols-3 gap-x-16 gap-y-12">
            <div>
                <div href="#" title="" class="block aspect-w-4 aspect-h-3">
                    <img class="object-cover w-full h-full" src={anxiety} alt="" />
                </div>
                <span class="inline-flex px-4 py-2 text-xs font-semibold tracking-widest uppercase rounded-full text-gray-100 bg-gray-600 mt-9"> Service Offered </span>
                <p class="mt-2 text-xl font-semibold">
                    <motion.a
                      variants={fadeIn("zoom",0.2)}
        initial="hidden"
        whileInView={"show"}
        viewport={{once: false, amount:0.7}}
                     href="#" title="" class="text-black font-serif"> Anger Management </motion.a>
                </p>
                <motion.p
                      variants={fadeIn("zoom",0.2)}
        initial="hidden"
        whileInView={"show"}
        viewport={{once: false, amount:0.7}}
                 class="mt-4 text-gray-600 font-montserrat font-medium">Our anger management program helps individuals understand the root causes of their anger, develop healthier coping mechanisms, and learn practical skills to manage their emotions in challenging situations.</motion.p>
           
           <div className="flex justify-center lg:justify-start items-center">
  <button
    className="btn bg-green-600 text-white rounded-md hover:bg-green-700 mt-4 font-montserrat"
    onClick={() => handleNavigation('/Services/AngerManagement')}
  >
  
    Learn More 
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-6 mr-2">
  <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
</svg>
  </button>
</div>
           
           
            </div>

            <div>
                <div href="#" title="" class="block aspect-w-4 aspect-h-3">
                    <img class="object-cover w-full h-full" src={angermanagement} alt="" />
                </div>
              <span class="inline-flex px-4 py-2 text-xs font-semibold tracking-widest uppercase rounded-full text-gray-100 bg-gray-600 mt-9"> Service Offered </span>
                <p class="mt-2 text-xl font-semibold">
                    <motion.a 
                            variants={fadeIn("zoom",0.2)}
        initial="hidden"
        whileInView={"show"}
        viewport={{once: false, amount:0.7}}
                    href="#" title="" class="text-black font-serif"> Anxiety </motion.a>
                </p>
                <motion.p
                     variants={fadeIn("zoom",0.2)}
        initial="hidden"
        whileInView={"show"}
        viewport={{once: false, amount:0.7}}
                 class="mt-4 text-gray-600 font-montserrat font-medium">We offer treatments to help individuals manage anxiety, whether it’s generalized anxiety, social anxiety, or panic disorders. Through therapy and medication management, we aim to reduce symptoms and improve overall quality of life.</motion.p>
                   
                   <div className="flex justify-center lg:justify-start items-center">
  <button
   onClick={() => handleNavigation('/Services/Anxiety')}
    className="btn bg-green-600 text-white rounded-md hover:bg-green-700 mt-4 font-montserrat"
  >
    Learn More 
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-6 mr-2">
  <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
</svg>
  </button>
</div>
         
            </div>

            <div>
                <div href="#" title="" class="block aspect-w-4 aspect-h-3">
                    <img class="object-cover w-full h-full" src={behaviour} alt="" />
                </div>
              <span class="inline-flex px-4 py-2 text-xs font-semibold tracking-widest uppercase rounded-full text-gray-100 bg-gray-600 mt-9"> Service Offered </span>
                <p class="mt-2 text-xl font-semibold">
                    <motion.a
                      variants={fadeIn("zoom",0.2)}
        initial="hidden"
        whileInView={"show"}
        viewport={{once: false, amount:0.7}}  href="#" title="" class="text-black font-serif"> Behavioral Issues 
                    </motion.a>
                </p>
                <motion.p
                  variants={fadeIn("zoom",0.2)}
        initial="hidden"
        whileInView={"show"}
        viewport={{once: false, amount:0.7}} class="mt-4 text-gray-600 font-montserrat font-medium">We address a wide range of behavioral issues, helping individuals especially children and adolescents manage impulsive or disruptive behaviors, improve emotional regulation, and develop better social skills.
                </motion.p>
                    
                <div className="flex justify-center lg:justify-start items-center">
  <button
   onClick={() => handleNavigation('/Services/BehavioralIssues')}
    className="btn bg-green-600 text-white rounded-md hover:bg-green-700 mt-4 font-montserrat"
  >
    Learn More 
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-6 mr-2">
  <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
</svg>
  </button>
</div>      
                
            </div>




            <div>
                <div href="#" title="" class="block aspect-w-4 aspect-h-3">
                    <img class="object-cover w-full h-full" src={depression} alt="" />
                </div>
              <span class="inline-flex px-4 py-2 text-xs font-semibold tracking-widest uppercase rounded-full text-gray-100 bg-gray-600 mt-9"> Service Offered </span>
                <p class="mt-2 text-xl font-semibold">
                    <motion.a
                      variants={fadeIn("zoom",0.2)}
        initial="hidden"
        whileInView={"show"}
        viewport={{once: false, amount:0.7}} href="#" title="" class="text-black font-serif"> Depression 
                    </motion.a>
                </p>
                <motion.p
                  variants={fadeIn("zoom",0.2)}
        initial="hidden"
        whileInView={"show"}
        viewport={{once: false, amount:0.7}} class="mt-4 text-gray-600 font-montserrat font-medium">Our depression treatment focuses on comprehensive care, from diagnosing the root causes to developing personalized therapy plans. We provide support for those struggling with mood disorders, helping them regain a sense of hope and stability.
                </motion.p>
                
                        
           <div className="flex justify-center lg:justify-start items-center">
  <button
   onClick={() => handleNavigation('/Services/Depression')}
    className="btn bg-green-600 text-white rounded-md hover:bg-green-700 mt-4 font-montserrat"
  >
    Learn More 
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-6 mr-2">
  <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
</svg>
  </button>
</div>  
            </div>


            <div>
                <div href="#" title="" class="block aspect-w-4 aspect-h-3">
                    <img class="object-cover w-full h-full" src={grief} alt="" />
                </div>
              <span class="inline-flex px-4 py-2 text-xs font-semibold tracking-widest uppercase rounded-full text-gray-100 bg-gray-600 mt-9"> Service Offered </span>
                <p class="mt-2 text-xl font-semibold">
                    <motion.a
                      variants={fadeIn("zoom",0.2)}
        initial="hidden"
        whileInView={"show"}
        viewport={{once: false, amount:0.7}} href="#" title="" class="text-black font-serif"> Grief 
                    </motion.a>
                </p>
                <motion.p
                  variants={fadeIn("zoom",0.2)}
        initial="hidden"
        whileInView={"show"}
        viewport={{once: false, amount:0.7}} class="mt-4 text-gray-600 font-montserrat font-medium">Our grief counseling helps individuals navigate the emotional pain of losing a loved one, offering support and tools to process emotions, find closure, and eventually move toward healing.
                </motion.p>
                          
           <div className="flex justify-center lg:justify-start items-center">
  <button
    onClick={() => handleNavigation('/Services/Grief')}
    className="btn bg-green-600 text-white rounded-md hover:bg-green-700 mt-4 font-montserrat"
  >
    Learn More 
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-6 mr-2">
  <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
</svg>
  </button>
</div>
                
            </div>


            <div>
                <div href="#" title="" class="block aspect-w-4 aspect-h-3">
                    <img class="object-cover w-full h-full" src={medication} alt="" />
                </div>
              <span class="inline-flex px-4 py-2 text-xs font-semibold tracking-widest uppercase rounded-full text-gray-100 bg-gray-600 mt-9"> Service Offered </span>
                <p class="mt-2 text-xl font-semibold">
                    <motion.a
                      variants={fadeIn("zoom",0.2)}
        initial="hidden"
        whileInView={"show"}
        viewport={{once: false, amount:0.7}} href="#" title="" class="text-black font-serif"> Medication Management
                     </motion.a>
                </p>
                <motion.p
                  variants={fadeIn("zoom",0.2)}
        initial="hidden"
        whileInView={"show"}
        viewport={{once: false, amount:0.7}} class="mt-4 text-gray-600 font-montserrat font-medium">For those who require medication as part of their mental health treatment, we offer ongoing medication management services, ensuring safe and effective use while monitoring for side effects and adjusting dosages as needed.
                </motion.p>
                
                          
           <div className="flex justify-center lg:justify-start items-center">
  <button
   onClick={() => handleNavigation('/Services/MedicationManagement')}
    className="btn bg-green-600 text-white rounded-md hover:bg-green-700 mt-4 font-montserrat"
  >
    Learn More 
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-6 mr-2">
  <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
</svg>
  </button>
</div>
            </div>

            <div>
                <div href="#" title="" class="block aspect-w-4 aspect-h-3">
                    <img class="object-cover w-full h-full" src={relationship} alt="" />
                </div>
              <span class="inline-flex px-4 py-2 text-xs font-semibold tracking-widest uppercase rounded-full text-gray-100 bg-gray-600 mt-9"> Service Offered </span>
                <p class="mt-2 text-xl font-semibold">
                    <motion.a
                      variants={fadeIn("zoom",0.2)}
        initial="hidden"
        whileInView={"show"}
        viewport={{once: false, amount:0.7}} href="#" title="" class="text-black font-serif"> Relationship Issues 
                    </motion.a>
                </p>
                <motion.p
                  variants={fadeIn("zoom",0.2)}
        initial="hidden"
        whileInView={"show"}
        viewport={{once: false, amount:0.7}} class="mt-4 text-gray-600 font-montserrat font-medium">We provide therapy to couples or individuals struggling with relationship issues, whether related to communication, trust, or intimacy. Our goal is to help you build stronger, healthier connections.
                </motion.p>
                
                          
           <div className="flex justify-center lg:justify-start items-center">
  <button
   onClick={() => handleNavigation('/Services/Relationship')}
    className="btn bg-green-600 text-white rounded-md hover:bg-green-700 mt-4 font-montserrat"
  >
    Learn More 
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-6 mr-2">
  <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
</svg>
  </button>
</div>
            </div>

            <div>
                <div href="#" title="" class="block aspect-w-4 aspect-h-3">
                    <img class="object-cover w-full h-full" src={truama} alt="" />
                </div>
              <span class="inline-flex px-4 py-2 text-xs font-semibold tracking-widest uppercase rounded-full text-gray-100 bg-gray-600 mt-9"> Service Offered </span>
                <p class="mt-2 text-xl font-semibold">
                    <motion.a
                      variants={fadeIn("zoom",0.2)}
        initial="hidden"
        whileInView={"show"}
        viewport={{once: false, amount:0.7}} href="#" title="" class="text-black font-serif"> Trauma and PTSD 
                    </motion.a>
                </p>
                <motion.p
                  variants={fadeIn("zoom",0.2)}
        initial="hidden"
        whileInView={"show"}
        viewport={{once: false, amount:0.7}} class="mt-4 text-gray-600 font-montserrat font-medium">For those suffering from trauma or post-traumatic stress disorder (PTSD), we offer trauma-focused therapies designed to help individuals process and heal from their experiences, reclaiming control over their lives.
                </motion.p>
                          
           <div className="flex justify-center lg:justify-start items-center">
  <button
   onClick={() => handleNavigation('/Services/TrumaandPTSD')}
    className="btn bg-green-600 text-white rounded-md hover:bg-green-700 mt-4 font-montserrat"
  >
    Learn More 
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-6 mr-2">
  <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
</svg>
  </button>
</div>
                
            </div>


            <div>
                <div href="#" title="" class="block aspect-w-4 aspect-h-3">
                    <img class="object-cover w-full h-full" src={ADHD} alt="" />
                </div>
              <span class="inline-flex px-4 py-2 text-xs font-semibold tracking-widest uppercase rounded-full text-gray-100 bg-gray-600 mt-9"> Service Offered </span>
                <p class="mt-2 text-xl font-semibold">
                    <motion.a
                      variants={fadeIn("zoom",0.2)}
        initial="hidden"
        whileInView={"show"}
        viewport={{once: false, amount:0.7}} href="#" title="" class="text-black font-serif"> ADHD 
                    </motion.a>
                </p>
                <motion.p
                  variants={fadeIn("zoom",0.2)}
        initial="hidden"
        whileInView={"show"}
        viewport={{once: false, amount:0.7}} class="mt-4 text-gray-600 font-montserrat font-medium">Our ADHD specialists offers Personalized treatment plans tailored to your specific needs
Medication management to help manage symptoms effectively
Support and resources to navigate challenges associated with ADHD

                </motion.p>
                          
           <div className="flex justify-center lg:justify-start items-center">
  <button
   onClick={() => handleNavigation('/Services/ADHD')}
    className="btn bg-green-600 text-white rounded-md hover:bg-green-700 mt-4 font-montserrat"
  >
    Learn More 
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-6 mr-2">
  <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
</svg>
  </button>
</div>
            </div>
        </div>
    </div>
</section>
  )
}

export default Services