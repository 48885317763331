import AngerManagement from "../../../components/WebsiteComponents/ServicesComponent/AngerManagement";
import AngerManagementDetails from "../../../components/WebsiteComponents/ServicesComponent/AngerManagementDetails";

export const AngerManagementPage = () => {
    return (
        <> 
          <AngerManagement/>
<AngerManagementDetails/>
        </>
    );
}
export default AngerManagementPage;