import React from 'react';
import aetna from '../../Assets/Aetna.png';
import cigna from '../../Assets/cigna.png';
import mashachuset from '../../Assets/mashachuset.png';
import premera from '../../Assets/premera.png';
import regence from '../../Assets/regence.png';
import quest from '../../Assets/quest.png'

import {motion} from 'framer-motion';
import {fadeIn} from '../../Variants'

function Insurance() {
  return (
    <section className="py-10 bg-white sm:py-16 lg:py-14">
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="max-w-2xl mx-auto text-center">
          <motion.h2
           variants={fadeIn("down",0.2)}
           initial="hidden"
           whileInView={"show"}
           viewport={{once: false, amount:0.7}}
           className="text-2xl font-bold text-gray-800 sm:text-4xl sm:leading-tight font-serif">
            Health Care Insurance Companies Accepted in our practice
          </motion.h2>
          <motion.p
                       variants={fadeIn("zoom",0.2)}
        initial="hidden"
        whileInView={"show"}
        viewport={{once: false, amount:0.7}}
                     class="max-w-xl mx-auto mt-2 text-base leading-relaxed text-gray-600 font-montserrat">
We are in-network with Regence Blue Cross, Aetna, and Cigna. We also offer superbills for out-of-network clients to support reimbursement. Reach out with any questions we’re here to help
                    </motion.p>
        </div>

        <motion.div
  variants={fadeIn("zoom", 0.2)}
  initial="hidden"
  whileInView={"show"}
  viewport={{ once: false, amount: 0.7 }}
  className="flex items-center justify-center flex-wrap mx-auto mt-2 md:mt-8 gap-x-10 gap-y-6"
>
  <div className="flex justify-center">
    <img className="object-contain h-8 grayscale" src={cigna} alt="Cigna" />
  </div>

  <div className="flex justify-center">
    <img className="object-contain h-8 grayscale" src={regence} alt="Regence" />
  </div>

  <div className="flex justify-center">
    <img className="object-contain h-8 grayscale" src={mashachuset} alt="Massachusetts" />
  </div>

  <div className="flex justify-center">
    <img className="object-contain h-8 grayscale" src={aetna} alt="Aetna" />
  </div>

  {/* Make the last two images stack vertically on small screens and align side-by-side on larger screens */}
  <div className="flex flex-col md:flex-row justify-center gap-x-10 gap-y-6 w-full">
    <div className="flex justify-center">
      <img className="object-contain h-8 grayscale" src={premera} alt="Premera" />
    </div>
    <div className="flex justify-center">
      <img className="object-contain h-8 grayscale" src={quest} alt="quest" />
    </div>
  </div>
</motion.div>



      </div>
    </section>
  );
}

export default Insurance;
