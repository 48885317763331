import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import GriefDetailsImage from '../../../Assets/GriefDetails.jpg';

function GriefDetails() {
    const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
  };

  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  const handleVideoPlay = () => {
    setIsVideoPlaying(true);
  };
  return (
    <section className="py-10 bg-white sm:py-16 lg:py-24">
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="grid items-center grid-cols-1 lg:grid-cols-2 gap-x-12 xl:gap-x-24 gap-y-12">
          <div className="relative lg:mb-12">
            <img className="absolute -right-0 -bottom-8 xl:-bottom-12 xl:-right-4" src="https://cdn.rareblocks.xyz/collection/celebration/images/content/3/dots-pattern.svg" alt="" />
            <div className="pl-12 pr-6">
              <img className="relative rounded-md" src={GriefDetailsImage} alt="Grief Details" />
            </div>
          </div>

          <div className="2xl:pl-16">
            <h2 className="text-xl font-bold text-black sm:text-2xl lg:text-3xl font-serif">What Is Grief?</h2>
            <p className="text-md font-normal text-gray-600 font-montserrat mt-4">
              Grief is the emotional suffering experienced when someone we love is gone. It encompasses a wide range of feelings, thoughts, and behaviors and can occur due to various forms of loss, including the death of a loved one, divorce, loss of a job, or any significant life change.
            </p>
            <p className="mt-6 text-md font-normal text-gray-600 font-montserrat">
              Grief is a natural response to loss and can manifest physically, emotionally, and socially. Understanding the nature of grief can aid individuals in navigating their feelings and recognizing that they are not alone in their experiences.
            </p>
          </div>
        </div>
      </div>

      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {/* Left Column */}
          <div className="bg-white p-6 rounded-lg">
            <h2 className="text-2xl font-bold mb-4 font-serif">Types of Grief</h2>
            <ul className="list-disc list-inside text-md font-normal text-gray-600 font-montserrat space-y-2 mb-6">
              <li>
                <strong>Anticipatory Grief:</strong> This occurs before a loss, often seen in those caring for terminally ill loved ones. It involves a mix of sadness and the preparation for the inevitable.
              </li>
              <li>
                <strong>Normal Grief:</strong> A natural response to loss that can include a range of emotions. It typically lessens over time as individuals adjust to the change.
              </li>
              <li>
                <strong>Complicated Grief:</strong> This occurs when the grief process is prolonged and intense, making it difficult for the individual to move forward. Symptoms can include severe sadness and longing for the deceased that interferes with daily life.
              </li>
              <li>
                <strong>Disenfranchised Grief:</strong> This type of grief is not openly acknowledged or socially accepted, such as the loss of a pet or a relationship that has ended. Individuals may feel isolated in their grief.
              </li>
            </ul>

            <h2 className="text-2xl font-bold mb-4 font-serif">Stages of Grief</h2>
            <p className="text-md font-normal text-gray-600 font-montserrat">
              The grieving process is often described in stages, although not everyone experiences them linearly:
            </p>
            <ul className="list-disc list-inside text-md font-normal text-gray-600 font-montserrat space-y-2 mb-6">
              <li><strong>Denial:</strong> A defense mechanism that helps people cope by denying the reality of the loss.</li>
              <li><strong>Anger:</strong> This can manifest as frustration or resentment toward the loss, others, or oneself.</li>
              <li><strong>Bargaining:</strong> Involves seeking ways to avoid the cause of grief, often through negotiation or promises.</li>
              <li><strong>Depression:</strong> A deep sense of sadness and despair as the reality of the loss sets in.</li>
              <li><strong>Acceptance:</strong> Finding a way to move forward and live with the loss, acknowledging it as part of life.</li>
            </ul>

            <h2 className="text-2xl font-bold mt-4 font-serif">Emotional Symptoms of Grief:</h2>
            <ul className="list-disc list-inside text-md font-normal text-gray-600 font-montserrat space-y-2 mb-6">
              <li>Sadness</li>
              <li>Anxiety</li>
              <li>Guilt and Regret</li>
              <li>Relief (especially in cases of prolonged suffering)</li>
            </ul>

            <div className="mt-6">
              <h2 className="text-2xl font-bold mb-4 font-serif">Watch this video to learn more:</h2>

              {/* Show the thumbnail before the video is played */}
              {!isVideoPlaying ? (
                <div
                  className="relative cursor-pointer"
                  style={{ paddingBottom: '56.25%', height: 0, overflow: 'hidden', maxWidth: '100%', position: 'relative' }}
                  onClick={handleVideoPlay}
                >
                  {/* YouTube video thumbnail */}
                  <img
                    className="absolute top-0 left-0 w-full h-full object-cover rounded-lg"
                    src="https://img.youtube.com/vi/Gcp1G0eIUYk/maxresdefault.jpg"
                    alt="Video Thumbnail"
                  />
                  {/* Play button overlay */}
                  <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="white"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      className="h-16 w-16 opacity-75"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5.25 5.25l13.5 6.75-13.5 6.75V5.25z"
                      />
                    </svg>
                  </div>
                </div>
              ) : (
                // Show the iframe once the user clicks to play the video
                <div className="relative" style={{ paddingBottom: '56.25%', height: 0, overflow: 'hidden', maxWidth: '100%', position: 'relative' }}>
                  <iframe
                    className="absolute top-0 left-0 w-full h-full"
                    src="https://www.youtube.com/embed/Gcp1G0eIUYk?autoplay=1"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                  ></iframe>
                </div>
              )}
            </div>
          </div>

          {/* Right Column */}
          <div className="bg-white p-6 rounded-lg">
            <h2 className="text-2xl font-bold mb-4 font-serif">Coping with Grief</h2>
            <p className="text-md font-normal text-gray-600 font-montserrat mb-4">
              Coping with grief is a personal journey, and there is no "right" way to grieve. Here are some strategies to help navigate the grieving process:
            </p>
            <ul className="list-disc list-inside text-md font-normal text-gray-600 font-montserrat space-y-2 mb-6">
              <li>
                <strong>Support Networks:</strong> Surrounding oneself with supportive friends and family can provide comfort and understanding. Joining a support group can also connect you with others who share similar experiences.
              </li>
              <li>
                <strong>Therapy:</strong> Professional support from a therapist can provide guidance and strategies to help process grief. Cognitive-behavioral therapy (CBT) and grief counseling are effective options.
              </li>
              <li>
                <strong>Self-Care:</strong> Prioritizing physical health through exercise, proper nutrition, and rest is crucial. Engaging in hobbies or activities that bring joy can also serve as a healthy distraction.
              </li>
              <li>
                <strong>Memorialization:</strong> Creating a memorial or engaging in rituals can help honor the memory of the lost loved one, allowing individuals to express their grief creatively.
              </li>
            </ul>

            <h2 className="text-2xl mt-2 font-bold mb-4 font-serif">Physical and Emotional Effects of Grief</h2>
            <p className="text-md font-normal text-gray-600 font-montserrat mb-4">
              Coping with grief is a personal journey, and there is no "right" way to grieve. Here are some strategies to help navigate the grieving process:
            </p>
            <ul className="list-disc list-inside text-md font-normal text-gray-600 font-montserrat space-y-2 mb-6">
              <li>
                <strong>Physical symptoms:</strong> Fatigue, changes in sleep patterns, appetite changes, body aches, headaches, and immune system weakening.
              </li>
              <li>
                <strong>Emotional symptoms:</strong> Anxiety, sadness, anger, guilt, loneliness, despair, and numbness.
              </li>
              <li>
                <strong>Cognitive symptoms:</strong> Difficulty concentrating, forgetfulness, confusion, and a sense of unreality.
              </li>
              <li>
                <strong>Social symptoms:</strong> Isolation, withdrawal from activities, strained relationships, or feeling disconnected from others..
              </li>
            </ul>


            <h2 className="text-2xl font-bold mb-4 font-serif">When to Seek Professional Help</h2>
            <p className="text-md font-normal text-gray-600 font-montserrat mb-4">
              While grief is a natural process, it's important to recognize when it becomes overwhelming. Seek professional help if you experience:
            </p>
            <ul className="list-disc list-inside text-md font-normal text-gray-600 font-montserrat space-y-2 mb-6">
              <li>Persistent feelings of hopelessness or despair</li>
              <li>Difficulty functioning in daily life</li>
              <li>Self-harm thoughts or feelings</li>
              <li>Isolation from friends and family</li>
            </ul>

            <div className="flex flex-col mt-4 sm:flex-row justify-center lg:justify-start items-center gap-4">
  {/* Book an Appointment Button */}
  <button
    className="btn w-full sm:w-auto border-none bg-green-600 text-white rounded hover:bg-green-700  font-montserrat flex items-center justify-center sm:justify-start"
    onClick={() => handleNavigation('/Contact')}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      className="h-5 w-5 mr-1"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"
      />
    </svg>
    Book Virtual Appointment
  </button>
</div>



          </div>
        </div>

     
      </div>
    </section>
  )
}

export default GriefDetails