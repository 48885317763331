import { BrowserRouter as Router, Routes, Route, useMatch } from "react-router-dom";
import Navbar from "./components/WebsiteComponents/Navbar";
import { Home } from "./pages/Websitepages/Home";
import { About } from "./pages/Websitepages/About";
import { ServicesPage } from './pages/Websitepages/Service';
import { Insurance } from "./pages/Websitepages/Insurance";
import { Contact } from "./pages/Websitepages/Contact";
import Footer from "./components/WebsiteComponents/Footer";
import  {Login}  from "./pages/AdminSide/AdminLogin";
import  {AdminDashboard}  from "./pages/AdminSide/AdminDashboard";
import {Error} from './pages/Websitepages/Errorpage'
import Signup from './components/AdminComponents/AdminLoginComponents/Signup'
import {Success} from './pages/Websitepages/FormSuccess'
import {Unsuccess} from './pages/Websitepages/FormUnsuccessful'
import AngerManagementpage from './pages/Websitepages/WebServices/AngerManagementPage'
import Anxietypage from './pages/Websitepages/WebServices/AnxietyPage'
import BehavioralIssuespage from './pages/Websitepages/WebServices/BehavioralIssues'
import DepressionPage from './pages/Websitepages/WebServices/Depression'
import GriefPage from './pages/Websitepages/WebServices/Grief'
import MedicationManagementPage from './pages/Websitepages/WebServices/MedicationManagement'
import RelationshipPage from './pages/Websitepages/WebServices/Relationship'
import TrumaandPTSDPage from './pages/Websitepages/WebServices/TrumaandPTSD'
import ADHDPage from './pages/Websitepages/WebServices/ADHD'

function Layout() {
  // Match routes for displaying the Navbar and Footer
  const matchHome = useMatch("/");
  const matchAbout = useMatch("/About");
  const matchServices = useMatch("/Services");
  const matchInsurance = useMatch("/Insurance");
  const matchContact = useMatch("/Contact");
  const matchSuccess = useMatch("/Success");
  const matchUnsuccess = useMatch("/Unsuccess");
  const matchAngerManagementpage = useMatch("/Services/AngerManagement");
  const matchAnxietypage = useMatch("/Services/Anxiety");
const matchBehavioralIssues = useMatch("/Services/BehavioralIssues");
const matchDepressions = useMatch("/Services/Depression");

const matchGrief = useMatch("/Services/Grief");
const matchMedicationManagement = useMatch("/Services/MedicationManagement");
const matchRelationship = useMatch("/Services/Relationship");
const matchTrumaandPTSD = useMatch("/Services/TrumaandPTSD");
const matchADHD = useMatch("/Services/ADHD");


// Only show the Navbar and Footer for these specific routes
const showNavbarAndFooter = matchHome || matchAbout || matchServices || matchInsurance || matchContact || matchSuccess || matchUnsuccess || matchAngerManagementpage || matchAnxietypage || matchBehavioralIssues || matchDepressions || matchGrief || matchMedicationManagement || matchRelationship || matchTrumaandPTSD || matchADHD;

  return (
    <>
      {/* Conditionally render Navbar and Footer */}
      {showNavbarAndFooter && <Navbar />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/About" element={<About />} />
        <Route path="/Services" element={<ServicesPage />} />
        <Route path="/Insurance" element={<Insurance />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/Login" element={<Login />} />
        <Route path="/Signup" element={<Signup />} />
        <Route path="/AdminDashboard/*" element={<AdminDashboard />} />
        <Route path="/Success" element={<Success />} />
        <Route path="/Unsuccess" element={<Unsuccess />} />
        <Route path="/Services/AngerManagement" element={<AngerManagementpage />} />
        <Route path="/Services/Anxiety" element={<Anxietypage />} />
        <Route path="/Services/BehavioralIssues" element={<BehavioralIssuespage />} />
        <Route path="/Services/Depression" element={<DepressionPage />} />
        <Route path="/Services/Grief" element={<GriefPage />} />
        <Route path="/Services/MedicationManagement" element={<MedicationManagementPage />} />
        <Route path="/Services/Relationship" element={<RelationshipPage />} />
        <Route path="/Services/TrumaandPTSD" element={<TrumaandPTSDPage />} />
        <Route path="/Services/ADHD" element={<ADHDPage />} />
        <Route path="*" element={<Error />} />
      </Routes>
      {showNavbarAndFooter && <Footer />}
    </>
  );
}

function App() {
  return (
    <Router>
      <Layout />
    </Router>
  );
}

export default App;
