import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MedicatiomanageDetails from '../../../Assets/MedicatiomanageDetails.jpg';

function MedicationManagementDetails() {
    const navigate = useNavigate();

    const handleNavigation = (path) => {
      navigate(path);
    };
  
    const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  
    const handleVideoPlay = () => {
      setIsVideoPlaying(true);
    };

  return (
    <section className="py-10 bg-white sm:py-16 lg:py-24">
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="grid items-center grid-cols-1 lg:grid-cols-2 gap-x-12 xl:gap-x-24 gap-y-12">
          <div className="relative lg:mb-12">
            <img
              className="absolute -right-0 -bottom-8 xl:-bottom-12 xl:-right-4"
              src="https://cdn.rareblocks.xyz/collection/celebration/images/content/3/dots-pattern.svg"
              alt=""
            />
            <div className="pl-12 pr-6">
              <img className="relative rounded-md" src={MedicatiomanageDetails} alt="Medication management overview" />
            </div>
          </div>

          <div className="2xl:pl-16">
            <h2 className="text-xl font-bold text-black sm:text-2xl lg:text-3xl font-serif">What Is Medication Management?</h2>
            <p className="text-md font-normal text-gray-600 font-montserrat mt-4">
              Medication management is a structured process aimed at ensuring that patients use their medications effectively and safely. This process includes the monitoring and management of medication intake, its effects, and the necessary adjustments to treatment plans.
            </p>
            <p className="mt-6 text-md font-normal text-gray-600 font-montserrat">
              It is crucial in minimizing the risks of drug interactions, side effects, and adverse reactions while maximizing the benefits of the medications prescribed. By ensuring adherence to prescribed regimens and preventing medication errors, medication management enhances patient outcomes.
            </p>
          </div>
        </div>
      </div>

      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {/* Left Column */}
          <div className="bg-white p-6 rounded-lg">
            <h2 className="text-2xl font-bold mb-4 font-serif"><strong>Why Is Medication Management Important?</strong></h2>
            <p className="text-md font-normal text-gray-600 font-montserrat mb-6">
              Medication management plays a vital role in ensuring the safety and well-being of patients, especially those with chronic or complex health conditions. Effective management helps avoid unnecessary hospital admissions, reduces healthcare costs, and improves the quality of life for patients. Here are some of the key reasons why medication management is essential:
            </p>
            <ul className="list-disc list-inside text-md font-normal text-gray-600 font-montserrat space-y-2 mb-6">
              <li><strong>Improves medication adherence:</strong> Ensures that patients take their medications correctly as prescribed, both in terms of dosage and timing.</li>
              <li><strong>Prevents adverse drug events:</strong> Helps in monitoring for side effects and interactions between different medications, reducing the risk of harmful reactions.</li>
              <li><strong>Enhances patient outcomes:</strong> Optimizes the effectiveness of treatment by continuously reviewing and adjusting medication regimens as needed.</li>
            </ul>

            <h2 className="text-2xl font-bold mb-4 font-serif"><strong>Key Components of Medication Management:</strong></h2>
            <ul className="list-disc list-inside text-md font-normal text-gray-600 font-montserrat space-y-2">
              <li><strong>Medication Review:</strong> A thorough review of all medications a patient is taking to ensure that each one is necessary and appropriate.</li>
              <li><strong>Medication Reconciliation:</strong> Comparing a patient's current medication orders with the medications they are actually taking, to avoid duplication, omissions, or errors.</li>
              <li><strong>Patient Education:</strong> Educating patients on how to take their medications, potential side effects, and the importance of adherence to prescribed treatments.</li>
              <li><strong>Adherence Monitoring:</strong> Tracking patients’ adherence to their medication plans to ensure consistency and effectiveness.</li>
            </ul>

          

            <div className="mt-6">
  <h2 className="text-2xl font-bold mb-4 font-serif">Watch this video to learn more:</h2>

  {/* Show the thumbnail before the video is played */}
  {!isVideoPlaying ? (
    <div
      className="relative cursor-pointer"
      style={{ paddingBottom: '56.25%', height: 0, overflow: 'hidden', maxWidth: '100%', position: 'relative' }}
      onClick={handleVideoPlay}
    >
      {/* YouTube video thumbnail */}
      <img
        className="absolute top-0 left-0 w-full h-full object-cover rounded-lg"
        src="https://img.youtube.com/vi/B5ekQbwMDq0/maxresdefault.jpg"
        alt="Video Thumbnail"
      />
      {/* Play button overlay */}
      <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="white"
          viewBox="0 0 24 24"
          stroke="currentColor"
          className="h-16 w-16 opacity-75"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M5.25 5.25l13.5 6.75-13.5 6.75V5.25z"
          />
        </svg>
      </div>
    </div>
  ) : (
    // Show the iframe once the user clicks to play the video
    <div className="relative" style={{ paddingBottom: '56.25%', height: 0, overflow: 'hidden', maxWidth: '100%', position: 'relative' }}>
      <iframe
        className="absolute top-0 left-0 w-full h-full"
        src="https://www.youtube.com/embed/B5ekQbwMDq0?autoplay=1"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
      ></iframe>
    </div>
  )}
</div>

          </div>

          {/* Right Column */}
          <div className="bg-white p-6 rounded-lg">
            <h2 className="text-2xl font-bold mb-4 font-serif"><strong>Challenges in Medication Management:</strong></h2>
            <ul className="list-disc list-inside text-md font-normal text-gray-600 font-montserrat space-y-2 mb-6">
              <li><strong>Polypharmacy:</strong> Managing multiple medications for patients with chronic illnesses increases the complexity and risks of drug interactions.</li>
              <li><strong>Patient Education Barriers:</strong> Patients may not fully understand their medications, leading to non-adherence or misuse.</li>
              <li><strong>Health Literacy Issues:</strong> Patients with limited health literacy may struggle to comprehend instructions, making effective management difficult.</li>
            </ul>

            <h2 className="text-2xl font-bold mb-4 font-serif"><strong>Best Practices in Medication Management:</strong></h2>
            <ul className="list-disc list-inside text-md font-normal text-gray-600 font-montserrat space-y-2">
              <li><strong>Interprofessional Collaboration:</strong> Working closely with a team of healthcare professionals, including doctors, pharmacists, and nurses, to create and monitor treatment plans.</li>
              <li><strong>Regular Follow-Ups:</strong> Ongoing communication with patients to adjust medications based on progress, side effects, or changes in condition.</li>
              <li><strong>Personalized Care Plans:</strong> Tailoring medication regimens to the specific needs and health conditions of individual patients, taking into account their lifestyle and preferences.</li>
              <li><strong>Personalized Care Plans:</strong> Tailoring medication regimens to the specific needs and health conditions of individual patients, taking into account their lifestyle and preferences.</li>
            </ul>


            <h2 className="text-2xl mt-4 font-bold mb-4 font-serif"><strong>Tools and Technologies for Effective Medication Management:</strong></h2>
            <ul className="list-disc list-inside text-md font-normal text-gray-600 font-montserrat space-y-2 mb-6">
              <li><strong>Electronic Health Records (EHR):</strong> EHR systems help healthcare providers maintain accurate records of medications, ensuring that the most up-to-date information is available.</li>
              <li><strong>Medication Management Software:</strong> Specialized software that helps pharmacists and clinicians track, dispense, and monitor medications efficiently.</li>
              <li><strong>Telehealth Solutions:</strong> Remote consultations and monitoring tools that provide continuous oversight and support for patients managing medications at home.</li>
            </ul>



            <h2 className="text-2xl font-bold mt-6 font-serif"><strong>Need Assistance in Medication Management?</strong></h2>
            <p className="text-md font-normal text-gray-600 font-montserrat">
              Continuous evaluation and improvement of medication management practices are crucial for enhancing patient care. By using the right tools, involving interprofessional collaboration, and educating patients, healthcare providers can improve outcomes and patient satisfaction.
            </p>

            <div className="flex flex-col mt-4 sm:flex-row justify-center lg:justify-start items-center gap-4">
              {/* Book an Appointment Button */}
              <button
                className="btn w-full sm:w-auto border-none bg-green-600 text-white rounded hover:bg-green-700 font-montserrat flex items-center justify-center sm:justify-start"
                onClick={() => handleNavigation('/Contact')}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="h-5 w-5 mr-1"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008Z"
                  />
                </svg>
                Book Virtual Appointment
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default MedicationManagementDetails;



