import { useEffect, useState } from 'react';
import { motion } from 'framer-motion';

export const fadeIn = (direction, delay) => ({
  hidden: {
    y: direction === 'up' ? 40 : direction === 'down' ? -40 : 0,
    x: direction === 'left' ? 40 : direction === 'right' ? -40 : 0,
    opacity: 0,
  },
  show: {
    y: 0,
    x: 0,
    opacity: 1,
    transition: {
      type: 'spring', // Use spring for smoother transitions
      stiffness: 100, // Higher stiffness makes it quicker
      damping: 20, // Damping controls the overshoot and smoothness
      duration: 1.2, // You can adjust this duration as needed
      delay: delay,
    }
  }
});

// Component
const AnimatedComponent = () => {
  const [hasAnimated, setHasAnimated] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (!hasAnimated) {
        // Get the element to animate
        const element = document.querySelector('.animate-once');
        if (element) {
          const rect = element.getBoundingClientRect();
          // Check if the element is in the viewport
          if (rect.top < window.innerHeight && rect.bottom > 0) {
            setHasAnimated(true); // Mark as animated
          }
        }
      }
    };

    // Check on component mount if the element is already in view
    handleScroll();

    // Add scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [hasAnimated]);

  return (
    <motion.div
      className="animate-once"
      variants={fadeIn('up', 0.5)}
      initial="hidden"
      animate={hasAnimated ? 'show' : 'hidden'} // Animate only once when visible
    >
      {/* Your content here */}
    </motion.div>
  );
};




export default AnimatedComponent;
