import React from 'react'
import {motion} from 'framer-motion';
import {fadeIn} from '../../Variants'
import qoutation from '../../Assets/text.png'

function Payment() {
  return (
    <section class="py-10 bg-gray-900 sm:py-16 lg:py-24">
    <div class="max-w-5xl px-4 mx-auto sm:px-6 lg:px-8">
        <div class="grid grid-cols-1 md:items-stretch md:grid-cols-2 gap-x-12 lg:gap-x-20 gap-y-10">
        <div class="flex flex-col justify-between lg:py-5">
                <div>
                    <motion.h2
                       variants={fadeIn("down",0.2)}
        initial="hidden"
        whileInView={"show"}
        viewport={{once: false, amount:0.7}}
                     class="text-xl font-bold leading-tight text-white sm:text-2xl lg:leading-tight lg:text-3xl font-serif">Support Your Mental Health Journey with Compassionate Care
                    </motion.h2>
                    <motion.p
                       variants={fadeIn("zoom",0.2)}
        initial="hidden"
        whileInView={"show"}
        viewport={{once: false, amount:0.7}}
                     class="max-w-xl mx-auto mt-4 text-base leading-relaxed text-white font-montserrat">
Support your mental health journey with care and compassion. Seeking help provides a safe space to explore your emotions and build resilience. Through therapy or mindfulness, prioritizing your mental well-being leads to greater peace and balance.
                    </motion.p>
                    <div className="overflow-hidden bg-gray-800 border mt-4 sm:block ltr:border-b-0 ltr:border-s-0 ltr:bg-white rtl:border-e-0 rtl:border-t-0">
  <div className="px-2 py-2 flex flex-col items-center">
    <img src={qoutation} alt="" className="h-10 w-10"/>
    <motion.p
      variants={fadeIn("zoom", 0.2)}
      initial="hidden"
      whileInView="show"
      viewport={{ once: false, amount: 0.7 }}
      className="text-sm text-center font-medium text-white sm:text-md font-montserrat px-4"
    >
You have the option to add your insurance details, allowing you to view an estimated cost for the mental health consultation session. This estimate considers any coverage provided by your insurance plan.
    </motion.p>
  </div>
</div>




                </div>
            </div>
            <div class="">
                <div class="overflow-hidden bg-white rounded-md">
                    <div class="p-10">
                    <div class="flex justify-center">
    <h3 class="text-xs font-semibold tracking-widest text-gray-100 bg-green-600 inline-flex items-center px-4 py-2 rounded-full uppercase space-x-2">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
            <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75 11.25 15 15 9.75m-3-7.036A11.959 11.959 0 0 1 3.598 6 11.99 11.99 0 0 0 3 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285Z" />
        </svg>
        <span>Types of Payment we Accept</span>
    </h3>
</div>
                        <ul class="flex flex-col mt-8 space-y-4 font-montserrat">
                  <li class="inline-flex items-center space-x-2">
                    <svg class="flex-shrink-0 w-5 h-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                      <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                    </svg>
                    <span class="text-base font-medium text-gray-900"> American Express </span>
                  </li>

                  <li class="inline-flex items-center space-x-2">
                    <svg class="flex-shrink-0 w-5 h-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                      <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                    </svg>
                    <span class="text-base font-medium text-gray-900"> Health Savings Account </span>
                  </li>

                  <li class="inline-flex items-center space-x-2">
                    <svg class="flex-shrink-0 w-5 h-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                      <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                    </svg>
                    <span class="text-base font-medium text-gray-900"> Mastercard or Visa </span>
                  </li>

                  <li class="inline-flex items-center space-x-2">
                    <svg class="flex-shrink-0 w-5 h-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                      <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                    </svg>
                    <span class="text-base font-medium text-gray-900"> Cash, Check, Discover </span>
                  </li>
                </ul>
                <div class="space-y-3 mt-4 sm:mt-8">
    <div class="overflow-hidden transition-all duration-200 bg-white border-2 border-gray-200 rounded-md ">
        <div class="px-4 py-5 sm:p-6">
            <div class="flex items-center">
                <div>
                    <div href="#" class="text-gray-700">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-8">
  <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 18.75a60.07 60.07 0 0 1 15.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 0 1 3 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 0 0-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 0 1-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 0 0 3 15h-.75M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm3 0h.008v.008H18V10.5Zm-12 0h.008v.008H6V10.5Z" />
</svg>
                    </div>
                </div>
                <label for="monthlyPlan" class="ml-5 mr-auto">
                    <p class="text-xl font-semibold text-black font-montserrat">Cash Payment</p>
                    <p class="text-sm text-gray-600 font-montserrat">Simple billing per Appointment</p>
                </label>
            </div>
        </div>
    </div>

    <div class="overflow-hidden transition-all duration-200 bg-white border-2 rounded-md ">
        <div class="px-4 py-5 sm:p-6">
            <div class="flex items-center">
                <div>
                    <div href="#" class="text-gray-700">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-8">
  <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Z" />
</svg>

                    </div>
                </div>
                <label for="yearlyPlan" class="ml-5 mr-auto">
                    <p class="text-xl font-semibold text-black font-montserrat">Card Payment</p>
                    <p class="text-sm text-gray-600 font-montserrat">Fast and Secure Transaction</p>
                </label>
            </div>
        </div>
    </div>
</div>


                       
                    
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


  )
}

export default Payment