import React, { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import { fadeIn } from '../../Variants';
import Feedback from '../../Assets/Feedback.svg';
import axios from 'axios';
import Swal from 'sweetalert2';

function Testimonials() {
  const [slides, setSlides] = useState([]);
  const [activeSlide, setActiveSlide] = useState(0);

  useEffect(() => {
    // Fetch testimonials from the server
    axios.get('https://phoebe-compass-point-560accfa31d6.herokuapp.com/testimonials')
      .then((response) => {
        const testimonials = response.data;
        const formattedSlides = testimonials.map((testimonial) => ({
          content: (
            <div className="relative flex flex-col items-center max-w-6xl mx-auto sm:px-8 lg:px-12">
              <div className="flex justify-between items-center max-w-full px-4 mx-auto sm:px-6 lg:px-8">
                <div className="max-w-4xl mx-auto text-center">
                  <div className="flex items-center justify-center">
                    <svg
                      fill="#686D76"
                      width="48"
                      height="48"
                      className="mx-auto mb-2"
                      viewBox="0 0 8 8"
                      xmlns="http://www.w3.org/2000/svg"
                      stroke="#686D76"
                    >
                      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                      <g id="SVGRepo_iconCarrier">
                        <path d="M0 0v6l3-3v-3h-3zm5 0v6l3-3v-3h-3z" transform="translate(0 1)"></path>
                      </g>
                    </svg>
                  </div>
                  <h2 className="mt-4 text-2xl sm:text-xl md:text-2xl lg:text-3xl font-bold leading-tight text-gray-100 lg:mt-2 font-serif">
                    {testimonial.Headline}
                  </h2>
                  <p className="max-w-6xl mx-auto mt-4 text-md text-gray-200 md:mt-4 font-montserrat">
                    {testimonial.Description}
                  </p>
                  <p className="mt-2 text-md font-bold text-gray-200">— {testimonial.Name}</p>
                  <p className="text-sm text-gray-200">{new Date(testimonial.Date).toLocaleDateString()}</p>
                </div>
              </div>
            </div>
          )
        }));
        setSlides(formattedSlides);
      })
      .catch((error) => {
        console.error('Error fetching testimonials:', error);
      });
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setActiveSlide((prevSlide) => (prevSlide + 1) % slides.length);
    }, 20000); // 20 seconds interval

    return () => clearInterval(intervalId);
  }, [slides.length]);

  // Function to go to the previous slide
  const handlePrevSlide = () => {
    setActiveSlide((prevSlide) => (prevSlide === 0 ? slides.length - 1 : prevSlide - 1));
  };

  // Function to go to the next slide
  const handleNextSlide = () => {
    setActiveSlide((prevSlide) => (prevSlide + 1) % slides.length);
  };

  function handleSubmit(e) {
    e.preventDefault();
    axios
      .post(
        'https://phoebe-compass-point-560accfa31d6.herokuapp.com/add_Feedback',
        values
      )
      .then((res) => {
        // Show success SweetAlert
        Swal.fire({
          title: 'Feedback Added Successfully!',
          text: 'Your Feedback has been successfully added.',
          icon: 'success',
          confirmButtonText: 'OK',
        }).then(() => {
          // Optional: handle any logic after user clicks "OK"
        });
        modalRef.current.close();
      })
      .catch((err) => {
        console.error('Error adding Feedback:', err);
        // Show error SweetAlert
        Swal.fire({
          title: 'Error',
          text: 'There was a problem adding your Feedback. Please try again.',
          icon: 'error',
          confirmButtonText: 'OK',
        });
      });
  }

  const [values, setValues] = useState({
    Name: '',
    Date: '',
    Headline: '',
    Description: ''
  });
  const modalRef = useRef(null); 

  return (
    <section className="py-10 bg-gray-900 sm:py-16 lg:py-12">
      <div className="px-4 mx-auto max-w-full sm:px-6 lg:px-8">
        <div className="max-w-4xl mx-auto text-center">
          <motion.h2
            variants={fadeIn('down', 0.2)}
            initial="hidden"
            whileInView="show"
            viewport={{ once: false, amount: 0.7 }}
            className="text-3xl font-bold leading-tight text-white sm:text-4xl lg:text-5xl font-serif"
          >
            Testimonials
          </motion.h2>
          <motion.p
            variants={fadeIn('zoom', 0.2)}
            initial="hidden"
            whileInView="show"
            viewport={{ once: false, amount: 0.7 }}
            className="mt-2 text-base font-Montserrat text-gray-200 sm:text-xl font-montserrat"
          >
            Our customers' experiences are at the heart of everything we do. Their feedback and stories inspire us to
            continuously improve and deliver exceptional service.
          </motion.p>

          <div className="relative p-4 sm:p-6 md:p-8 lg:p-8">
  {slides.length > 0 && (
    <motion.div
      key={activeSlide}
      variants={{
        hidden: { opacity: 0, x: 100 },
        visible: { opacity: 1, x: 0 },
        exit: { opacity: 0, x: -100 },
      }}
      initial="hidden"
      animate="visible"
      exit="exit"
      transition={{ duration: 0.5 }}
      className="py-2"
    >
      {slides[activeSlide].content}
    </motion.div>
  )}

  <div className="flex justify-center mt-4">
    {slides.map((_, index) => (
      <div
        key={index}
        className={`h-1.5 mx-1 cursor-pointer transition-all duration-300 rounded-full ${
          index === activeSlide
            ? 'bg-green-600 w-6'
            : 'bg-gray-300 w-3 hover:bg-gray-500'
        }`}
        onClick={() => setActiveSlide(index)}
      />
    ))}
  </div>

  {/* Arrows for larger screens */}
  <div className="hidden sm:flex justify-between items-center absolute inset-0 top-1/2 transform -translate-y-1/2 px-4">
    <button
      className="p-2 text-white bg-transparent rounded-full focus:outline-none hover:bg-gray-500"
      onClick={handlePrevSlide}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        strokeWidth="2"
      >
        <path strokeLinecap="round" strokeLinejoin="round" d="M15 19l-7-7 7-7" />
      </svg>
    </button>
    <button
     className="p-2 text-white bg-transparent rounded-full focus:outline-none hover:bg-gray-500"
      onClick={handleNextSlide}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        strokeWidth="2"
      >
        <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
      </svg>
    </button>
  </div>

  {/* Arrows below the slider for mobile screens */}
  <div className="flex justify-center mt-6 sm:hidden">
    <button
     className="p-2 text-white bg-transparent rounded-full focus:outline-none hover:bg-gray-500"
      onClick={handlePrevSlide}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        strokeWidth="2"
      >
        <path strokeLinecap="round" strokeLinejoin="round" d="M15 19l-7-7 7-7" />
      </svg>
    </button>
    <button
    className="p-2 text-white bg-transparent rounded-full focus:outline-none hover:bg-gray-500"
      onClick={handleNextSlide}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        strokeWidth="2"
      >
        <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
      </svg>
    </button>
  </div>
</div>

          <div className="flex justify-center">
            <button
              className="btn bg-green-600 border-none text-white rounded hover:bg-green-700 font-montserrat"
              onClick={() => document.getElementById('SubmitTestimonial').showModal()}
            >
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="h-5 w-5 mr-1">
  <path stroke-linecap="round" stroke-linejoin="round" d="M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 0 1-.825-.242m9.345-8.334a2.126 2.126 0 0 0-.476-.095 48.64 48.64 0 0 0-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0 0 11.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155" />
</svg>
              Submit Feedback
            </button>
          </div>

          {/* Feedback Modal */}
          <dialog id="SubmitTestimonial" className="modal" ref={modalRef}>
  <div className="modal-box w-11/12 max-w-5xl rounded-none p-4">
  <form method="dialog">
      <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
    </form>
    <div className="max-w-2xl mt-2 p-4 mx-auto text-center">
            <h2 className="text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-3xl font-serif">
            Submit Testimonials
            </h2>
            <p className="max-w-xl mx-auto mt-2 text-sm leading-relaxed text-gray-600 font-montserrat">
            We value your feedback! Share your experience with us and let others know how we’ve made a difference.
            </p>
          </div>
          <div className="flex flex-col -mt-8 md:flex-row"> 
      {/* Left Side - Modal Content */}
      <div className="w-full md:w-1/2 p-6 flex items-center justify-center">
        <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
        <form className="max-w-xl mx-auto mt-2" onSubmit={handleSubmit} action="#" method="POST">
            <div className="space-y-2">
              <div className="md:flex md:space-x-2">
                <div className="w-full sm:w-full md:w-1/2">
                  <label htmlFor="Name" className="block text-left text-base font-medium text-gray-900 font-serif">
                    Name
                  </label>
                  <div className="mt-2.5 relative">
                    <input
                      type="text"
                      name="Name"
                      id="Name"
                      placeholder="Full Name"
                      className="block w-full px-4 py-4 text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 rounded-md focus:outline-none focus:ring-orange-500 focus:border-orange-500 caret-orange-500 font-montserrat"
                      onChange={(e) => setValues({ ...values, Name: e.target.value })}
                      required
                    />
                  </div>
                </div>

                <div className="w-full sm:w-full md:w-1/2">
                  <label htmlFor="Date" className="block text-left text-base font-medium text-gray-900 font-serif">
                    Date
                  </label>
                  <div className="mt-2.5 relative">
                    <input
                      type="date"
                      id="Date"
                      name="Date"
                      onChange={(e) => setValues({ ...values, Date: e.target.value })}
                      className="block w-full px-4 py-4 text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 rounded-md focus:outline-none focus:ring-orange-500 focus:border-orange-500 caret-orange-500 font-montserrat"
                      required
                    />
                  </div>
                </div>
              </div>

              <div>
                <label htmlFor="Headline" className="block text-left text-base font-medium text-gray-900 font-serif">
                  Headline
                </label>
                <div className="mt-2.5 relative">
                  <input
                    type="text"
                    name="Headline"
                    id="Headline"
                    placeholder="Ex. 'Transformative Support'"
                    className="block w-full px-4 py-4 text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 rounded-md focus:outline-none focus:ring-orange-500 focus:border-orange-500 caret-orange-500 font-montserrat"
                    onChange={(e) => setValues({ ...values, Headline: e.target.value })}
                    required
                  />
                </div>
              </div>

              <div>
                <label htmlFor="Description" className="block text-left text-base font-medium text-gray-900 font-serif">
                  Feedback Description
                </label>
                <div className="mt-2.5 relative">
                  <textarea
                    name="Description"
                    id="Description"
                    placeholder="State your Feedback Description"
                    className="block w-full px-4 py-4 text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 rounded-md focus:outline-none focus:ring-orange-500 focus:border-orange-500 caret-orange-500 font-montserrat"
                    onChange={(e) => setValues({ ...values, Description: e.target.value })}
                    required
                  />
                </div>
              </div>

              <div>
                <button  type="submit" className="inline-flex items-center justify-center w-full px-4 py-3 text-sm font-semibold text-white transition-all duration-200 bg-[#4ADE80] border border-transparent rounded-md hover:bg-[#34C759] focus:bg-[#34C759]">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-5 w-5 mr-2">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5" />
                  </svg>
                  Submit Feedback
                </button>
              </div>
            </div>
          </form>

          <div className="flex items-left justify-start mt-2 sm:justify-center sm:px-0">
            <span className="ml-2 text-sm text-gray-600 font-montserrat">
            Your feedback is important to us as it helps us improve our services and better meet your needs
            </span>
          </div>
        </div>
      </div>

      {/* Right Side - SVG Image */}
      <div className="w-full md:w-1/2 flex items-center justify-center p-6">
        <img
          src={Feedback}
          alt="Mental Wellness Illustration"
          className="w-full h-auto hidden md:block" 
        />
      </div>
    </div>
  </div>
</dialog>
        </div>
      </div>
    </section>
  );
}

export default Testimonials;
