import Communities from '../../components/WebsiteComponents/Communities';
import Hero from '../../components/WebsiteComponents/Hero'
import InitialAppointment from '../../components/WebsiteComponents/InitialAppointment';

import WhatweOffer from '../../components/WebsiteComponents/WhatweOffer';

export const Home = () => {
    return (
        <> 
        <Hero/>
      
        <WhatweOffer/>
        <Communities/>
        <InitialAppointment/>
    </>
       
    );
}