import React from 'react'
import ProfileFebbie from '../../Assets/ProfileFebbie.jpg'
function FAQ() {
  return (
    <section class="py-10 bg-gray-100 sm:py-16 lg:py-24">
    <div class="max-w-7xl px-4 mx-auto sm:px-6 lg:px-8">
        <div class="grid grid-cols-1 md:grid-cols-2 md:gap-x-16 lg:gap-x-16 gap-y-10">
        <div>
    <img class="object-cover w-16 h-16 rounded-full" src={ProfileFebbie} alt="" />
    <blockquote>
        <p class="mt-6 text-md leading-relaxed text-black font-montserrat">“Please note this provider uses a platform called <span class="font-semibold">Headway</span> and <span class="font-semibold">Zocdoc</span> Calendar  to manage their practice. After booking, you will need to set up a Headway account to confirm your appointment. By providing your phone number and/or email address during booking, you agree to receive communications via e-mail, phone call and/or text message, which may include calls/texts sent using an automated system, from or on behalf of Headway and/or this provider about your appointment, account registration, insurance and payment information, and treatment.”</p>
    </blockquote>
    <p class="mt-6 text-base font-semibold text-black font-montserrat">Febbie Mutindori, ARNP</p>
    <p class="mt-1 text-base text-gray-600 font-montserrat">Adult Psychiatric & Mental Health Nurse Practitioner</p>

    <div class="flex flex-col mt-2 md:flex-row md:space-x-4">
        <a 
          href="https://headway.co/providers/febbie-mutindori?utm_source=pem&utm_medium=direct_link&utm_campaign=88474" 
          target="_blank" 
          rel='noreferrer'
          class="btn inline-flex items-center text-gray-800 border ltr:border-b-0 ltr:border-s-0 ltr:bg-white rtl:border-e-0 rtl:border-t-0 hover:bg-green-600 hover:text-white border-green-500 rounded px-5 py-2.5 w-full md:w-auto justify-center font-montserrat mb-4 md:mb-0"
        >
          <img src="https://res.cloudinary.com/dgl9hqn2p/image/upload/v1731334171/jpkr8u9zpc1mewwpzuzf.png" alt="Headway Icon" class="h-5 w-5 mr-2"/>
          Book in Headway Calendar
        </a>

        <a 
          href="https://www.zocdoc.com/doctor/febbie-mutindori-arnp-524312?dd_referrer=https%3A%2F%2Fwww.google.com%2F" 
          target="_blank" 
           rel='noreferrer'
          class="btn inline-flex items-center text-gray-800 border ltr:border-b-0 ltr:border-s-0 ltr:bg-white rtl:border-e-0 rtl:border-t-0 hover:bg-green-600 hover:text-white border-green-500 rounded px-5 py-2.5 w-full md:w-auto justify-center font-montserrat"
        >
          <img src="https://res.cloudinary.com/dgl9hqn2p/image/upload/v1731334455/Zocdoc_tcuasp.png" alt="Zocdoc Icon" class="h-5 w-5 mr-2"/>
          Book in Zocdoc Calendar
        </a>
    </div>
</div>

            <div>
            <div class="2xl:pl-16">
                <h2 class="text-lg font-bold leading-tight text-black sm:text-xl lg:text-2xl lg:leading-tight text-center">Frequently Asked Questions</h2>
               <div className='w-full divide-y rounded divide-slate-200'>
               <details className="p-4 group">
          <summary className="relative cursor-pointer list-none pr-8 font-medium text-slate-700 transition-colors duration-300 focus-visible:outline-none group-hover:text-slate-900  [&::-webkit-details-marker]:hidden font-montserrat">
          How soon can I make an appointment with Febbie Mutindori?
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="absolute right-0 w-4 h-4 transition duration-300 top-1 shrink-0 stroke-slate-700 group-open:rotate-45"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="1.5"
              aria-labelledby="title-ac01 desc-ac01"
            >
              <title id="title-ac01">Open icon</title>
              <desc id="desc-ac01">
                icon that represents the state of the summary
              </desc>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 4v16m8-8H4"
              />
            </svg>
          </summary>
          <p className="mt-4 text-slate-500 font-montserrat">
          Generally, Febbie Mutindori has appointments available on Zocdoc within 1 week. You can see Febbie Mutindori's earliest availability on 
          Zocdoc and Headway to make an appointment online.
          </p>
        </details>
        <details className="p-4 group">
          <summary className="relative cursor-pointer list-none pr-8 font-medium text-slate-700 transition-colors duration-300 focus-visible:outline-none group-hover:text-slate-900  [&::-webkit-details-marker]:hidden font-montserrat">
          Is Febbie Mutindori accepting new patients?
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="absolute right-0 w-4 h-4 transition duration-300 top-1 shrink-0 stroke-slate-700 group-open:rotate-45"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="1.5"
              aria-labelledby="title-ac02 desc-ac02"
            >
              <title id="title-ac02">Open icon</title>
              <desc id="desc-ac02">
                icon that represents the state of the summary
              </desc>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 4v16m8-8H4"
              />
            </svg>
          </summary>
          <p className="mt-4 text-slate-500 font-montserrat">
          Febbie Mutindori generally accepts new patients on Zocdoc. You can see Febbie Mutindori's earliest availability on Zocdoc and Headway to schedule an appointment online.
          </p>
        </details>
        <details className="p-4 group">
          <summary className="relative cursor-pointer list-none pr-8 font-medium text-slate-700 transition-colors duration-300 focus-visible:outline-none group-hover:text-slate-900  [&::-webkit-details-marker]:hidden font-montserrat">
          Does Febbie Mutindori accept my insurance?
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="absolute right-0 w-4 h-4 transition duration-300 top-1 shrink-0 stroke-slate-700 group-open:rotate-45"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="1.5"
              aria-labelledby="title-ac03 desc-ac03"
            >
              <title id="title-ac03">Open icon</title>
              <desc id="desc-ac03">
                icon that represents the state of the summary
              </desc>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 4v16m8-8H4"
              />
            </svg>
          </summary>
          <p className="mt-4 text-slate-500 font-montserrat">
          Choose your insurance plan to verify if Febbie Mutindori is in-network.
          </p>
        </details>
        <details className="p-4 group">
          <summary className="relative cursor-pointer list-none pr-8 font-medium text-slate-700 transition-colors duration-300 focus-visible:outline-none group-hover:text-slate-900  [&::-webkit-details-marker]:hidden font-montserrat">
          Can I make an appointment with Febbie Mutindori online?
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="absolute right-0 w-4 h-4 transition duration-300 top-1 shrink-0 stroke-slate-700 group-open:rotate-45"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="1.5"
              aria-labelledby="title-ac04 desc-ac04"
            >
              <title id="title-ac04">Open icon</title>
              <desc id="desc-ac04">
                icon that represents the state of the summary
              </desc>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 4v16m8-8H4"
              />
            </svg>
          </summary>
          <p className="mt-4 text-slate-500 font-montserrat">
          Yes, you can make an appointment online with Febbie Mutindori using Zocdoc and Headway. It’s simple, secure, and free.
          </p>
        </details>


        <details className="p-4 group">
          <summary className="relative cursor-pointer list-none pr-8 font-medium text-slate-700 transition-colors duration-300 focus-visible:outline-none group-hover:text-slate-900  [&::-webkit-details-marker]:hidden font-montserrat">
          What practice does Febbie Mutindori work with?
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="absolute right-0 w-4 h-4 transition duration-300 top-1 shrink-0 stroke-slate-700 group-open:rotate-45"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="1.5"
              aria-labelledby="title-ac04 desc-ac04"
            >
              <title id="title-ac04">Open icon</title>
              <desc id="desc-ac04">
                icon that represents the state of the summary
              </desc>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 4v16m8-8H4"
              />
            </svg>
          </summary>
          <p className="mt-4 text-slate-500 font-montserrat">
          Febbie Mutindori works with Headway - Washington.
          </p>
        </details>

        <details className="p-4 group">
          <summary className="relative cursor-pointer list-none pr-8 font-medium text-slate-700 transition-colors duration-300 focus-visible:outline-none group-hover:text-slate-900  [&::-webkit-details-marker]:hidden font-montserrat">
          What are common reasons for patients to see Febbie Mutindori?
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="absolute right-0 w-4 h-4 transition duration-300 top-1 shrink-0 stroke-slate-700 group-open:rotate-45"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="1.5"
              aria-labelledby="title-ac04 desc-ac04"
            >
              <title id="title-ac04">Open icon</title>
              <desc id="desc-ac04">
                icon that represents the state of the summary
              </desc>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 4v16m8-8H4"
              />
            </svg>
          </summary>
          <p className="mt-4 text-slate-500 font-montserrat">
          Febbie Mutindori frequently sees patients for Depression, New Patient Visit, Psychiatry Consultation, Psychotherapy, and Sleep Problems. You can see other visit reasons for Febbie Mutindori on their profile.
          </p>
        </details>


        <details className="p-4 group">
          <summary className="relative cursor-pointer list-none pr-8 font-medium text-slate-700 transition-colors duration-300 focus-visible:outline-none group-hover:text-slate-900  [&::-webkit-details-marker]:hidden font-montserrat">
          What languages does Febbie Mutindori speak?
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="absolute right-0 w-4 h-4 transition duration-300 top-1 shrink-0 stroke-slate-700 group-open:rotate-45"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="1.5"
              aria-labelledby="title-ac04 desc-ac04"
            >
              <title id="title-ac04">Open icon</title>
              <desc id="desc-ac04">
                icon that represents the state of the summary
              </desc>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 4v16m8-8H4"
              />
            </svg>
          </summary>
          <p className="mt-4 text-slate-500 font-montserrat">
          Febbie Mutindori speaks English and Shona.
          </p>
        </details>



               </div>
            </div>
            </div>
        </div>
    </div>
</section>



  )
}

export default FAQ