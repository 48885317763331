import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Behavioralissie from '../../../Assets/Behavioralissie.jpg';
function BehavioralissuesDetails() {

    const navigate = useNavigate();

    const handleNavigation = (path) => {
      navigate(path);
    };
  
    const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  
    const handleVideoPlay = () => {
      setIsVideoPlaying(true);
    };
  
 
  return (
    <section className="py-10 bg-white sm:py-16 lg:py-24">
    <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
      <div className="grid items-center grid-cols-1 lg:grid-cols-2 gap-x-12 xl:gap-x-24 gap-y-12">
        {/* Left Column - Image Section */}
        <div className="relative lg:mb-12">
          <img
            className="absolute -right-0 -bottom-8 xl:-bottom-12 xl:-right-4"
            src="https://cdn.rareblocks.xyz/collection/celebration/images/content/3/dots-pattern.svg"
            alt=""
          />
          <div className="pl-12 pr-6">
            <img className="relative rounded-md" src={ Behavioralissie} alt="Behavioral Issue Details" />
          </div>
        </div>

        {/* Right Column - Introduction */}
        <div className="2xl:pl-16">
          <h2 className="text-xl font-bold text-black sm:text-2xl lg:text-3xl font-serif">
            Understanding Behavioral Issues
          </h2>
          <p className="text-md font-normal text-gray-600 font-montserrat mt-4">
            Behavioral issues refer to a range of conditions that impact a person’s ability to control emotions,
            actions, and interactions with others. These challenges can interfere with school, work, and personal
            relationships, often leading to distress for both individuals and those around them.
          </p>
          <p className="mt-6 text-md font-normal text-gray-600 font-montserrat">
            Addressing behavioral issues involves understanding the underlying causes and working on strategies to
            manage and improve behavior constructively.
          </p>
        </div>
      </div>
    </div>

    <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {/* Left Column - Causes & Symptoms */}
        <div className="bg-white p-6 rounded-lg">
          <h2 className="text-2xl font-bold mb-4 font-serif">Common Causes of Behavioral Issues</h2>
          <p className="text-md font-normal text-gray-600 font-montserrat mb-6">
            Behavioral problems can be caused by a variety of factors, including biological, psychological, and
            environmental influences. Here are some common causes:
          </p>
          <ul className="list-disc list-inside text-md font-normal text-gray-600 font-montserrat space-y-2">
            <li>Genetics and family history</li>
            <li>Traumatic experiences or abuse</li>
            <li>Brain chemistry imbalances</li>
            <li>Inconsistent discipline or lack of structure</li>
            <li>Substance abuse or exposure to toxic environments</li>
            <li>Stressful life events, such as divorce or loss</li>
          </ul>

          <h2 className="text-2xl font-bold mt-6 mb-4 font-serif">Common Symptoms:</h2>
          <ul className="list-disc list-inside text-md font-normal text-gray-600 font-montserrat space-y-2">
            <li>Frequent temper tantrums or outbursts</li>
            <li>Difficulty following rules or instructions</li>
            <li>Aggressive behavior or hostility towards others</li>
            <li>Social withdrawal or anxiety in social situations</li>
            <li>Impulsivity and poor judgment</li>
            <li>Defiance or arguing with authority figures</li>
          </ul>

          <h2 className="text-2xl  font-bold mb-4 font-serif mt-4">Learn More About Behavioral Issues:</h2>
            {!isVideoPlaying ? (
              <div
                className="relative cursor-pointer"
                style={{
                  paddingBottom: '56.25%',
                  height: 0,
                  overflow: 'hidden',
                  maxWidth: '100%',
                  position: 'relative',
                }}
                onClick={handleVideoPlay}
              >
                <img
                  className="absolute  top-0 left-0 w-full h-full object-cover rounded-lg"
                  src="https://img.youtube.com/vi/YFT4AQd8PzE/0.jpg" // Updated thumbnail for the new video
                  alt="Video Thumbnail"
                />
                <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="white"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    className="h-16 w-16 opacity-75"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M5.25 5.25l13.5 6.75-13.5 6.75V5.25z" />
                  </svg>
                </div>
              </div>
            ) : (
              <div
                className="relative mt-4 "
                style={{
                  paddingBottom: '56.25%',
                  height: 0,
                  overflow: 'hidden',
                  maxWidth: '100%',
                  position: 'relative',
                }}
              >
                <iframe
                  className="absolute top-0 left-0 w-full h-full"
                  src="https://www.youtube.com/embed/YFT4AQd8PzE?autoplay=1" // Updated with the correct video link
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                ></iframe>
              </div>
            )}



        </div>

        {/* Right Column - Management & Strategies */}
        <div className="bg-white p-6 rounded-lg">
          <h2 className="text-2xl font-bold mb-4 font-serif">Managing Behavioral Issues:</h2>
          <p className="text-md font-normal text-gray-600 font-montserrat mb-6">
            Managing behavioral issues requires a combination of strategies that include professional support,
            personal effort, and the involvement of family or friends. Here are some effective techniques:
          </p>
          <ul className="list-disc list-inside text-md font-normal text-gray-600 font-montserrat space-y-2">
            <li>
              <strong>Behavioral Therapy:</strong> Techniques that focus on changing negative behaviors and
              reinforcing positive actions.
            </li>
            <li>
              <strong>Medication:</strong> In some cases, medication may be prescribed to manage symptoms such as
              hyperactivity or mood swings.
            </li>
            <li>
              <strong>Support Groups:</strong> Joining a support group can provide encouragement, shared experiences,
              and coping strategies.
            </li>
            <li>
              <strong>Consistent Routine:</strong> Maintaining a structured routine helps establish boundaries and
              predictability.
            </li>
            <li>
              <strong>Positive Reinforcement:</strong> Rewarding good behavior encourages more of the same.
            </li>
            <li>
              <strong>Stress Management:</strong> Techniques like mindfulness, relaxation exercises, and deep
              breathing can help manage stress.
            </li>
          </ul>


          <h2 className="text-2xl mt-4 font-bold mb-4 font-serif">When to Seek Professional Help?</h2>
          <p className="text-md font-normal text-gray-600 font-montserrat">
            It is essential to seek professional help if behavioral issues significantly impact daily life, causing
            distress, social isolation, or difficulty maintaining relationships. Professional guidance can offer
            tailored strategies to manage behavior and improve well-being.
          </p>
          <h2 className="text-2xl font-bold mt-6 mb-4 font-serif">Therapies for Behavioral Issues:</h2>
          <ul className="list-disc list-inside text-md font-normal text-gray-600 font-montserrat space-y-2">
            <li>
              <strong>Cognitive Behavioral Therapy (CBT):</strong> Helps individuals change negative thought patterns
              and behavior.
            </li>
            <li>
              <strong>Family Therapy:</strong> Involves family members to improve communication and resolve conflicts.
            </li>
            <li>
              <strong>Play Therapy:</strong> Used for children to express feelings and work through issues in a safe
              environment.
            </li>
          </ul>


          <div className="flex flex-col mt-4 sm:flex-row justify-center lg:justify-start items-center gap-4">
  {/* Book an Appointment Button */}
  <button
    className="btn w-full sm:w-auto border-none bg-green-600 text-white rounded hover:bg-green-700  font-montserrat flex items-center justify-center sm:justify-start"
    onClick={() => handleNavigation('/Contact')}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      className="h-5 w-5 mr-1"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"
      />
    </svg>
    Book Virtual Appointment
  </button>
</div>



        </div>
      </div>
    </div>
  </section>
  )
}

export default BehavioralissuesDetails