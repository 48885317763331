import React ,{useState} from 'react'
import { useNavigate } from 'react-router-dom';
import AngerDetails from '../../../Assets/AngerDetails.jpg'
function AngerManagementDetails() {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
  };

  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  const handleVideoPlay = () => {
    setIsVideoPlaying(true);
  };


  return (
    <section class="py-10 bg-white sm:py-16 lg:py-24">
    <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div class="grid items-center grid-cols-1 lg:grid-cols-2 gap-x-12 xl:gap-x-24 gap-y-12">
            <div class="relative lg:mb-12">
                <img class="absolute -right-0 -bottom-8 xl:-bottom-12 xl:-right-4" src="https://cdn.rareblocks.xyz/collection/celebration/images/content/3/dots-pattern.svg" alt="" />
                <div class="pl-12 pr-6">
                    <img class="relative rounded-md" src={AngerDetails} alt="" />
                </div>
            </div>

            <div class="2xl:pl-16">
                <h2 class="text-xl font-bold text-black sm:text-2xl lg:text-3xl  font-serif">What Is Anger Management?</h2>
                <p class="text-md font-normal text-gray-600 font-montserrat mt-4">Anger management is the process of learning to recognize early signs of anger, understanding what triggers it, and finding positive ways to deal with it. It includes techniques to reduce both emotional and physical arousal, allowing individuals to respond calmly rather than with rage.</p>
                <p class="mt-6 text-md font-normal text-gray-600 font-montserrat">Anger Management involves learning techniques and strategies to control emotional responses, particularly when feeling anger or frustration. Effective anger management doesn't mean suppressing anger but rather expressing it in healthy and constructive ways.</p>
            </div>
        </div>
    </div>

    <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
    <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {/* Left Column */}
        <div className="bg-white p-6 rounded-lg">
            <h2 className="text-2xl font-bold mb-4 font-serif">How Anger Management Important?</h2>
            <p className="text-md font-normal text-gray-600 font-montserrat mb-6 ">
              Uncontrolled anger can lead to problems in relationships, work, and personal health. It can cause increased stress, anxiety, depression, and physical issues like high blood pressure. Effective anger management helps maintain healthy interactions, reduces stress, and promotes better mental and physical health.
            </p>

            <h2 className="text-2xl font-bold mb-4 font-serif">Common Symptoms of Anger Issues:</h2>
            <ul className="list-disc list-inside text-md font-normal text-gray-600 font-montserrat space-y-2">
              <li>Frequent outbursts or loss of temper</li>
              <li>Feelings of frustration or irritation over minor issues</li>
              <li>Physical signs like clenched fists, increased heart rate, or muscle tension</li>
              <li>Difficulty in calming down after getting angry</li>
              <li>Aggressive or passive-aggressive behavior</li>
              <li>Feeling overwhelmed, resentful, or easily annoyed</li>
            </ul>

            <h2 className="text-2xl mt-4 font-bold mb-4 font-serif">Causes of Anger Issues:</h2>
            <p className="text-md font-normal text-gray-600 font-montserrat mb-6">
              Anger can stem from various sources, and understanding its root causes can help in managing it effectively. Common causes include:
            </p>
            <ul className="list-disc list-inside text-md font-normal text-gray-600 font-montserrat space-y-2">
              <li>Stress from work, family, or financial problems.</li>
              <li>Past trauma or unresolved personal issues.</li>
              <li>Frustration due to unmet expectations or goals.</li>
              <li>Feelings of injustice or being wronged.</li>
              <li>Underlying mental health conditions like anxiety or depression.</li>
              <li>Physical factors like lack of sleep or chronic pain.</li>
            </ul>

            <div className="mt-6">
          <h2 className="text-2xl font-bold mb-4 font-serif">Watch this video to learn more:</h2>

          {/* Show the thumbnail before the video is played */}
          {!isVideoPlaying ? (
            <div
              className="relative cursor-pointer"
              style={{ paddingBottom: '56.25%', height: 0, overflow: 'hidden', maxWidth: '100%', position: 'relative' }}
              onClick={handleVideoPlay}
            >
              {/* YouTube video thumbnail */}
              <img
                className="absolute top-0 left-0 w-full h-full object-cover rounded-lg"
                src="https://img.youtube.com/vi/kmTEyxWg7Hs/maxresdefault.jpg"
                alt="Video Thumbnail"
              />
              {/* Play button overlay */}
              <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="white"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="h-16 w-16 opacity-75"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M5.25 5.25l13.5 6.75-13.5 6.75V5.25z"
                  />
                </svg>
              </div>
            </div>
          ) : (
            // Show the iframe once the user clicks to play the video
            <div className="relative" style={{ paddingBottom: '56.25%', height: 0, overflow: 'hidden', maxWidth: '100%', position: 'relative' }}>
              <iframe
                className="absolute top-0 left-0 w-full h-full"
                src="https://www.youtube.com/embed/kmTEyxWg7Hs?autoplay=1"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
              ></iframe>
            </div>
          )}
        </div>
          </div>
        {/* Right Column */}
        <div className="bg-white p-6 rounded-lg">
          <h2 className="text-2xl font-bold mb-4 font-serif">Techniques for Anger Management:</h2>
          <ul className="list-disc list-inside text-md font-normal text-gray-600 font-montserrat space-y-2 mb-6">
            <li>
              <strong>Deep Breathing:</strong> Slow, deep breaths can reduce tension and provide time to think before reacting.
            </li>
            <li>
              <strong>Mindfulness and Meditation:</strong> Practicing mindfulness helps increase awareness of emotions and reactions, leading to more controlled responses.
            </li>
            <li>
              <strong>Physical Exercise:</strong> Regular exercise can help manage stress, reduce feelings of anger, and release built-up tension.
            </li>
            <li>
              <strong>Timeouts:</strong> Taking a break from a situation that triggers anger allows time to cool down.
            </li>
            <li>
              <strong>Cognitive Restructuring:</strong> Changing the way one thinks about situations to reduce irrational beliefs and focus on problem-solving.
            </li>
            <li>
              <strong>Communication Skills:</strong> Learning to express feelings calmly and assertively rather than aggressively.
            </li>
            <li>
              <strong>Relaxation Techniques:</strong> Progressive muscle relaxation or visualization can decrease stress and prevent angry reactions.
            </li>
          </ul>

          <h2 className="text-2xl font-bold mb-4 font-serif">Therapies for Anger Management:</h2>
          <ul className="list-disc list-inside text-md font-normal text-gray-600 font-montserrat space-y-2">
            <li>
              <strong>Cognitive Behavioral Therapy (CBT):</strong> Helps individuals identify triggers and change patterns of thought and behavior that lead to anger.
            </li>
            <li>
              <strong>Group Therapy:</strong> Provides a supportive environment to share experiences and learn strategies from others dealing with similar issues.
            </li>
            <li>
              <strong>Individual Counseling:</strong> One-on-one sessions with a mental health professional can target specific triggers and create personalized strategies.
            </li>
            <li>
              <strong>Stress Management:</strong> Learning ways to manage stress effectively can reduce the frequency of anger outbursts.
            </li>
          </ul>

          <h2 className="text-2xl font-bold mt-6 font-serif">When to Seek Professional Help?</h2>
          <p className="text-md font-normal text-gray-600 font-montserrat">
            If anger is causing significant problems in personal or professional life or leading to harmful behavior,
            seeking help from a mental health professional is recommended. Therapists can guide individuals in finding
            the root causes of their anger and develop customized coping strategies.
          </p>

          <div className="flex flex-col mt-4 sm:flex-row justify-center lg:justify-start items-center gap-4">
  {/* Book an Appointment Button */}
  <button
    className="btn w-full sm:w-auto border-none bg-green-600 text-white rounded hover:bg-green-700  font-montserrat flex items-center justify-center sm:justify-start"
    onClick={() => handleNavigation('/Contact')}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      className="h-5 w-5 mr-1"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"
      />
    </svg>
    Book Virtual Appointment
  </button>
</div>



        </div>
      </div>
    </div>

</section>

  )
}

export default AngerManagementDetails