import { Routes, Route, useLocation, Link } from "react-router-dom";
import noappointment from '../../Assets/noappointment.svg';

function Unsuccessful() {
  return (
    <section className="relative z-10 bg-white py-4"> {/* Reduced padding for smaller devices */}
      <div className="grid h-[80vh] place-content-center bg-white px-4"> {/* Set a specific height */}
        <div className="text-center">
          <img
            src={noappointment} // Ensure this path is correct
            alt="No appointments found"
            className="max-w-full max-h-24 mx-auto mb-2 object-contain" // Adjust max height as needed
          />
          <p className="text-xl font-bold tracking-tight text-gray-900 sm:text-2xl lg:text-4xl">
            Error Setting up Schedule
          </p>
          <p className="mt-4 text-sm text-gray-500 sm:text-base lg:text-lg font-montserrat max-w-lg mx-auto">
            An error occurred while attempting to set up the schedule. Please review the provided information. If further assistance is needed, feel free to contact support for help resolving the problem.
          </p>

          <Link
            to="/Contact"
            className="mt-6 inline-block rounded bg-[#4ADE80] px-5 py-3 text-xs sm:text-sm lg:text-base font-medium text-white hover:bg-[#34C759] focus:outline-none focus:ring"
          >
            Retry Again
          </Link>
        </div>
      </div>
    </section>
  );
}

export default Unsuccessful;
