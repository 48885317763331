import React, { useState } from 'react';

import 'flatpickr/dist/themes/material_green.css'; // Import a Flatpickr theme
import { motion } from 'framer-motion';
import { fadeIn } from '../../Variants';
import Swal from 'sweetalert2';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import USFLAG from '../../Assets/USFLAG.svg'
import ReCAPTCHA from "react-google-recaptcha";
const Contactus = () => {
  const navigate = useNavigate(); // Initialize useNavigate

  // Function to handle form submission
  const [values, setValues] = useState({
    FullName: '',
    PhoneNumber: '+1 ',
    EmailAddress: '',
    Age: '',
    Date: '',
    Time: '',
    Message: '',
    AmPm: 'AM',
  });

  const [phoneError, setPhoneError] = useState(''); // Error message for phone validation
  const [recaptchaValue, setRecaptchaValue] = useState(null); // Add reCAPTCHA value state
  const handlePhoneNumberChange = (e) => {
    let input = e.target.value;

    // Ensure the input starts with "+1 "
    if (!input.startsWith('+1 ')) {
        input = '+1 ';
    }

    // Only keep numeric characters after "+1 "
    input = '+1 ' + input.slice(3).replace(/[^0-9]/g, '');

    // Add spaces after the third and sixth digits after "+1 "
    if (input.length > 6) {
        input = input.slice(0, 6) + ' ' + input.slice(6);
    }
    if (input.length > 10) {
        input = input.slice(0, 10) + ' ' + input.slice(10);
    }

    // Limit input to "+1 XXX XXX XXXX" format (15 characters total)
    if (input.length > 15) {
        input = input.slice(0, 15);
    }

    setValues((prevValues) => ({
        ...prevValues,
        PhoneNumber: input,
    }));

    // Update error message based on length
    if (input.length < 15) {
        setPhoneError('Please enter a 10-digit phone number after "+1".');
    } else {
        setPhoneError('');
    }
};




  const handleTimeChange = (e) => {
      const timeValue = e.target.value;
      const [hours, minutes] = timeValue.split(":");
      const hour = parseInt(hours, 10);
      const formattedTime = hour === 0 ? `12:${minutes} AM` : hour < 12 ? `${hour}:${minutes} AM` : `${hour - 12}:${minutes} PM`;
      
      setValues((prevValues) => ({
        ...prevValues,
        Time: formattedTime, // Store formatted time with AM/PM
        AmPm: hour < 12 ? "AM" : "PM", // Determine AM/PM
      }));
    };


    const handleDateChange = (e) => {
      const selectedDate = e.target.value;
      const [year, month, day] = selectedDate.split('-');
      const formattedDate = `${month}/${day}/${year}`;
      
      setValues({
        ...values,
        Date: formattedDate,
      });
    };

    

    const handleSubmit = (e) => {
      e.preventDefault();
      if (phoneError || values.PhoneNumber.length !== 15) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid Phone Number',
          text: phoneError || 'Please enter a 10-digit phone number after "+1 "',
        });
        return;
      }
      if (!recaptchaValue) {
        Swal.fire({
          icon: 'error',
          title: 'Please complete the reCAPTCHA',
          text: 'You must complete the reCAPTCHA to submit the form.',
        });
        return;
      }
      axios.post('https://phoebe-compass-point-560accfa31d6.herokuapp.com/add_Appointment', values)
        .then(() => {
          navigate('/Success');
          window.location.reload();
        })
        .catch(err => {
          console.error('Error adding appointment:', err);
          navigate('/Unsuccess');
        });
    };
  
    const onRecaptchaChange = (value) => {
      setRecaptchaValue(value);
    };
  return (
    <section className="py-10 bg-gray-900 sm:py-16 lg:py-24">
      <div className="max-w-6xl px-4 mx-auto sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:items-stretch md:grid-cols-2 gap-x-12 lg:gap-x-20 gap-y-10">
          <div className="flex flex-col justify-between lg:py-5">
            <div>
              <motion.h2
                variants={fadeIn('down', 0.2)}
                initial="hidden"
                whileInView={'show'}
                viewport={{ once: false, amount: 0.7 }}
                className="text-3xl font-bold leading-tight text-white sm:text-4xl  lg:text-5xl font-serif"
              >
                Contact us today to schedule your initial appointment
              </motion.h2>
              <motion.p
                variants={fadeIn('zoom', 0.2)}
                initial="hidden"
                whileInView={'show'}
                viewport={{ once: false, amount: 0.7 }}
                className="max-w-xl mx-auto mt-4 text-base leading-relaxed text-white font-montserrat"
              >
                Your mental health matters, and taking that first step toward
                professional help is an act of self-care. At Phoebe Compass
                Point, we are here to help you every step of the way with expert
                care that is both compassionate and personalized.
              </motion.p>
              
              <div className='mt-8'>
  <h2 className="text-2xl font-bold leading-tight text-white sm:text-xl lg:text-xl font-serif">
    Follow these Instructions to Set Your Initial Appointment
  </h2>
  <p className="max-w-xl mx-auto mt-4 text-base leading-relaxed text-white font-montserrat">
    We’re excited to help you get started. Follow these simple steps to easily set up your appointment.
  </p>
  
  <ul className="list-none mt-4 text-white font-montserrat">
    <li className="mt-2"><strong>Step 1: </strong>Enter your Personal information.</li>
    <li className="mt-2"><strong>Step 2: </strong>Choose a Date and Time, then confirm your selection.</li>
    <li className="mt-2"><strong>Step 3: </strong>Click Schedule Virtual Appointment</li>
  </ul>
</div>


            <div className='mt-6'>
  <h2 className="sr-only">Steps</h2>

  <div>
    <ol
      className="grid grid-cols-1 divide-x divide-gray-100 overflow-hidden rounded-lg border border-gray-100 text-sm text-gray-500 sm:grid-cols-3"
    >
  <li className="flex items-center justify-center gap-2 p-4">
      <svg
               className="size-7 shrink-0"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth="2"
      >
  <path stroke-linecap="round" stroke-linejoin="round" d="M15.042 21.672 13.684 16.6m0 0-2.51 2.225.569-9.47 5.227 7.917-3.286-.672ZM12 2.25V4.5m5.834.166-1.591 1.591M20.25 10.5H18M7.757 14.743l-1.59 1.59M6 10.5H3.75m4.007-4.243-1.59-1.59" />
</svg>

        <p className="leading-none">
          <strong className="block font-medium text-gray-200"> Fill-up Form </strong>
          <small className="mt-1 text-gray-200">Fill-up Personal Information. </small>
        </p>
      </li>




      <li className="relative flex items-center justify-center gap-2 bg-gray-800 p-4">
        <span
          className="absolute -left-2 top-1/2 hidden size-4 -translate-y-1/2 rotate-45 border border-gray-100 sm:block ltr:border-b-0 ltr:border-s-0 ltr:bg-white rtl:border-e-0 rtl:border-t-0 rtl:bg-gray-50"
        >
        </span>

        <span
          className="absolute -right-2 top-1/2 hidden size-4 -translate-y-1/2 rotate-45 border border-gray-100 sm:block ltr:border-b-0 ltr:border-s-0 ltr:bg-gray-50 rtl:border-e-0 rtl:border-t-0 rtl:bg-white"
        >
        </span>

        <svg 
         className="size-7 shrink-0"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth="2"
        
        >
  <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
</svg>


        <p className="leading-none">
          <strong className="block font-medium text-gray-200"> Schedule </strong>
          <small className="mt- text-gray-200"> Select Preferred Date & Time. </small>
        </p>
      </li>

   


      <li className="flex items-center justify-center gap-2 p-4">
      <svg
               className="size-7 shrink-0"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth="2"
      >
  <path stroke-linecap="round" stroke-linejoin="round" d="M15.042 21.672 13.684 16.6m0 0-2.51 2.225.569-9.47 5.227 7.917-3.286-.672ZM12 2.25V4.5m5.834.166-1.591 1.591M20.25 10.5H18M7.757 14.743l-1.59 1.59M6 10.5H3.75m4.007-4.243-1.59-1.59" />
</svg>

        <p className="leading-none">
          <strong className="block font-medium text-gray-200"> Confirm </strong>
          <small className="mt-1 text-gray-200">Click Schedule Appointment. </small>
        </p>
      </li>
    </ol>
  </div>
  <div className="bg-gray-800 border  sm:block ltr:border-b-0 ltr:border-s-0 ltr:bg-white rtl:border-e-0 rtl:border-t-0 mt-4 p-4 rounded-md shadow-sm">
  <div className="flex justify-center items-center space-x-2 mb-2">
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-6 w-6 text-white">
      <path strokeLinecap="round" strokeLinejoin="round" d="M6 20.25h12m-7.5-3v3m3-3v3m-10.125-3h17.25c.621 0 1.125-.504 1.125-1.125V4.875c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125Z" />
    </svg>
    <span className="text-gray-200">|</span>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-6 w-6 text-white">
      <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 1.5H8.25A2.25 2.25 0 0 0 6 3.75v16.5a2.25 2.25 0 0 0 2.25 2.25h7.5A2.25 2.25 0 0 0 18 20.25V3.75a2.25 2.25 0 0 0-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3" />
    </svg>
  </div>
  <h2 className="text-lg font-semibold text-center text-gray-200 font-montserrat">
    Virtual Sessions Only
  </h2>
  <p className=" text-gray-200 text-center font-montserrat">
    For your convenience and safety, we only offer Virtual Sessions at this time. Reach out to schedule your online appointment today!
  </p>
</div>


</div>
            </div>
          </div>

          <div className="lg:pl-12">
            <div className="overflow-hidden bg-white rounded-md">
              <div className="p-6 sm:p-10">
                <h3 className="text-3xl font-semibold text-black font-serif">
                  Get In touch
                </h3>
                <p className="mt-4 text-base text-gray-600 font-montserrat">
                  Have questions or need support? Fill out the form, and we’ll
                  be in touch to guide you on your path to well-being.
                </p>
                <form
  onSubmit={handleSubmit}
  action="#"
  method="POST"
  className="mt-4"
>
  <div className="space-y-6">
    <div className="grid grid-cols-1 md:grid-cols-2 md:gap-6">
      <div>
        <label
          htmlFor="Name"
          className="text-base font-medium text-gray-900 font-serif"
        >
          Name
        </label>
        <div className="mt-2.5 relative">
                    <input
                      type="text"
                      name="FullName"
                      id="FullName"
                      placeholder="Full Name"
                      className="block w-full px-4 py-4 text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 rounded-md focus:outline-none focus:ring-orange-500 focus:border-orange-500 caret-orange-500 font-montserrat"
                      onChange={(e) => setValues({ ...values, FullName: e.target.value })}
                      required
                    />
                  </div>
      </div>

      <div>
        <label
          htmlFor="Age"
          className="text-base font-medium text-gray-900 font-serif"
        >
          Age
        </label>
        <div className="mt-2.5 relative">
          <input
            type="number"
            id="Age"
            name="Age"
            onChange={(e) => setValues({ ...values, Age: e.target.value })}
            className="block w-full px-4 py-4 text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 rounded-md focus:outline-none focus:ring-orange-500 focus:border-orange-500 caret-orange-500 font-montserrat"
            placeholder="Enter Age"
            required
          />
        </div>
      </div>
    </div>

    <div>
                      <label
                        htmlFor="PhoneNumber"
                        className="text-base font-medium text-gray-900 font-serif"
                      >
                        Phone Number
                      </label>
                      <div className="mt-2.5 relative">
                        <span className="absolute left-3 top-1/2 transform -translate-y-1/2">
                          <img src={USFLAG} alt="U.S. Flag" className="w-6 h-6" />
                        </span>
                        <input
                          type="text"
                          name="PhoneNumber"
                          id="PhoneNumber"
                          placeholder="Enter Phone Number"
                          value={values.PhoneNumber}
                          maxLength="15"
                          className="block w-full px-12 py-4 text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 rounded-md focus:outline-none focus:ring-orange-500 focus:border-orange-500 caret-orange-500 font-montserrat"
                          onChange={handlePhoneNumberChange}
                          required
                        />
                      </div>
                      {phoneError && (
                          <p className="mt-1 text-red-600 text-sm font-medium">{phoneError}</p>
                        )}
                    </div>


    <div>
      <label
        htmlFor="EmailAddress"
        className="text-base font-medium text-gray-900 font-serif"
      >
        Email address
      </label>
      <div className="mt-2.5 relative">
        <input
          type="email"
          name="EmailAddress"
          id="EmailAddress"
          placeholder="Enter your Email Address"
          className="block w-full px-4 py-4 text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 rounded-md focus:outline-none focus:ring-orange-500 focus:border-orange-500 caret-orange-500 font-montserrat"
          onChange={(e) => setValues({ ...values, EmailAddress: e.target.value })}
          required
        />
      </div>
    </div>


    <div className="flex flex-col space-y-4 md:flex-row md:space-x-4 md:space-y-0">
    <div className="w-full md:w-1/2">
      <label htmlFor="Date" className="text-base font-medium text-gray-900 font-serif">
        Select Preferred Date
      </label>
      <div className="mt-2.5 relative">
        <input
          type="date"
          name="Date"
          id="Date"
          className="block w-full px-4 py-4 text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 rounded-md focus:outline-none focus:ring-orange-500 focus:border-orange-500 caret-orange-500 font-montserrat"
          onChange={handleDateChange}
          required
        />
      </div>
      <p className='hidden'>Selected Date: {values.Date}</p> {/* Optional display of the formatted date */}
    </div>

  <div className="w-full md:w-1/2">
    <label
      htmlFor="Time"
      className="text-base font-medium text-gray-900 font-serif"
    >
      Select Preferred Time
    </label>
    <div className="mt-2.5 relative">
      <input
        type="time"
        name="Time"
        id="Time"
        className="block w-full px-4 py-4 text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 rounded-md focus:outline-none focus:ring-orange-500 focus:border-orange-500 caret-orange-500 font-montserrat"
        onChange={handleTimeChange}
        required
      />
    </div>
    <div className="mt-2 text-gray-600 hidden">
      {values.Time && <span>{values.AmPm}</span>} {/* Display AM/PM */}
    </div>
  </div>
</div>

    <div>
      <label
        htmlFor="Message"
        className="text-base font-medium text-gray-900 font-serif"
      >
        Message
      </label>
      <div className="mt-2.5 relative">
        <textarea
          name="Message"
          id="Message"
          placeholder="Question/Comment"
          className="block w-full px-4 py-4 text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 rounded-md focus:outline-none focus:ring-orange-500 focus:border-orange-500 caret-orange-500 font-montserrat"
          onChange={(e) => setValues({ ...values, Message: e.target.value })}
          required
        />
      </div>
    </div>
    <div className='flex items-center justify-center'>
    <ReCAPTCHA
    sitekey="6LeToXgqAAAAAHAofZ7ugg8wZR35wkuWE5M1-P1O"
    onChange={onRecaptchaChange}
  />
  </div>
    <div>
                <button type="submit" className="inline-flex items-center justify-center w-full px-4 py-3 text-sm font-semibold text-white transition-all duration-200 bg-[#4ADE80] border border-transparent rounded-md hover:bg-[#34C759] focus:bg-[#34C759]">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-5 w-5 mr-2">
                    <path strokeLinecap="round" strokeLinejoin="round" d="m15.75 10.5 4.72-4.72a.75.75 0 0 1 1.28.53v11.38a.75.75 0 0 1-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25h-9A2.25 2.25 0 0 0 2.25 7.5v9a2.25 2.25 0 0 0 2.25 2.25Z" />
                  </svg>
                  Schedule Virtual Appointment
                </button>
              </div>



  </div>
</form>
               
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contactus;
