
import FormUnsuccess from '../../components/WebsiteComponents/Unsuccessful';

export const Unsuccess = () => {
    return (
        <> 
            <FormUnsuccess />
        </>
    );
}
