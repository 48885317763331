import React from 'react';
import abt4 from '../../Assets/abt4.jpg'
import abt2 from '../../Assets/abt2.jpg'
import abt3 from '../../Assets/abt3.jpg'
import {motion} from 'framer-motion';
import {fadeIn} from '../../Variants'
import { useNavigate } from 'react-router-dom'; 

function Aboutus() {
  const navigate = useNavigate(); 

  const handleNavigation = (path) => {
    navigate(path);
  };




  return (
    <section className="overflow-hidden pt-4 pb-12 lg:pt-[50px] lg:pb-[60px] bg-gradient-to-b from-green-50 to-green-100 dark:bg-dark">
    <div className="container mx-auto p-4 ">
      <div className="flex flex-wrap items-center justify-center">
      <div className="w-full px-4 lg:w-1/2 xl:w-5/12">
          <div className="mt-10 lg:mt-0">
          <div className='max-w-7xl mx-auto'> 
          <motion.h2
  variants={fadeIn("down", 0.2)}
  initial="hidden"
  whileInView={"show"}
  viewport={{ once: false, amount: 0.7 }}
  className="text-2xl font-bold  leading-tight text-black  lg:text-4xl font-serif  text-center sm:text-left "
>
  Experience Compassionate Care Tailored to you
</motion.h2>
<motion.p
  variants={fadeIn("zoom", 0.2)}
  initial="hidden"
  whileInView="show"
  viewport={{ once: false, amount: 0.7 }}
  className="mb-5 mt-2 font-medium text-gray-600 font-montserrat text-center sm:text-left text-sm sm:text-base md:text-md lg:text-lg"
>
  At Phoebe Compass Point, our mission is to provide compassionate, high-quality mental health care that empowers individuals to lead healthier and more fulfilling lives.
</motion.p>
 </div>

            <div class="space-y-4 lg:pr-16 xl:pr-24 lg:col-span-2 lg:space-y-4">
                <motion.div
                   variants={fadeIn("zoom",0.2)}
        initial="hidden"
        whileInView={"show"}
        viewport={{once: false, amount:0.7}}
                 class="flex items-start">
                    <svg class="flex-shrink-0 text-green-500 w-9 h-9" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" d="M10.05 4.575a1.575 1.575 0 1 0-3.15 0v3m3.15-3v-1.5a1.575 1.575 0 0 1 3.15 0v1.5m-3.15 0 .075 5.925m3.075.75V4.575m0 0a1.575 1.575 0 0 1 3.15 0V15M6.9 7.575a1.575 1.575 0 1 0-3.15 0v8.175a6.75 6.75 0 0 0 6.75 6.75h2.018a5.25 5.25 0 0 0 3.712-1.538l1.732-1.732a5.25 5.25 0 0 0 1.538-3.712l.003-2.024a.668.668 0 0 1 .198-.471 1.575 1.575 0 1 0-2.228-2.228 3.818 3.818 0 0 0-1.12 2.687M6.9 7.575V12m6.27 4.318A4.49 4.49 0 0 1 16.35 15m.002 0h-.002" />
</svg>
                    <div class="ml-5">
                        <h3 class="text-xl font-semibold text-black font-serif">Compassion</h3>
                        <p class="mt-2 text-base text-gray-600 font-montserrat font-medium">We lead with empathy, listening to our clients’ needs and concerns with genuine care and understanding.</p>
                    </div>
                </motion.div>
                <motion.div
                 variants={fadeIn("zoom",0.3)}
        initial="hidden"
        whileInView={"show"}
        viewport={{once: false, amount:0.7}}
                 class="flex items-start">
                    <svg class="flex-shrink-0 text-blue-600 w-9 h-9" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z" />
</svg>
                    <div class="ml-5">
                        <h3 class="text-xl font-semibold text- font-serif">Collaboration</h3>
                        <p class="mt-2 text-base text-gray-600 font-montserrat font-medium">We believe in a team-based approach, working with other mental health providers to ensure you receive the comprehensive care you deserve.</p>
                    </div>
                </motion.div>

                <motion.div
   variants={fadeIn("zoom",0.4)}
        initial="hidden"
        whileInView={"show"}
        viewport={{once: false, amount:0.7}}
                 class="flex items-start">
                    <svg class="flex-shrink-0 text-red-500 w-9 h-9" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                    </svg>
                    <div class="ml-5">
                        <h3 class="text-xl font-semibold text-black font-serif">Personalized Care</h3>
                        <p class="mt-2 text-base text-gray-600 font-montserrat font-medium">Every treatment plan is crafted to fit the individual’s unique circumstances, promoting the best possible outcomes.</p>
                    </div>
                </motion.div>
            </div>
      
          </div>
          <div className="flex mt-4 justify-center lg:justify-start items-center">
  <button
    className="btn bg-green-600 text-white rounded hover:bg-green-700 mt-4 font-montserrat"
    onClick={() => handleNavigation('/Contact')}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      className="h-5 w-5 mr-2"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"
      />
    </svg>
    Book Virtual Appointment
  </button>
</div>
        </div>
       
        <div className="w-full mt-4 px-4 lg:w-6/12">
  <div className="flex items-center -mx-3 sm:-mx-4">
  <div className="w-full px-3 sm:px-4 xl:w-1/2">
  <motion.div whileHover={{ scale: 1.05, rotate: 2 }} className="py-3 sm:py-4 relative">
    <div className="relative">
      <img
        src={abt4}
        alt="Anxiety"
        className="w-[300px] sm:w-[350px] md:w-[400px] h-[250px] sm:h-[250px] md:h-[300px] rounded-2xl shadow-lg"
      />
      <div className="absolute top-4 left-2 text-gray-600 bg-white font-semibold text-sm rounded-full px-2 py-1 font-montserrat">
        Depression
      </div>
      <p className="absolute bottom-2 left-2 right-2 text-white bg-gray-800 bg-opacity-70 rounded p-2 text-xs sm:text-xs md:text-sm font-montserrat">
  Depression is a common mental disorder characterized by persistent sadness and a lack of interest in activities.
</p>

    </div>
  </motion.div>

  <motion.div whileHover={{ scale: 1.05, rotate: -2 }} className="py-3 sm:py-1 relative">
    <div className="relative">
      <img
        src={abt2}
        alt="Image 2"
        className="w-[300px] sm:w-[350px] md:w-[400px] h-[250px] sm:h-[250px] md:h-[300px] rounded-2xl shadow-lg"
      />
      <div className="absolute top-4 left-2 text-gray-600 bg-white font-semibold text-sm rounded-full px-2 py-1 font-montserrat">
        Anxiety
      </div>
      <p className="absolute bottom-2 left-2 right-2 text-white bg-gray-800 bg-opacity-70 rounded p-2 text-xs sm:text-xs md:text-sm font-montserrat">
        Anxiety involves feelings of worry, fear, or unease, often about an upcoming event or uncertain outcome.
      </p>
    </div>
  </motion.div>
</div>


    <div className="w-full xl:w-1/2">
      <motion.div whileHover={{ scale: 1.05, rotate: 2 }} className="relative z-10 my-4 relative">
        <div className="relative">
          <img
            src={abt3}
            alt="Image 3"
            className="w-[300px] sm:w-[350px] md:w-[400px] h-[300px] sm:h-[300px] md:h-[400px] rounded-2xl shadow-lg"
          />
          <div className="absolute top-4 left-2 text-gray-600 bg-white font-semibold text-sm rounded-full px-2 py-1 font-montserrat">
            ADHD
          </div>
          <p className="absolute bottom-2 left-2 right-2 text-white bg-gray-800 bg-opacity-70 rounded p-2 text-xs sm:text-xs md:text-sm font-montserrat">
            ADHD is a neurodevelopmental disorder that affects attention, impulse control, and hyperactivity levels.
          </p>
        </div>
      </motion.div>
    </div>
  </div>
</div>
      </div>
    </div>
  </section>
  )
}

export default Aboutus