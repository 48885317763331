import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { FaTrash } from 'react-icons/fa';
import { MdEmail } from "react-icons/md";
import { FiSearch } from 'react-icons/fi';
import { saveAs } from 'file-saver'; // Import file-saver
import sampleemaillog from '../../../Assets/Emaillogo.png'; 
import noappointment from '../../../Assets/noappointment.svg';

function AdminNewsLetter() {
  const [emails, setEmails] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [emailsPerPage] = useState(20);

  useEffect(() => {
    const fetchEmails = async () => {
      try {
        const response = await fetch('https://phoebe-compass-point-560accfa31d6.herokuapp.com/api/newsletter', {
          credentials: 'include',
        });
        const data = await response.json();
        setEmails(data);
      } catch (error) {
        console.error('Error fetching emails:', error);
        Swal.fire('Error', 'Could not fetch emails', 'error');
      }
    };
  
    fetchEmails();
  }, []);

  const handleDownloadCSV = () => {
    const csvData = emails.map(email => `${email.EmailID},${email.Email}`).join('\n');
    const csvBlob = new Blob([`Email-ID,Email\n${csvData}`], { type: 'text/csv;charset=utf-8;' });
    saveAs(csvBlob, 'newsletter-emails.csv');
  };

  const handleSendInvitation = (email) => {
    Swal.fire({
      title: 'Are you sure?',
      text: `Do you want to send an invitation to ${email}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, send it!',
      cancelButtonText: 'No, cancel',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await fetch('https://phoebe-compass-point-560accfa31d6.herokuapp.com/api/send-invitation', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              to: email,
              subject: 'Discover a Path to Better Mental Health with Phoebe Compass Point',
              message: `
                <div style="font-family: Arial, sans-serif; color: #333;">
                  <div style="text-align: center; padding: 20px;">
                    <img src="https://res.cloudinary.com/dbnchrysj/image/upload/v1727302405/Logo_qkgwl8.png" alt="Mental Health Logo" style="width: 200px; margin-top: 20px;" />
                  </div>
                  <h2 style="color: #0066cc;">Your Mental Health, Our Priority</h2>
                  <p style="font-size: 16px;">
                    Welcome to <strong>Phoebe Compass Point</strong>! We understand that mental well-being is essential to living a fulfilling life, and we’re here to support you every step of the way. Our dedicated team of professionals is ready to provide compassionate care tailored to your unique needs—all through convenient, confidential virtual sessions.
                  </p>
                  <p style="font-size: 16px;">
                    Whether you're seeking guidance, support, or simply someone to listen, we offer a range of services to help you take charge of your mental health.
                  </p>
                  <p style="font-size: 16px; font-weight: bold;">
                    Explore our virtual services:
                  </p>
                  <table style="width: 100%; border-collapse: collapse; margin-top: 20px;">
                    <thead>
                      <tr style="background-color: #f0f0f0;">
                        <th style="padding: 10px; border: 1px solid #ddd;">Service</th>
                        <th style="padding: 10px; border: 1px solid #ddd;">Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td style="padding: 10px; border: 1px solid #ddd;">Anger Management</td>
                        <td style="padding: 10px; border: 1px solid #ddd;">Learn to channel your emotions constructively and regain control of your responses.</td>
                      </tr>
                      <tr>
                        <td style="padding: 10px; border: 1px solid #ddd;">Anxiety</td>
                        <td style="padding: 10px; border: 1px solid #ddd;">Find peace and balance with tools to manage anxiety in your everyday life.</td>
                      </tr>
                      <tr>
                        <td style="padding: 10px; border: 1px solid #ddd;">Behavioral Issues</td>
                        <td style="padding: 10px; border: 1px solid #ddd;">Work through behavioral challenges to foster positive change and self-growth.</td>
                      </tr>
                      <tr>
                        <td style="padding: 10px; border: 1px solid #ddd;">Depression</td>
                        <td style="padding: 10px; border: 1px solid #ddd;">We’re here to support you in navigating feelings of depression with empathy and care.</td>
                      </tr>
                      <tr>
                        <td style="padding: 10px; border: 1px solid #ddd;">Grief</td>
                        <td style="padding: 10px; border: 1px solid #ddd;">Receive compassionate guidance as you cope with the loss of a loved one.</td>
                      </tr>
                      <tr>
                        <td style="padding: 10px; border: 1px solid #ddd;">Medication Management</td>
                        <td style="padding: 10px; border: 1px solid #ddd;">Ensure that your medications are effectively supporting your mental health journey.</td>
                      </tr>
                      <tr>
                        <td style="padding: 10px; border: 1px solid #ddd;">Relationship Issues</td>
                        <td style="padding: 10px; border: 1px solid #ddd;">Get support navigating conflicts and strengthening relationships in a healthy way.</td>
                      </tr>
                      <tr>
                        <td style="padding: 10px; border: 1px solid #ddd;">Trauma and PTSD</td>
                        <td style="padding: 10px; border: 1px solid #ddd;">Heal and move forward from trauma with expert guidance and care.</td>
                      </tr>
                      <tr>
                        <td style="padding: 10px; border: 1px solid #ddd;">ADHD</td>
                        <td style="padding: 10px; border: 1px solid #ddd;">Get structured support to manage ADHD and thrive in your daily life.</td>
                      </tr>
                    </tbody>
                  </table>
                  <div style="text-align: center; margin-top: 20px;">
                    <a href="https://phoebecompasspoint.com/Contact" style="padding: 10px 20px; background-color: #4ADE80; color: white; text-decoration: none; border-radius: 5px;">
                      Book Your Virtual Session Today
                    </a>
                  </div>
                  <p style="font-size: 16px; text-align: center; margin-top: 20px;">
                    We're just a click away—reach out today to start your journey toward better mental health.
                  </p>
                  <div style="margin-top: 30px; text-align: center; font-size: 12px; color: #888;">
                    <p>For more details, <a href="https://phoebecompasspoint.com" style="color: #0066cc;">visit our website</a>.</p>
                  </div>
                </div>
              `,
            }),
          });
  
          if (response.ok) {
            Swal.fire('Sent!', `The invitation has been sent to ${email}`, 'success');
          } else {
            Swal.fire('Error', 'Failed to send the email.', 'error');
          }
        } catch (error) {
          console.error('Error sending email:', error);
          Swal.fire('Error', 'Failed to send the email.', 'error');
        }
      }
    });
  };

  const handleDelete = async (emailID) => {
    const confirmed = await Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    });

    if (confirmed.isConfirmed) {
      try {
        await fetch(`https://phoebe-compass-point-560accfa31d6.herokuapp.com/api/newsletter/${emailID}`, {
          method: 'DELETE',
        });
        setEmails(emails.filter(email => email.EmailID !== emailID));
        Swal.fire('Deleted!', 'The email has been deleted.', 'success');
      } catch (error) {
        console.error('Error deleting email:', error);
        Swal.fire('Error', 'Could not delete email', 'error');
      }
    }
  };

  const filteredEmails = emails.filter(email =>
    email.Email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const totalPages = Math.ceil(filteredEmails.length / emailsPerPage);
  const indexOfLastEmail = currentPage * emailsPerPage;
  const indexOfFirstEmail = indexOfLastEmail - emailsPerPage;
  const currentEmails = filteredEmails.slice(indexOfFirstEmail, indexOfLastEmail);

  const goToPage = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };


  return (
    <div className="container mt-8 mx-auto p-6">
      <div className="flex items-center justify-between mb-4">
        <h1 className="text-3xl font-bold text-gray-800">Newsletter Email Subscriber</h1>
        <div className="relative">
          <input
            type="text"
            className="pl-10 pr-4 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Search Email"
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
          />
          <span className="absolute left-3 top-1/2 transform -translate-y-1/2">
            <FiSearch className="h-5 w-5 text-gray-500" />
          </span>
        </div>
      </div>

      <div className="overflow-x-auto rounded-lg">
        <table className="min-w-full border-collapse border-2 border-gray-200">
          <thead className="bg-gray-100">
            <tr>
              {['Email-ID', 'Email Address', 'Actions'].map(header => (
                <th key={header} className="border border-gray-300 px-4 py-3 text-center text-sm font-semibold text-gray-600">
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {currentEmails.length > 0 ? (
              currentEmails.map(email => (
                <tr key={email.EmailID} className="hover:bg-gray-50 transition text-center">
                  <td className="border border-gray-200 px-4 py-2 text-sm text-gray-700">{email.EmailID}</td>
                  <td className="border border-gray-200 px-4 py-2 text-sm text-gray-700">{email.Email}</td>
                  <td className="border border-gray-200 px-4 py-2 text-center">
                    <div className="flex justify-center space-x-3">
                      <button
                        className="bg-green-500 text-white hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-blue-300 rounded-md px-3 py-1 transition ease-in-out flex items-center"
                        title="Send Invitation"
                        onClick={() => handleSendInvitation(email.Email)}
                      >
                        <MdEmail className="mr-1" />
                        <span>Send Invitation</span>
                      </button>
                      <button
                        className="bg-red-500 text-white hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-300 rounded-md px-3 py-1 transition ease-in-out flex items-center"
                        title="Delete Email"
                        onClick={() => handleDelete(email.EmailID)}
                      >
                        <FaTrash className="mr-1" />
                        <span>Delete Email</span>
                      </button>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" className="border border-gray-200 px-4 py-4 text-sm text-center text-gray-700">
                  <img
                    src={noappointment}
                    alt="No appointments found"
                    className="max-w-full max-h-24 mx-auto mb-2 object-contain"
                  />
                  <span className="font-montserrat">No Emails found.</span>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

    {/* Specified Pagination Layout */}
    <div className="flex justify-between items-center mt-2">
  <div className="inline-flex justify-center gap-1">
    <a
      href="#"
      onClick={() => goToPage(currentPage - 1)}
      className="inline-flex size-8 items-center justify-center rounded border border-gray-100 bg-white text-gray-900"
      disabled={currentPage === 1}
    >
      <span className="sr-only">Prev Page</span>
      <svg xmlns="http://www.w3.org/2000/svg" className="size-3" viewBox="0 0 20 20" fill="currentColor">
        <path
          fillRule="evenodd"
          d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
          clipRule="evenodd"
        />
      </svg>
    </a>

    <p className="rounded border border-gray-100 bg-white text-sm font-medium text-gray-900 flex items-center justify-center w-8">
      {currentPage}
    </p>

    <a
      href="#"
      onClick={() => goToPage(currentPage + 1)}
      className="inline-flex size-8 items-center justify-center rounded border border-gray-100 bg-white text-gray-900"
      disabled={currentPage === totalPages}
    >
      <span className="sr-only">Next Page</span>
      <svg xmlns="http://www.w3.org/2000/svg" className="size-3" viewBox="0 0 20 20" fill="currentColor">
        <path
          fillRule="evenodd"
          d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
          clipRule="evenodd"
        />
      </svg>
    </a>
  </div>

  <button
    onClick={handleDownloadCSV}
    className="bg-green-500 text-white hover:bg-green-600 px-4 py-2 rounded-md"
  >
    Export Data as CSV
  </button>
</div>


    </div>
  );
}

export default AdminNewsLetter;
