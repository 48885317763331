import Depression from "../../../components/WebsiteComponents/ServicesComponent/Depression";
import DepressionDetails from "../../../components/WebsiteComponents/ServicesComponent/DepressionDetails";

export const DepressionPage = () => {
    return (
        <> 
    <Depression/>
    <DepressionDetails/>
        </>
    );
}
export default DepressionPage;