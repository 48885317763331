import React, { useState } from 'react'
import Logo from '../../../Assets/Logo.png';
import loginbg from '../../../Assets/anxiety.jpg';
import axios from 'axios'
import {useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2';
function Adminlogin() {

  const [values,setValues] = useState({
    AdminUsername:'',
    AdminEmail:'',
    AdminPassword:''
})

const navigate = useNavigate()
axios.defaults.withCredentials = true;
const handleSubmit = (event) => {
  event.preventDefault();
  axios.post('https://phoebe-compass-point-560accfa31d6.herokuapp.com/Login', values, {
    withCredentials: true // Ensures the browser includes cookies with this request
  })
  .then(res => {
      if (res.data.Status === "Success") {
          // Alert for successful login
          Swal.fire({
              title: 'Success!',
              text: 'Login successful!',
              icon: 'success',
              confirmButtonText: 'OK'
          }).then(() => {
              navigate('/AdminDashboard'); // Navigate after closing the alert
          });
      } else {
          // Alert for error
          Swal.fire({
              title: 'Error!',
              text: res.data.Error,
              icon: 'error',
              confirmButtonText: 'OK'
          });
      }
  })
  .catch(err => console.log(err)); // Handle any Axios errors
   // Handle any Axios errors
};













  return (
    <section className="bg-white min-h-screen">
      <div className="grid grid-cols-1 lg:grid-cols-2 min-h-screen">
        <div className="flex items-center justify-center px-4 py-10 bg-white sm:px-6 lg:px-8 sm:py-16 lg:py-4 min-h-screen">
          <div className='xl:w-full xl:max-w-sm 2xl:max-w-md xl:mx-auto'>
            <div className='flex items-center justify-center'>
            <a href="https://phoebecompasspoint.com/">
              <img
                alt="Phobe Compass Point"
                src={Logo}
                className="h-16 border-2 border-gray-200 w-auto ml-2 bg-white rounded-lg p-2"
              />
              </a>
            </div>
            <h2 className="text-2xl text-center font-bold leading-tight text-black lg:text-3xl font-montserrat">Welcome Admin</h2>
            <p className="mt-2 text-center text-base text-gray-600 font-montserrat">
              Manage Website Details by Logging into Your Account
            </p>

            <form onSubmit={handleSubmit} className="mt-8">
              <div className="space-y-5">
                <div>
                  <label htmlFor='AdminUsername' className="text-base font-montserrat font-medium text-gray-900">Username</label>
                  <div className="mt-2.5 relative text-gray-400 focus-within:text-gray-600">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                      <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z" />
                      </svg>
                    </div>
                    <input
                      type="text"
                      name="AdminUsername"
                      placeholder="Enter your username"
                      className="block font-montserrat w-full py-4 pl-10 pr-4 text-black placeholder-gray-500 transition-all duration-200 border border-gray-200 rounded-md bg-gray-50 focus:outline-none focus:border-blue-600 focus:bg-white caret-blue-600"
                      required
                      onChange={e => setValues({...values, AdminUsername: e.target.value})}
                    />
                  </div>
                </div>
                <div>
                  <label htmlFor='AdminEmail' className="text-base font-montserrat font-medium text-gray-900">Email</label>
                  <div className="mt-2.5 relative text-gray-400 focus-within:text-gray-600">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                      <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207" />
                      </svg>
                    </div>
                    <input
                      type="email"
                        name='AdminEmail'
                      className="block font-montserrat w-full py-4 pl-10 pr-4 text-black placeholder-gray-500 transition-all duration-200 border border-gray-200 rounded-md bg-gray-50 focus:outline-none focus:border-blue-600 focus:bg-white caret-blue-600"
                      required
                      placeholder='Enter Email'
                      onChange={e => setValues({...values, AdminEmail: e.target.value})}
                    />
                  </div>
                </div>

                <div>
                  <div className="flex items-center justify-between">
                    <label htmlFor='AdminPassword' className="text-base font-montserrat font-medium text-gray-900">Password</label>
                    
                  </div>
                  <div className="mt-2.5 relative text-gray-400 focus-within:text-gray-600">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                      <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 11c0 3.517-1.009 6.799-2.753 9.571m-3.44-2.04l.054-.09A13.916 13.916 0 008 11a4 4 0 118 0c0 1.017-.07 2.019-.203 3m-2.118 6.844c.875.47 1.87.726 2.926.68A9.956 9.956 0 0020 11a9.956 9.956 0 00-3.394-7.48m-2.926.68A7.5 7.5 0 014.5 11c0 1.071.224 2.09.626 3.027l.054.09m0 0L12 21.5l-6.82-7.383z" />
                      </svg>
                    </div>
                    <input
                      type="password"
                      name='AdminPassword'
                      placeholder="Enter your password"
                      className="block w-full py-4 font-montserrat pl-10 pr-4 text-black placeholder-gray-500 transition-all duration-200 border border-gray-200 rounded-md bg-gray-50 focus:outline-none focus:border-blue-600 focus:bg-white caret-blue-600"
                      required
                      onChange={e => setValues({...values, AdminPassword: e.target.value})}
                    />
                  </div>
                </div>

                <div className='mt-2'>
                  <button type='submit' className="group relative inline- w-full text-sm font-medium text-white focus:outline-none focus:ring">
                    <span className="absolute inset-0 border border-blue-500 group-active:border-blue-500"></span>
                    <span className="flex border font-montserrat items-center justify-center border-blue-500 bg-blue-500 px-8 py-3 cursor-pointer transition-transform active:blue-500 group-hover:-translate-x-1 group-hover:-translate-y-1">
                      Login Admin
                    </span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div className="relative flex items-end px-4 pb-10 pt-60 sm:pb-16 md:justify-center lg:pb-24 bg-gray-50 sm:px-6 lg:px-8 min-h-screen">
          <div className="absolute inset-0">
            <div className="absolute inset-0 bg-black opacity-60"></div>
            <img className="object-cover object-top w-full h-full" src={loginbg} alt="" />
          </div>
          <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent"></div>

          {/* Text Overlay */}
          <div className="relative ">
            <div className="w-full max-w-xl xl:w-full xl:mx-auto xl:pr-24 xl:max-w-xl">
              <h3 className="text-4xl font-bold text-white">Supporting Your Mental Health Journey</h3>
              <p className="mt-4 text-lg text-white">Join a community dedicated to understanding and overcoming challenges together.</p>
              <ul className="grid grid-cols-1 mt-10 sm:grid-cols-2 gap-x-8 gap-y-4">
                <li className="flex items-center space-x-3">
                  <div className="inline-flex items-center justify-center flex-shrink-0 w-5 h-5 bg-blue-500 rounded-full">
                    <svg className="w-3.5 h-3.5 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 111.414-1.414L9 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                    </svg>
                  </div>
                  <span className="text-base font-medium text-white">Find Resources & Support</span>
                </li>
                <li className="flex items-center space-x-3">
                  <div className="inline-flex items-center justify-center flex-shrink-0 w-5 h-5 bg-blue-500 rounded-full">
                    <svg className="w-3.5 h-3.5 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 111.414-1.414L9 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                    </svg>
                  </div>
                  <span className="text-base font-medium text-white">Connect with Professionals</span>
                </li>
                <li className="flex items-center space-x-3">
                  <div className="inline-flex items-center justify-center flex-shrink-0 w-5 h-5 bg-blue-500 rounded-full">
                    <svg className="w-3.5 h-3.5 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 111.414-1.414L9 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                    </svg>
                  </div>
                  <span className="text-base font-medium text-white">Share Your Experiences</span>
                </li>
                <li className="flex items-center space-x-3">
                  <div className="inline-flex items-center justify-center flex-shrink-0 w-5 h-5 bg-blue-500 rounded-full">
                    <svg className="w-3.5 h-3.5 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 111.414-1.414L9 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                    </svg>
                  </div>
                  <span className="text-base font-medium text-white">Empower Yourself</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Adminlogin;
