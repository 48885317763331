import Aboutus from "../../components/WebsiteComponents/Aboutus";
import FAQ from "../../components/WebsiteComponents/FAQ";

import Howwork from '../../components/WebsiteComponents/Howwork'
import InitialAppointment from "../../components/WebsiteComponents/InitialAppointment";
import Testimonials from "../../components/WebsiteComponents/Testimonials";
export const About = () => {
    return (
        <> 
<Aboutus/>
<Howwork/>
<FAQ/>
<Testimonials/>
<InitialAppointment/>
    </>
       
    );
}