import React from 'react';
import { useNavigate } from 'react-router-dom';
import Behavior from '../../../Assets/Behavior.jpg'
import { motion } from 'framer-motion';
import { fadeIn } from '../../../Variants';

function BehavioralIssues() {
    const navigate = useNavigate();

    const handleNavigation = (path) => {
      navigate(path);
    };
  return (
    <section className="relative py-10 overflow-hidden bg-black sm:py-16 lg:py-12 xl:py-24">
    <div className="absolute inset-0">
      <img
        className="object-cover w-full h-full md:object-left md:scale-150 md:origin-top-left"
        src={Behavior}
        alt=""
      />
    </div>

    <div className="absolute inset-0 hidden bg-gradient-to-r md:block from-black to-transparent"></div>

    <div className="absolute inset-0 block bg-black/60 md:hidden"></div>

    <div className="relative px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
      <div className="text-center md:w-2/3 lg:w-3/4 xl:w-1/3 md:text-left">
      <h2 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold leading-snug md:leading-tight text-white font-serif">
        Behavioral Issues, Symptoms, & Strategies
        </h2>
        <p className="mt-4 text-sm sm:text-base md:text-lg lg:text-lg font-montserrat text-gray-200 font-Montserrat leading-relaxed sm:leading-normal">
        At Phoebe Compass Point, we offer personalized strategies to address behavioral issues and help individuals develop healthier behaviors. We also provide a <span className="underline"> Free Initial Consultation</span> for adults 18 and older, to improve overall well-being.
        </p>

        <div className="grid grid-cols-1 justify-center items-center px-10 mt-4 text-center gap-x-18 gap-y-6 sm:grid-cols-2 md:grid-cols-3 sm:px-0">
  {/* Feature list */}
  <motion.div
    variants={fadeIn('zoom', 0.2)}
    initial="hidden"
    whileInView="show"
    viewport={{ once: false, amount: 0.7 }}
    className="flex flex-col items-center justify-center"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      className="h-10 w-10 sm:h-12 sm:w-12 md:h-8 md:w-8 text-gray-200 mb-2"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z"
      />
    </svg>
    <p className="text-xs sm:text-xs md:text-xs font-semibold text-gray-200 font-montserrat">
      Improved Mental Health
    </p>
  </motion.div>

  <motion.div
    variants={fadeIn('zoom', 0.4)}
    initial="hidden"
    whileInView="show"
    viewport={{ once: false, amount: 0.7 }}
    className="flex flex-col items-center justify-center"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      className="h-10 w-10 sm:h-12 sm:w-12 md:h-8 md:w-8 text-gray-200 mb-2"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M9 12.75L11.25 15 15 9.75m-3-7.036A11.959 11.959 0 0 1 3.598 6 11.99 11.99 0 0 0 3 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285Z"
      />
    </svg>
    <p className="text-xs sm:text-xs md:text-xs font-semibold text-gray-200 font-montserrat">
      Mental Health Checkups
    </p>
  </motion.div>

  <motion.div
    variants={fadeIn('zoom', 0.6)}
    initial="hidden"
    whileInView="show"
    viewport={{ once: false, amount: 0.7 }}
    className="flex flex-col items-center justify-center"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      className="h-10 w-10 sm:h-12 sm:w-12 md:h-8 md:w-8 text-gray-200 mb-2"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M10.05 4.575a1.575 1.575 0 1 0-3.15 0v3m3.15-3v-1.5a1.575 1.575 0 0 1 3.15 0v1.5m-3.15 0 .075 5.925m3.075.75V4.575m0 0a1.575 1.575 0 0 1 3.15 0V15M6.9 7.575a1.575 1.575 0 1 0-3.15 0v8.175a6.75 6.75 0 0 0 6.75 6.75h2.018a5.25 5.25 0 0 0 3.712-1.538l1.732-1.732a5.25 5.25 0 0 0 1.538-3.712l.003-2.024a.668.668 0 0 1 .198-.471 1.575 1.575 0 1 0-2.228-2.228 3.818 3.818 0 0 0-1.12 2.687M6.9 7.575V12m6.27 4.318A4.49 4.49 0 0 1 16.35 15m.002 0h-.002"
      />
    </svg>
    <p className="text-xs sm:text-xs md:text-xs font-semibold text-gray-200 font-montserrat">
      Secure Online Support
    </p>
  </motion.div>
</div>





        <div className="flex flex-col mt-6 sm:flex-row justify-center lg:justify-start items-center gap-4">
{/* Book an Appointment Button */}
<button
  className="btn w-full sm:w-auto border-none bg-green-600 text-white rounded hover:bg-green-700  font-montserrat flex items-center justify-center sm:justify-start"
  onClick={() => handleNavigation('/Contact')}
>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth="1.5"
    stroke="currentColor"
    className="h-5 w-5 mr-1"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"
    />
  </svg>
  Book Virtual Appointment
</button>


</div>
      </div>
    </div>
  </section>
  )
}

export default BehavioralIssues