import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AnxietyDetails from '../../../Assets/Anxietydetails.jpg';

function Anxietydetails() {

    const navigate = useNavigate();
    const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  
    const handleNavigation = (path) => {
      navigate(path);
    };
  
    const handleVideoPlay = () => {
      setIsVideoPlaying(true);
    };

  return (
    <section class="py-10 bg-white sm:py-16 lg:py-24">
    <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
      <div class="grid items-center grid-cols-1 lg:grid-cols-2 gap-x-12 xl:gap-x-24 gap-y-12">
        <div class="relative lg:mb-12">
          <img
            class="absolute -right-0 -bottom-8 xl:-bottom-12 xl:-right-4"
            src="https://cdn.rareblocks.xyz/collection/celebration/images/content/3/dots-pattern.svg"
            alt=""
          />
          <div class="pl-12 pr-6">
            <img class="relative rounded-md" src={AnxietyDetails} alt="Anxiety Details" />
          </div>
        </div>

        <div class="2xl:pl-16">
          <h2 class="text-xl font-bold text-black sm:text-2xl lg:text-3xl font-serif">
            What Is Anxiety?
          </h2>
          <p class="text-md font-normal text-gray-600 font-montserrat mt-4">
            Anxiety is a natural response to stress and can manifest as feelings of worry, nervousness, or unease. It becomes problematic when these feelings are persistent, excessive, and affect daily life.
          </p>
          <p class="mt-6 text-md font-normal text-gray-600 font-montserrat">
            While occasional anxiety is normal, chronic anxiety may indicate an anxiety disorder, which requires understanding, proper management, and sometimes professional help.
          </p>
        </div>
      </div>
    </div>

    <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {/* Left Column */}
        <div className="bg-white p-6 rounded-lg">
          <h2 className="text-2xl font-bold mb-4 font-serif">Why Understanding Anxiety Is Important?</h2>
          <p className="text-md font-normal text-gray-600 font-montserrat mb-6">
            Recognizing anxiety symptoms and understanding their impact can help individuals manage stress better, seek appropriate support, and improve overall well-being.
          </p>

          <h2 className="text-2xl font-bold mb-4 font-serif">Common Symptoms of Anxiety:</h2>
          <ul className="list-disc list-inside text-md font-normal text-gray-600 font-montserrat space-y-2">
            <li>Persistent worry or fear</li>
            <li>Increased heart rate and rapid breathing</li>
            <li>Restlessness or feeling tense</li>
            <li>Trouble concentrating or focusing</li>
            <li>Physical symptoms like fatigue, headaches, or stomach issues</li>
            <li>Difficulty sleeping or staying asleep</li>
          </ul>

          <h2 className="text-2xl mt-4 font-bold mb-4 font-serif">Causes of Anxiety:</h2>
          <p className="text-md font-normal text-gray-600 font-montserrat mb-6">
            Anxiety can be triggered by various factors, including:
          </p>
          <ul className="list-disc list-inside text-md font-normal text-gray-600 font-montserrat space-y-2">
            <li>Stress from work, school, or personal relationships</li>
            <li>Traumatic experiences or past events</li>
            <li>Health concerns or medical conditions</li>
            <li>Genetic predisposition to anxiety disorders</li>
            <li>Environmental factors or major life changes</li>
          </ul>

          <div className="mt-6">
          <h2 className="text-2xl font-bold mb-4 font-serif">Watch this video to learn more:</h2>

          {/* Show the thumbnail before the video is played */}
          {!isVideoPlaying ? (
            <div
              className="relative cursor-pointer"
              style={{ paddingBottom: '56.25%', height: 0, overflow: 'hidden', maxWidth: '100%', position: 'relative' }}
              onClick={handleVideoPlay}
            >
              {/* YouTube video thumbnail */}
              <img
                className="absolute top-0 left-0 w-full h-full object-cover rounded-lg"
                src="https://img.youtube.com/vi/-ieJU5gklO4/maxresdefault.jpg"
                alt="Video Thumbnail"
              />
              {/* Play button overlay */}
              <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="white"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="h-16 w-16 opacity-75"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M5.25 5.25l13.5 6.75-13.5 6.75V5.25z"
                  />
                </svg>
              </div>
            </div>
          ) : (
            // Show the iframe once the user clicks to play the video
            <div className="relative" style={{ paddingBottom: '56.25%', height: 0, overflow: 'hidden', maxWidth: '100%', position: 'relative' }}>
              <iframe
                className="absolute top-0 left-0 w-full h-full"
                src="https://www.youtube.com/embed/-ieJU5gklO4?autoplay=1"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
              ></iframe>
            </div>
          )}
        </div>
        </div>
        {/* Right Column */}
        <div className="bg-white p-6 rounded-lg">
          <h2 className="text-2xl font-bold mb-4 font-serif">Techniques for Managing Anxiety:</h2>
          <ul className="list-disc list-inside text-md font-normal text-gray-600 font-montserrat space-y-2 mb-6">
            <li>
              <strong>Breathing Exercises:</strong> Practice deep breathing to calm the mind and body.
            </li>
            <li>
              <strong>Mindfulness:</strong> Focus on the present moment to reduce feelings of anxiety.
            </li>
            <li>
              <strong>Regular Exercise:</strong> Engage in physical activity to relieve stress and improve mood.
            </li>
            <li>
              <strong>Healthy Diet:</strong> Maintain a balanced diet to support mental health.
            </li>
            <li>
              <strong>Relaxation Techniques:</strong> Try yoga, meditation, or other calming practices.
            </li>
          </ul>

          <h2 className="text-2xl font-bold mb-4 font-serif">Therapies for Anxiety:</h2>
          <ul className="list-disc list-inside text-md font-normal text-gray-600 font-montserrat space-y-2">
            <li>
              <strong>Cognitive Behavioral Therapy (CBT):</strong> Focuses on changing negative thought patterns.
            </li>
            <li>
              <strong>Exposure Therapy:</strong> Gradually confronting fears to reduce anxiety.
            </li>
            <li>
              <strong>Medication:</strong> Antidepressants or anti-anxiety medications prescribed by a doctor.
            </li>
            <li>
              <strong>Group Therapy:</strong> Support from others with similar experiences.
            </li>
          </ul>

          <h2 className="text-2xl mt-4 font-bold mb-4 font-serif">When Anxiety Becomes a Concern</h2>
          <p className="text-md font-normal text-gray-600 font-montserrat mb-6">
          Seek professional help if anxiety:
          </p>
          <ul className="list-disc list-inside text-md font-normal text-gray-600 font-montserrat space-y-2">
            <li>Is interfering with work, school, or personal relationships.</li>
            <li>Causes physical symptoms that are unmanageable.</li>
            <li>Leads to substance abuse or other harmful coping mechanisms</li>
            <li>Makes daily tasks feel overwhelming or unmanageable.</li>
          </ul>


          <h2 className="text-2xl font-bold mt-6 font-serif">When to Seek Professional Help?</h2>
          <p className="text-md font-normal text-gray-600 font-montserrat">
            If anxiety significantly impacts daily life, causes distress, or leads to unhealthy coping mechanisms, consider consulting a mental health professional for guidance and support.
          </p>

          <div className="flex flex-col mt-4 sm:flex-row justify-center lg:justify-start items-center gap-4">
            {/* Book an Appointment Button */}
            <button
    className="btn w-full sm:w-auto border-none bg-green-600 text-white rounded hover:bg-green-700  font-montserrat flex items-center justify-center sm:justify-start"
    onClick={() => handleNavigation('/Contact')}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      className="h-5 w-5 mr-1"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"
      />
    </svg>
    Book Virtual Appointment
  </button>

          </div>
        </div>
      </div>
    </div>
  </section>
  )
}

export default Anxietydetails