import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import adhdDetails from '../../../Assets/AdhdDetails.jpg';

function ADHDDetails() {
    const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
  };

  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  const handleVideoPlay = () => {
    setIsVideoPlaying(true);
  };
  return (
    <section className="py-10 bg-white sm:py-16 lg:py-24">
    <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
      <div className="grid items-center grid-cols-1 lg:grid-cols-2 gap-x-12 xl:gap-x-24 gap-y-12">
        <div className="relative lg:mb-12">
          <img
            className="absolute -right-0 -bottom-8 xl:-bottom-12 xl:-right-4"
            src="https://cdn.rareblocks.xyz/collection/celebration/images/content/3/dots-pattern.svg"
            alt=""
          />
          <div className="pl-12 pr-6">
            <img className="relative rounded-md" src={adhdDetails} alt="" />
          </div>
        </div>

        <div className="2xl:pl-16">
          <h2 className="text-xl font-bold text-black sm:text-2xl lg:text-3xl font-serif">What Is ADHD?</h2>
          <p className="text-md font-normal text-gray-600 font-montserrat mt-4">
            ADHD (Attention Deficit Hyperactivity Disorder) is a neurodevelopmental disorder characterized by persistent patterns of inattention, hyperactivity, and impulsivity that interfere with functioning or development.
          </p>
          <p className="mt-6 text-md font-normal text-gray-600 font-montserrat">
            It affects approximately 5% of children and often continues into adulthood, with estimates suggesting 2.5% of adults are affected.
          </p>
        </div>
      </div>
    </div>

    <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {/* Left Column */}
        <div className="bg-white p-6 rounded-lg">
          <h2 className="text-2xl font-bold mb-4 font-serif">Types of ADHD:</h2>
          <ul className="list-disc list-inside text-md font-normal text-gray-600 font-montserrat space-y-2 mb-6">
            <li><strong>Predominantly Inattentive Presentation:</strong> Symptoms include difficulty sustaining attention, following through on tasks, and disorganization.</li>
            <li><strong>Predominantly Hyperactive-Impulsive Presentation:</strong> Symptoms include fidgeting, difficulty remaining seated, and impulsivity in decision-making.</li>
            <li><strong>Combined Presentation:</strong> A mix of inattentive and hyperactive-impulsive symptoms.</li>
          </ul>

          <h2 className="text-2xl font-bold mb-4 font-serif">Causes and Risk Factors:</h2>
          <ul className="list-disc list-inside text-md font-normal text-gray-600 font-montserrat space-y-2 mb-6">
            <li><strong>Genetic Factors:</strong> Family history increases risk; specific genes related to dopamine regulation may be involved.</li>
            <li><strong>Environmental Factors:</strong> Prenatal exposure to tobacco smoke, alcohol, and toxins can contribute.</li>
            <li><strong>Neurological Factors:</strong> Differences in brain structure and function, particularly in areas related to attention.</li>
          </ul>

          <h2 className="text-2xl font-bold mb-4 font-serif">Symptoms of ADHD:</h2>
          <ul className="list-disc list-inside text-md font-normal text-gray-600 font-montserrat space-y-2 mb-6">
            <li>Difficulties in sustaining attention and organizing tasks.</li>
            <li>Frequent fidgeting or restlessness.</li>
            <li>Impulsivity in decision-making and interrupting others.</li>
          </ul>

          <h2 className="text-2xl mt-2 font-bold mb-4 font-serif">Coping Strategies:</h2>
<ul className="list-disc list-inside text-md font-normal text-gray-600 font-montserrat space-y-2 mb-6">
  <li><strong>Organizational Tools:</strong> Use planners and reminders to manage tasks.</li>
  <li><strong>Routine Establishment:</strong> Create consistent daily routines to help manage time.</li>
  <li><strong>Physical Activity:</strong> Regular exercise can help with focus and reduce hyperactivity.</li>
</ul>




          <div className="mt-6">
              <h2 className="text-2xl font-bold mb-4 font-serif">Watch this video to learn more:</h2>

              {/* Show the thumbnail before the video is played */}
              {!isVideoPlaying ? (
                <div
                  className="relative cursor-pointer"
                  style={{ paddingBottom: '56.25%', height: 0, overflow: 'hidden', maxWidth: '100%', position: 'relative' }}
                  onClick={handleVideoPlay}
                >
                  {/* YouTube video thumbnail */}
                  <img
                    className="absolute top-0 left-0 w-full h-full object-cover rounded-lg"
                    src="https://img.youtube.com/vi/ToN-y8CNl-Q/maxresdefault.jpg"
                    alt="Video Thumbnail"
                  />
                  {/* Play button overlay */}
                  <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="white"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      className="h-16 w-16 opacity-75"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5.25 5.25l13.5 6.75-13.5 6.75V5.25z"
                      />
                    </svg>
                  </div>
                </div>
              ) : (
                // Show the iframe once the user clicks to play the video
                <div className="relative" style={{ paddingBottom: '56.25%', height: 0, overflow: 'hidden', maxWidth: '100%', position: 'relative' }}>
                  <iframe
                    className="absolute top-0 left-0 w-full h-full"
                    src="https://www.youtube.com/embed/ToN-y8CNl-Q?autoplay=1"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                  ></iframe>
                </div>
              )}
            </div>
        </div>

        {/* Right Column */}
        <div className="bg-white p-6 rounded-lg">
          <h2 className="text-2xl font-bold mb-4 font-serif">Treatment Options:</h2>
          <ul className="list-disc list-inside text-md font-normal text-gray-600 font-montserrat space-y-2 mb-6">
            <li><strong>Medical Treatment:</strong>  For some individuals, medical treatments are part of managing ADHD. These treatments are provided under the supervision of healthcare professionals and may include options to help manage symptoms effectively.</li>
            <li><strong>Behavioral Therapy:</strong> Techniques to manage symptoms through structured routines, parent training, and cognitive behavioral therapy (CBT), helping individuals improve focus and reduce impulsivity.</li>
            <li><strong>Psychoeducation:</strong> Educating individuals and families about ADHD to improve understanding and management, empowering them with strategies to cope with challenges.</li>
          </ul>

          <h2 className="text-2xl font-bold mb-4 font-serif">Long-term Outlook:</h2>
          <p className="text-md font-normal text-gray-600 font-montserrat mb-6">
            ADHD can persist into adulthood, leading to challenges in relationships and employment. However, many individuals develop effective coping strategies.
          </p>

  

          <h2 className="text-2xl font-bold mt-6 font-serif">Frequently Asked Questions (FAQs):</h2>
<ul className="list-disc list-inside text-md font-normal text-gray-600 font-montserrat space-y-2 mb-6">
  <li><strong>Q: Can adults have ADHD?</strong><br />
  A: Yes, many adults have ADHD, and it can manifest differently than in children.</li>
  
  <li><strong>Q: Is ADHD hereditary?</strong><br />
  A: Yes, genetics play a significant role, and it often runs in families.</li>
  
  <li><strong>Q: Can ADHD be outgrown?</strong><br />
  A: While some children may see a decrease in symptoms, many continue to experience them into adulthood.</li>
  
  <li><strong>Q: What should I do if I suspect I have ADHD?</strong><br />
  A: Consult a healthcare professional for an assessment and guidance on next steps. A tailored treatment plan may include behavioral strategies and professional support.</li>
</ul>


          <h2 className="text-2xl font-bold mt-6 font-serif">When to Seek Professional Help:</h2>
          <p className="text-md font-normal text-gray-600 font-montserrat">
          If ADHD symptoms are significantly impacting daily life, consulting a mental health professional is recommended for tailored strategies and support. A healthcare provider can guide you through managing the condition through both behavioral and medical approaches based on individual needs.
          </p>

          <div className="flex flex-col mt-4 sm:flex-row justify-center lg:justify-start items-center gap-4">
  {/* Book an Appointment Button */}
  <button
    className="btn w-full sm:w-auto border-none bg-green-600 text-white rounded hover:bg-green-700  font-montserrat flex items-center justify-center sm:justify-start"
    onClick={() => handleNavigation('/Contact')}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      className="h-5 w-5 mr-1"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"
      />
    </svg>
    Book Virtual Appointment
  </button>
</div>

        </div>
      </div>
    </div>
  </section>
  )
}

export default ADHDDetails