import MedicationManagement from "../../../components/WebsiteComponents/ServicesComponent/MedicationManagement";
import MedicationManagementDetails from "../../../components/WebsiteComponents/ServicesComponent/MedicationManagementDetails";

export const MedicationManagementPage = () => {
    return (
        <> 
    <MedicationManagement/>
    <MedicationManagementDetails/>
        </>
    );
}
export default MedicationManagementPage;