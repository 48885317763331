import React from 'react'
import {motion} from 'framer-motion';
import {fadeIn} from '../../Variants'
import aboutimg from '../../Assets/aboutimg.png'
import { useNavigate} from 'react-router-dom';
import Verified1 from '../../Assets/Verified1.png'
import edr1 from '../../Assets/endor1.png'
import edr2 from '../../Assets/Endor2.jpeg'
import edr3 from '../../Assets/endor3.jpeg'
function Howwork() {

  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
  };


  return (
    <section class="py-8 bg-white sm:py-16 lg:py-16">
    <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
    <div class="max-w-3xl mx-auto">
    <motion.h2 
        variants={fadeIn("down",0.2)}
        initial="hidden"
        whileInView={"show"}
        viewport={{once: false, amount:0.7}}
        class="text-xl font-bold  leading-tight text-black sm:text-2xl lg:text-5xl font-serif text-center">
        At Phoebe Compass Point Your Mental Health Matters
    </motion.h2>
    <motion.p
        variants={fadeIn("zoom",0.2)}
        initial="hidden"
        whileInView={"show"}
        viewport={{once: false, amount:0.7}}
        class="mt-2 text-md font-normal text-gray-600 font-montserrat text-center"> 
        Our practice is led by Febbie, a board-certified Psychiatric Nurse Practitioner with extensive experience in mental health care. She specializes in developing tailored treatment plans that cater to the unique needs of each individual, ensuring a personalized approach to mental wellness. Febbie is dedicated to fostering a supportive environment where clients feel heard, valued, and empowered to take charge of their mental health journey.
    </motion.p>
</div>

<div class="flex justify-center mt-4 md:mt-8">
    <div class="grid grid-cols-1 md:grid-cols-2 gap-y-2 gap-x-2 items-center"> 
    <div class="flex flex-col items-center">
    <img class="w-full mx-auto sm:max-w-sm rounded-xl" src={aboutimg} alt="" />
    <div class="w-full  text-gray-700 flex flex-col items-center justify-center p-2 rounded-xl mt-2 sm:max-w-sm">
    <a href="https://www.psychologytoday.com/profile/1326276" target="_blank" rel="noopener noreferrer" class="flex items-center justify-center">
      <img 
        src={Verified1} 
        alt="Verified"  
        className="w-[65%] h-[65%] object-contain shadow-lg rounded-full  " 
        title="Phoebe compass point"
      />
    </a>
  <div class="mt-2 text-center">
    <p class="text-sm font-semibold font-montserrat">Verified by Psychology Today</p>
    <p class="text-xs font-montserrat">License: Washington / AP 61551357</p>
    <p class="text-xs font-montserrat">Febbie Mutindori</p>
  </div>
</div>



</div>


        <div class="mt-2"> 
            <h3 class="text-md font-semibold text-gray-600 font-montserrat">
                Hey! I am Febbie Mutindori, Psychiatric Nurse Practitioner, PMHNP
            </h3>
            <p class="mt-4 text-md font-normal text-gray-600 font-montserrat">
                I am a board-certified Psychiatric Mental Health Nurse Practitioner (PMHNP-BC), passionate about helping individuals achieve emotional well-being. I hold a Master's degree in Nursing, specializing in Psychiatric Mental Health.
            </p>
            <p class="mt-4 text-md font-normal text-gray-600 font-montserrat">
                I believe in a collaborative and patient-centered approach to mental health. I work with you to develop a personalized treatment plan that addresses your unique needs and goals. I utilize evidence-based practices to manage mental health disorders like Depression and Anxiety disorders, Bipolar, Schizophrenia, GAD, Social Anxiety Disorder, Panic Disorder, etc.
            </p>
            <h3 class="mt-8 text-md font-semibold text-gray-600 font-montserrat">
                A Detailed Overview of What I Provide
            </h3>
            <p class="mt-4 text-md font-normal text-gray-600 font-montserrat">
                I offer medication management services, working collaboratively with your primary care physician if needed. I also provide telehealth appointments for your convenience and comfort. I am currently accepting new adult patients who are struggling with mental health concerns and seeking a supportive and collaborative approach to treatment.
            </p>
            
            <div class="flex flex-col lg:flex-row justify-center lg:justify-start items-center mt-2">
    <button
        class="btn bg-green-600 text-white rounded hover:bg-green-700 h-12 mb-2 lg:mb-0 lg:mr-4 w-full lg:w-auto font-montserrat"
        onClick={() => handleNavigation('/Contact')}
    >
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            class="h-5 w-5 mr-2"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"
            />
        </svg>
        Book Virtual Appointment
    </button>

 
</div>
        </div>
    </div>
</div>

<div class="px-4 mt-12 mx-auto max-w-7xl sm:px-6 lg:px-8">
<h2 class="text-xl font-bold leading-tight text-black sm:text-2xl lg:text-3xl font-serif text-left">Treatment Approaches</h2>
        <div className="mt-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 text-gray-700  r">
        <ul className="list-disc pl-5 space-y-1 font-montserrat">
          <li>Acceptance and Commitment (ACT)</li>
          <li>Motivational Interviewing</li>
        </ul>
        <ul className="list-disc pl-5 space-y-1 font-montserrat">
          <li>Cognitive Behavioral (CBT)</li>
          <li>Strength-Based</li>
        </ul>
        <ul className="list-disc pl-5 space-y-1 font-montserrat">
          <li>Mindfulness-Based (MBCT)</li>
          <li>Trauma Focused</li>
        </ul>
      </div>
</div>


<div class="px-4 mt-12 mx-auto max-w-7xl sm:px-6 lg:px-8">
<h2 class="text-xl font-bold leading-tight text-black sm:text-2xl lg:text-3xl font-serif text-left">Endorsements</h2>
      
<div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
      
        <div class="grid max-w-xl grid-cols-1 mx-auto mt-8 text-center lg:max-w-full sm:mt-12 lg:mt-8 lg:grid-cols-3 gap-x-6 xl:gap-x-12 gap-y-6">
            <div class="overflow-hidden bg-white rounded-md shadow">
                <div class="px-8 py-12">
                    <div class="relative w-24 h-24 mx-auto">
                        <img class="relative object-cover w-24 h-24 mx-auto rounded-full" src={edr1} alt="" />
                        <div class="absolute top-0 right-0 flex items-center justify-center bg-green-600 rounded-full w-7 h-7">
                            <svg class="w-4 h-4 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                                <path
                                    d="M20.309 17.708C22.196 15.66 22.006 13.03 22 13V5a1 1 0 0 0-1-1h-6c-1.103 0-2 .897-2 2v7a1 1 0 0 0 1 1h3.078a2.89 2.89 0 0 1-.429 1.396c-.508.801-1.465 1.348-2.846 1.624l-.803.16V20h1c2.783 0 4.906-.771 6.309-2.292zm-11.007 0C11.19 15.66 10.999 13.03 10.993 13V5a1 1 0 0 0-1-1h-6c-1.103 0-2 .897-2 2v7a1 1 0 0 0 1 1h3.078a2.89 2.89 0 0 1-.429 1.396c-.508.801-1.465 1.348-2.846 1.624l-.803.16V20h1c2.783 0 4.906-.771 6.309-2.292z"
                                ></path>
                            </svg>
                        </div>
                    </div>
                    <blockquote class="mt-4">
                        <p class="text-sm font-normal text-gray-600 font-montserrat">“Febbie is an outstanding PMHNP, demonstrating exceptional clinical skills and a deep commitment to patient-centered care. Their ability to connect with patients, coupled with their expertise in mental health treatment ensures positive outcomes.”</p>
                    </blockquote>
                    <p class="text-base font-semibold text-black mt-4 font-serif">Clarity Peak Mental Health</p>
                    <p class="mt-1 text-sm text-gray-600 font-montserrat">Psychiatric Nurse Practitioner, PMHNP</p>
                </div>
            </div>

            <div class="overflow-hidden bg-white rounded-md shadow">
                <div class="px-8 py-12">
                    <div class="relative w-24 h-24 mx-auto">
                        <img class="relative object-cover w-24 h-24 mx-auto rounded-full" src={edr2} alt="" />
                        <div class="absolute top-0 right-0 flex items-center justify-center bg-green-600 rounded-full w-7 h-7">
                            <svg class="w-4 h-4 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                                <path
                                    d="M20.309 17.708C22.196 15.66 22.006 13.03 22 13V5a1 1 0 0 0-1-1h-6c-1.103 0-2 .897-2 2v7a1 1 0 0 0 1 1h3.078a2.89 2.89 0 0 1-.429 1.396c-.508.801-1.465 1.348-2.846 1.624l-.803.16V20h1c2.783 0 4.906-.771 6.309-2.292zm-11.007 0C11.19 15.66 10.999 13.03 10.993 13V5a1 1 0 0 0-1-1h-6c-1.103 0-2 .897-2 2v7a1 1 0 0 0 1 1h3.078a2.89 2.89 0 0 1-.429 1.396c-.508.801-1.465 1.348-2.846 1.624l-.803.16V20h1c2.783 0 4.906-.771 6.309-2.292z"
                                ></path>
                            </svg>
                        </div>
                    </div>
                    <blockquote class="mt-4">
                        <p class="text-sm font-normal text-gray-600 font-montserrat">“Febbie is a highly skilled and dedicated Psychiatric-Mental Health Nurse Practitioner with a profound commitment to patient care. She is compassionate and cares about her patients and their mental health.”</p>
                    </blockquote>
                    <p class="text-base font-semibold tex-black mt-4 font-serif">Lizaida Enamorado @ Kaiwell Health</p>
                    <p class="mt-1 text-sm text-gray-600 font-montserrat">Psychiatric Nurse Practitioner, AGNP-C, PMHNP-C</p>
                </div>
            </div>

            <div class="overflow-hidden bg-white rounded-md shadow">
                <div class="px-8 py-12">
                    <div class="relative w-24 h-24 mx-auto">
                        <img class="relative object-cover w-24 h-24 mx-auto rounded-full" src={edr3} alt="" />
                        <div class="absolute top-0 right-0 flex items-center justify-center bg-green-600 rounded-full w-7 h-7">
                            <svg class="w-4 h-4 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                                <path
                                    d="M20.309 17.708C22.196 15.66 22.006 13.03 22 13V5a1 1 0 0 0-1-1h-6c-1.103 0-2 .897-2 2v7a1 1 0 0 0 1 1h3.078a2.89 2.89 0 0 1-.429 1.396c-.508.801-1.465 1.348-2.846 1.624l-.803.16V20h1c2.783 0 4.906-.771 6.309-2.292zm-11.007 0C11.19 15.66 10.999 13.03 10.993 13V5a1 1 0 0 0-1-1h-6c-1.103 0-2 .897-2 2v7a1 1 0 0 0 1 1h3.078a2.89 2.89 0 0 1-.429 1.396c-.508.801-1.465 1.348-2.846 1.624l-.803.16V20h1c2.783 0 4.906-.771 6.309-2.292z"
                                ></path>
                            </svg>
                        </div>
                    </div>
                    <blockquote class="mt-4">
                        <p class="text-sm font-normal text-gray-600 font-montserrat">“I am honored to endorse Febbie M, a highly skilled and compassionate Psychiatric Nurse Practitioner. Febbie has consistently demonstrated a deep understanding of mental health, combining her extensive clinical knowledge with genuine empathy for patients.”</p>
                    </blockquote>
                    <p class="text-base font-semibold tex-black mt-4 font-serif">Nurtured Psychiatry Llc</p>
                    <p class="mt-1 text-sm text-gray-600 font-montserrat">Psychiatric Nurse Practitioner, CNP</p>
                </div>
            </div>
        </div>
    </div>
    </div>



<div class="px-4 mt-12 mx-auto max-w-7xl sm:px-6 lg:px-8">
<h2 class="text-xl font-bold leading-tight text-black sm:text-2xl lg:text-3xl font-serif text-left">Client Focus</h2>
        <div class="grid mt-8 grid-cols-1 text-center sm:grid-cols-2 gap-y-8 lg:grid-cols-4 sm:gap-12">
            <div>
                <div class="flex items-center justify-center w-20 h-20 mx-auto bg-green-100 rounded-full">
                <svg class="text-green-600 w-9 h-9"  viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <circle cx="9" cy="7" r="4" />  <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />  <path d="M16 11l2 2l4 -4" /></svg>
                </div>
                <h3 class="mt-4 text-lg font-semibold text-black font-serif">Age</h3>
                <p className='text-sm font-semibold text-gray-700 font-serif'>Adults,Elders (65+)</p>
                <p class="mt-2 text-sm font-normal text-gray-600 font-montserrat">We support adults and elders (65+), offering tailored mental health services for later life challenges</p>
            </div>

            <div>
                <div class="flex items-center justify-center w-20 h-20 mx-auto bg-green-100 rounded-full">
                <svg class="text-green-600 w-9 h-9"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2" />  <circle cx="9" cy="7" r="4" />  <path d="M23 21v-2a4 4 0 0 0-3-3.87" />  <path d="M16 3.13a4 4 0 0 1 0 7.75" /></svg>
                </div>
                <h3 class="mt-4 text-lg font-semibold text-black font-serif">Participants</h3>
                <p className='text-sm font-semibold text-gray-700 font-serif'>Individuals, Couples, Family</p>
                <p class="mt-2 text-sm font-normal text-gray-600 font-montserrat">Our services are available for individuals, couples, and families seeking personal or relational support.</p>
            </div>

            <div>
                <div class="flex items-center justify-center w-20 h-20 mx-auto bg-green-100 rounded-full">
                <svg class="text-green-600 w-9 h-9"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"/>
</svg>
                </div>
                <h3 class="mt-4 text-lg font-semibold text-black font-serif">Ethnicity</h3>
                <p className='text-sm font-semibold text-gray-700 font-serif'>Other Racial or Ethnic Background</p>
                <p class="mt-2 text-sm font-normal text-gray-600 font-montserrat">We welcome clients from diverse racial and ethnic backgrounds, providing culturally sensitive care</p>
            </div>

            <div>
                <div class="flex items-center justify-center w-20 h-20 mx-auto bg-green-100 rounded-full">
                    <svg class="text-green-600 w-9 h-9"  viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M5 7h7m-2 -2v2a5 8 0 0 1 -5 8m1 -4a7 4 0 0 0 6.7 4" />  <path d="M11 19l4 -9l4 9m-.9 -2h-6.2" /></svg>
                </div>
                <h3 class="mt-4 text-lg font-semibold text-black font-serif">I Also Speak</h3>
                <p className='text-sm font-semibold text-gray-700 font-serif'>Shona & Ndebele</p>
                <p class="mt-2 text-sm font-normal text-gray-600 font-montserrat">I can communicate in Shona and Ndebele for clients who prefer these languages, ensuring clear conversations.</p>
            </div>
        </div>
    </div>


   

    </div>
</section>

  )
}

export default Howwork