import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import RelationshipDetailss from '../../../Assets/RelationshipDetails.jpg';

function RelationshipDetails() {
    const navigate = useNavigate();

    const handleNavigation = (path) => {
      navigate(path);
    };
  
    const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  
    const handleVideoPlay = () => {
      setIsVideoPlaying(true);
    };
  return (
    <section className="py-10 bg-white sm:py-16 lg:py-24">
    <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
      <div className="grid items-center grid-cols-1 lg:grid-cols-2 gap-x-12 xl:gap-x-24 gap-y-12">
        <div className="relative lg:mb-12">
          <img
            className="absolute -right-0 -bottom-8 xl:-bottom-12 xl:-right-4"
            src="https://cdn.rareblocks.xyz/collection/celebration/images/content/3/dots-pattern.svg"
            alt=""
          />
          <div className="pl-12 pr-6">
            <img className="relative rounded-md" src={RelationshipDetailss} alt="" />
          </div>
        </div>

        <div className="2xl:pl-16">
          <h2 className="text-xl font-bold text-black sm:text-2xl lg:text-3xl font-serif">What Are Relationship Issues?</h2>
          <p className="text-md font-normal text-gray-600 font-montserrat mt-4">
            Relationship issues refer to a range of challenges that couples or individuals face in their interactions. These can include communication problems, trust issues, and conflicting needs and desires. Understanding these issues is crucial for maintaining a healthy relationship.
          </p>
          <p className="mt-6 text-md font-normal text-gray-600 font-montserrat">
            Effective resolution of relationship issues involves recognizing the underlying causes, improving communication skills, and learning to empathize with one another. This process often requires commitment and effort from both partners.
          </p>
        </div>
      </div>
    </div>

    <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {/* Left Column */}
        <div className="bg-white p-6 rounded-lg">
          <h2 className="text-2xl font-bold mb-4 font-serif">Importance of Addressing Relationship Issues</h2>
          <p className="text-md font-normal text-gray-600 font-montserrat mb-6">
            Addressing relationship issues is vital for emotional well-being and the overall health of the partnership. Ignoring these problems can lead to resentment, emotional distance, and even the breakdown of the relationship. Open communication fosters intimacy and connection.
          </p>

          <h2 className="text-2xl font-bold mb-4 font-serif">Common Symptoms of Relationship Issues:</h2>
          <ul className="list-disc list-inside text-md font-normal text-gray-600 font-montserrat space-y-2">
            <li>Poor communication or avoidance of discussions</li>
            <li>Frequent arguments or disagreements</li>
            <li>Loss of emotional or physical intimacy</li>
            <li>Feelings of disconnection or isolation</li>
            <li>Lack of trust or frequent jealousy</li>
            <li>Feelings of frustration or resentment towards each other</li>
          </ul>

          <h2 className="text-2xl mt-4 font-bold mb-4 font-serif">Causes of Relationship Issues:</h2>
          <p className="text-md font-normal text-gray-600 font-montserrat mb-6">
            Relationship issues can arise from various factors, including:
          </p>
          <ul className="list-disc list-inside text-md font-normal text-gray-600 font-montserrat space-y-2">
            <li>Stress from work or financial problems</li>
            <li>Different values or life goals</li>
            <li>Poor communication skills</li>
            <li>Past traumas or unresolved personal issues</li>
            <li>Changes in life circumstances or roles</li>
            <li>Neglecting relationship maintenance or self-care</li>
          </ul>

          <div className="mt-6">
              <h2 className="text-2xl font-bold mb-4 font-serif">Watch this video to learn more:</h2>

              {/* Show the thumbnail before the video is played */}
              {!isVideoPlaying ? (
                <div
                  className="relative cursor-pointer"
                  style={{ paddingBottom: '56.25%', height: 0, overflow: 'hidden', maxWidth: '100%', position: 'relative' }}
                  onClick={handleVideoPlay}
                >
                  {/* YouTube video thumbnail */}
                  <img
                    className="absolute top-0 left-0 w-full h-full object-cover rounded-lg"
                    src="https://img.youtube.com/vi/rgQvqi6aYD8/maxresdefault.jpg"
                    alt="Video Thumbnail"
                  />
                  {/* Play button overlay */}
                  <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="white"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      className="h-16 w-16 opacity-75"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5.25 5.25l13.5 6.75-13.5 6.75V5.25z"
                      />
                    </svg>
                  </div>
                </div>
              ) : (
                // Show the iframe once the user clicks to play the video
                <div className="relative" style={{ paddingBottom: '56.25%', height: 0, overflow: 'hidden', maxWidth: '100%', position: 'relative' }}>
                  <iframe
                    className="absolute top-0 left-0 w-full h-full"
                    src="https://www.youtube.com/embed/rgQvqi6aYD8?autoplay=1"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                  ></iframe>
                </div>
              )}
            </div>
        </div>
        {/* Right Column */}
        <div className="bg-white p-6 rounded-lg">
          <h2 className="text-2xl font-bold mb-4 font-serif">Techniques for Improving Relationships:</h2>
          <ul className="list-disc list-inside text-md font-normal text-gray-600 font-montserrat space-y-2 mb-6">
            <li>
              <strong>Open Communication:</strong> Encourage honest dialogue about feelings, needs, and expectations.
            </li>
            <li>
              <strong>Active Listening:</strong> Make an effort to listen and understand your partner’s perspective without interrupting.
            </li>
            <li>
              <strong>Conflict Resolution Skills:</strong> Learn to resolve disagreements constructively rather than destructively.
            </li>
            <li>
              <strong>Quality Time:</strong> Spend meaningful time together to strengthen emotional bonds.
            </li>
            <li>
              <strong>Empathy:</strong> Practice putting yourself in your partner's shoes to better understand their feelings and reactions.
            </li>
            <li>
              <strong>Set Boundaries:</strong> Establish healthy boundaries to ensure both partners feel respected and valued.
            </li>
          </ul>

          <h2 className="text-2xl font-bold mb-4 font-serif">Therapies for Relationship Issues:</h2>
          <ul className="list-disc list-inside text-md font-normal text-gray-600 font-montserrat space-y-2">
            <li>
              <strong>Couples Therapy:</strong> Helps partners communicate effectively and address specific issues within the relationship.
            </li>
            <li>
              <strong>Individual Counseling:</strong> Addresses personal issues that may be affecting the relationship.
            </li>
            <li>
              <strong>Communication Workshops:</strong> Provides tools and strategies for improving communication skills.
            </li>
            <li>
              <strong>Mediation:</strong> A neutral third party can help resolve conflicts and facilitate discussions.
            </li>
          </ul>

          <h2 className="text-2xl font-bold mt-6 font-serif">When to Seek Professional Help?</h2>
          <p className="text-md font-normal text-gray-600 font-montserrat">
            If relationship issues persist despite efforts to improve the situation, or if they lead to emotional distress or harmful behaviors, it is advisable to seek help from a mental health professional. Early intervention can prevent deeper issues from developing and facilitate healthier interactions.
          </p>

          <div className="flex flex-col mt-4 sm:flex-row justify-center lg:justify-start items-center gap-4">
  {/* Book an Appointment Button */}
  <button
    className="btn w-full sm:w-auto border-none bg-green-600 text-white rounded hover:bg-green-700  font-montserrat flex items-center justify-center sm:justify-start"
    onClick={() => handleNavigation('/Contact')}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      className="h-5 w-5 mr-1"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"
      />
    </svg>
    Book Virtual Appointment
  </button>
</div>




        </div>
      </div>
    </div>
  </section>
  )
}

export default RelationshipDetails