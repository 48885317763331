import React, { useState, useEffect } from 'react';
import LOGO from '../../Assets/Logo.png';
import { useNavigate, useLocation } from 'react-router-dom';

function Navbar() {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [activeLink, setActiveLink] = useState('/');

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const handleNavigation = (path) => {
    setActiveLink(path);
    navigate(path);
    if (isMobileMenuOpen) {
      setMobileMenuOpen(false);
    }
  };

  return (
    <header className="sticky top-0 z-50 pb-6 shadow-lg bg-white lg:pb-0">
            {/* Mobile Contact Us button centered */}
            <div className="bg-gray-800 w-full flex justify-center py-2 lg:hidden space-x-6">
  {/* Contact Us Button */}
  <a
    href="#"
    onClick={() => handleNavigation('/Contact')}
    className="inline-flex items-center justify-center px-4 py-3 text-base font-semibold text-white transition-all duration-200 border-transparent border-b hover:underline hover:text-[#4ADE80] hover:bg-transparent font-Montserrat"
    role="button"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      className="h-5 w-5 mr-2"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
      />
    </svg>
    Contact Us
  </a>

  {/* Phone Number */}
  <a
    href="tel:+1-360-359-6325"
    className="inline-flex items-center justify-center px-4 py-3 text-base font-semibold text-white transition-all duration-200 border-transparent border-b hover:underline hover:text-[#4ADE80] hover:bg-transparent font-Montserrat"
  >
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="h-5 w-5 mr-2">
  <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z" />
</svg>

    (360)-359-6325
  </a>
</div>

      <div className="px-4 mx-auto max-w-7xl  lg:px-8">


        <nav className="flex items-center justify-between h-16 lg:h-20">
          <div className="flex-shrink-0">
            <div title="" className="flex">
              <a href="https://phoebecompasspoint.com/">
                <img className="w-auto h-8 lg:h-16 bg-transparent" src={LOGO} alt="Logo" />
              </a>
            </div>
          </div>

          <button
            type="button"
            onClick={toggleMobileMenu}
            className="inline-flex p-2 text-black transition-all duration-200 rounded-md lg:hidden focus:bg-gray-100 hover:bg-gray-100"
          >
            {/* Hamburger icon */}
            <svg className={`w-6 h-6 ${isMobileMenuOpen ? 'hidden' : 'block'}`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 8h16M4 16h16" />
            </svg>

            {/* Close icon */}
            <svg className={`w-6 h-6 ${isMobileMenuOpen ? 'block' : 'hidden'}`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>

          <div className="hidden lg:flex lg:items-center lg:ml-auto lg:space-x-10">
            {['/', '/About', '/Services', '/Insurance'].map((path, index) => (
              <a
                key={index}
                href="#"
                onClick={() => handleNavigation(path)}
                className={`font-medium text-black transition-all duration-200 hover:text-[#4ADE80] focus:text-[#4ADE80] hover:border-b-2 focus:border-b-2 ${
                  activeLink === path ? 'text-[#4ADE80] border-b-2 border-[#4ADE80]' : 'hover:border-[#4ADE80] focus:border-[#4ADE80]'
                } font-Montserrat`}
              >
                {path === '/' ? 'Home' : path === '/Insurance' ? 'Insurance & Payment' : path.substring(1)} {/* Display name */}
              </a>
            ))}
          </div>

          <a
            href="#"
            onClick={() => handleNavigation('/Contact')}
            className="hidden items-center justify-center px-4 py-3 ml-10 text-base rounded-md font-semibold text-white transition-all duration-200 bg-[#4ADE80] border border-transparent lg:inline-flex hover:bg-[#34C759] focus:bg-[#34C759] font-Montserrat"
            role="button"
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-5 w-5 mr-2">
              <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75" />
            </svg>
            Contact Us
          </a>
        </nav>

        {isMobileMenuOpen && (
          <nav className="pt-4 pb-6 bg-white border border-gray-200 rounded-md shadow-md lg:hidden">
            <div className="flow-root">
              <div className="flex flex-col px-6 -my-2 space-y-1 text-center">
                {['/', '/About', '/Services', '/Insurance'].map((path, index) => (
                  <a
                    key={index}
                    href="#"
                    onClick={() => handleNavigation(path)}
                    className={`inline-flex font-montserrat py-2 text-base font-medium text-black transition-all duration-200 hover:text-[#4ADE80] focus:text-[#4ADE80] hover:border-b-2 focus:border-b-2 ${
                      activeLink === path ? 'text-[#4ADE80] border-b-2 border-[#4ADE80]' : 'hover:border-[#4ADE80] focus:border-[#4ADE80]'
                    }`}
                  >
                    {path === '/' ? 'Home' : path === '/Insurance' ? 'Insurance & Payment' : path.substring(1)}
                  </a>
                ))}
              </div>
            </div>
          </nav>
        )}
      </div>
    </header>
  );
}

export default Navbar;
