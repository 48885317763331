import Contactus from "../../components/WebsiteComponents/Contactus";
import JoinourNewslatter from "../../components/WebsiteComponents/JoinourNewslatter";

export const Contact = () => {
    return (
        <> 
<Contactus/>
<JoinourNewslatter/>
    </>
       
    );
}