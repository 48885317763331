import React from 'react';
import combg from '../../Assets/combg.jpg';
import comweserve from '../../Assets/comweserve.jpg'
import {motion} from 'framer-motion';
import {fadeIn} from '../../Variants'
import qoutation from '../../Assets/text.png'

function Communities() {
  return (
    <section
      className="relative py-4 sm:py-16 lg:py-24"
      style={{
        backgroundImage: `url(${combg})`, // Use the imported image here
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      {/* Dark overlay */}
      <div className="absolute inset-0 bg-black opacity-70 z-0"></div>

      {/* Content container, placed above the overlay */}
      <div className="relative z-10 px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
        <div className="grid items-center grid-cols-1 gap-y-8 lg:grid-cols-2 gap-x-16 xl:gap-x-24">
            <div className="relative mb-12">
                <img className="w-full rounded-md" src={comweserve} alt="" />

                <div className="absolute w-full max-w-xs px-4 -translate-x-1/2 sm:px-0 sm:max-w-sm left-1/2 -bottom-12">
                <img src={qoutation} alt='' className="absolute left-6 top-4 h-10 w-10"/>
                    <div className="overflow-hidden bg-white rounded">
                  
                        <div className="px-2 py-6">
                        <div className="relative flex justify-center items-start">
  <motion.p
     variants={fadeIn("zoom",0.2)}
        initial="hidden"
        whileInView={"show"}
        viewport={{once: false, amount:0.7}}
   className="pl-8 pr-4 mt-2 text-sm text-center font-medium text-gray-700 sm:text-md font-montserrat">
  Whether you're dealing with anxiety, depression, trauma, or simply navigating life's stressors, we provide personalized care to help you manage your mental health.
  </motion.p>
</div>
                        </div>
                    </div>
                </div>
            </div>

            <div>
  <motion.h2
    variants={fadeIn("down", 0.2)}
    initial="hidden"
    whileInView={"show"}
    viewport={{ once: false, amount: 0.7 }}
    className="mt-2 text-2xl font-bold leading-snug text-white sm:text-3xl md:text-4xl md:leading-tight font-serif"
  >
    Communities We Serve
  </motion.h2>
  
  <motion.p
    variants={fadeIn("zoom", 0.2)}
    initial="hidden"
    whileInView={"show"}
    viewport={{ once: false, amount: 0.7 }}
    className="mt-2 text-base leading-relaxed text-white sm:text-lg md:text-xl font-montserrat"
  >
    At Phoebe Compass Point, we offer a compassionate, inclusive space where individuals can find the support they need to face life's challenges. Let us guide you towards healing and a better understanding of each other.
  </motion.p>
</div>

        </div>
      </div>
    </section>
  );
}

export default Communities;
