import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DepressionDetailss from '../../../Assets/DepressionDetails.jpg';


function DepressionDetails() {
    const navigate = useNavigate();

    const handleNavigation = (path) => {
      navigate(path);
    };
  
    const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  
    const handleVideoPlay = () => {
      setIsVideoPlaying(true);
    };
  return (
    <section className="py-10 bg-white sm:py-16 lg:py-24">
    <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
      <div className="grid items-center grid-cols-1 lg:grid-cols-2 gap-x-12 xl:gap-x-24 gap-y-12">
        <div className="relative lg:mb-12">
          <img
            className="absolute -right-0 -bottom-8 xl:-bottom-12 xl:-right-4"
            src="https://cdn.rareblocks.xyz/collection/celebration/images/content/3/dots-pattern.svg"
            alt=""
          />
          <div className="pl-12 pr-6">
            <img className="relative rounded-md" src={DepressionDetailss} alt="" />
          </div>
        </div>

        <div className="2xl:pl-16">
          <h2 className="text-xl font-bold text-black sm:text-2xl lg:text-3xl  font-serif">What Is Depression?</h2>
          <p className="text-md font-normal text-gray-600 font-montserrat mt-4">
            Depression is a common and serious mood disorder that affects how you feel, think, and handle daily activities. It can lead to a range of emotional and physical problems, and decrease a person’s ability to function in everyday life.
          </p>
          <p className="mt-6 text-md font-normal text-gray-600 font-montserrat">
            Managing depression involves a variety of approaches, including medication, therapy, and lifestyle changes. Understanding the symptoms, causes, and impact of depression is the first step in addressing it.
          </p>
        </div>
      </div>
    </div>

    <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {/* Left Column */}
        <div className="bg-white p-6 rounded-lg">
          <h2 className="text-2xl font-bold mb-4 font-serif">Why Is Depression Management Important?</h2>
          <p className="text-md font-normal text-gray-600 font-montserrat mb-6">
            Depression can severely affect relationships, work, and health. It’s linked to other health problems like heart disease and diabetes. Managing depression improves overall well-being, quality of life, and helps prevent further mental and physical complications.
          </p>

          <h2 className="text-2xl font-bold mb-4 font-serif">Common Symptoms of Depression:</h2>
          <ul className="list-disc list-inside text-md font-normal text-gray-600 font-montserrat space-y-2">
            <li>Persistent feelings of sadness or hopelessness</li>
            <li>Loss of interest in activities once enjoyed</li>
            <li>Changes in appetite or weight</li>
            <li>Difficulty sleeping or sleeping too much</li>
            <li>Fatigue or lack of energy</li>
            <li>Difficulty concentrating, remembering, or making decisions</li>
            <li>Thoughts of death or suicide</li>
          </ul>

          <h2 className="text-2xl mt-4 font-bold mb-4 font-serif">Causes of Depression:</h2>
          <p className="text-md font-normal text-gray-600 font-montserrat mb-6">
            Depression can have various causes, including genetic, environmental, psychological, and biological factors:
          </p>
          <ul className="list-disc list-inside text-md font-normal text-gray-600 font-montserrat space-y-2">
            <li>Family history of depression</li>
            <li>Trauma, stress, or major life changes</li>
            <li>Chronic illnesses like heart disease or cancer</li>
            <li>Substance use and withdrawal</li>
            <li>Hormonal imbalances</li>
            <li>Certain medications</li>
          </ul>

          <div className="mt-6">
              <h2 className="text-2xl font-bold mb-4 font-serif">Watch this video to learn more:</h2>
              {!isVideoPlaying ? (
                <div
                  className="relative cursor-pointer"
                  style={{ paddingBottom: '56.25%', height: 0, overflow: 'hidden', maxWidth: '100%', position: 'relative' }}
                  onClick={handleVideoPlay}
                >
                  <img
                    className="absolute top-0 left-0 w-full h-full object-cover rounded-lg"
                    src="https://img.youtube.com/vi/5rcqL1YCay0/maxresdefault.jpg"
                    alt="Video Thumbnail"
                  />
                  <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="white"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      className="h-16 w-16 opacity-75"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5.25 5.25l13.5 6.75-13.5 6.75V5.25z"
                      />
                    </svg>
                  </div>
                </div>
              ) : (
                <div className="relative" style={{ paddingBottom: '56.25%', height: 0, overflow: 'hidden', maxWidth: '100%', position: 'relative' }}>
                  <iframe
                    className="absolute top-0 left-0 w-full h-full"
                    src="https://www.youtube.com/embed/5rcqL1YCay0?autoplay=1"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                  ></iframe>
                </div>
              )}
            </div>
        </div>
        
        {/* Right Column */}
        <div className="bg-white p-6 rounded-lg">
          <h2 className="text-2xl font-bold mb-4 font-serif">Managing Depression:</h2>
          <ul className="list-disc list-inside text-md font-normal text-gray-600 font-montserrat space-y-2 mb-6">
            <li><strong>Support Network:</strong> Building supportive relationships with friends, family, or groups can reduce feelings of isolation.</li>
            <li><strong>Exercise:</strong> Regular physical activity helps improve mood and energy levels.</li>
            <li><strong>Mindfulness:</strong> Practicing mindfulness or meditation can manage stress and reduce anxiety.</li>
            <li><strong>Healthy Lifestyle:</strong> Maintaining a balanced diet and proper sleep schedule is crucial for mental health.</li>
            <li><strong>Limiting Alcohol and Drugs:</strong> Reducing substance use can prevent worsening symptoms of depression.</li>
            <li><strong>Seeking Professional Help:</strong> Therapy or counseling can offer personalized strategies for managing depression.</li>
          </ul>


          <h2 className="text-2xl font-bold mb-4 font-serif">Risk Factors for Depression:</h2>
            <p className="text-md font-normal text-gray-600 font-montserrat mb-6">
              A combination of factors can increase the likelihood of developing depression:
            </p>
            <ul className="list-disc list-inside text-md font-normal text-gray-600 font-montserrat space-y-2 mb-6">
              <li><strong>Genetics:</strong> A family history of depression can increase the risk.</li>
              <li><strong>Personality:</strong> Individuals with low self-esteem, those who are easily overwhelmed by stress, or those generally pessimistic are more likely to experience depression.</li>
              <li><strong>Chronic Stress:</strong> Ongoing stressful situations such as financial problems, work stress, or abuse.</li>
              <li><strong>Trauma:</strong> Exposure to violence, neglect, or emotional, physical, or sexual abuse.</li>
              <li><strong>Substance Abuse:</strong> Drug and alcohol abuse often co-occurs with depression.</li>
              <li><strong>Medical Conditions:</strong> Chronic illnesses, including cardiovascular disease, diabetes, or chronic pain.</li>
            </ul>
          <h2 className="text-2xl font-bold mb-4 font-serif">When to Seek Professional Help?</h2>
          <p className="text-md font-normal text-gray-600 font-montserrat">
            If depression is interfering with daily life or causing suicidal thoughts, seeking help from a mental health professional is essential. They can provide therapy, medication, or other treatments to help manage the condition.
          </p>
          <div className="flex flex-col mt-4 sm:flex-row justify-center lg:justify-start items-center gap-4">
  {/* Book an Appointment Button */}
  <button
    className="btn w-full sm:w-auto border-none bg-green-600 text-white rounded hover:bg-green-700  font-montserrat flex items-center justify-center sm:justify-start"
    onClick={() => handleNavigation('/Contact')}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      className="h-5 w-5 mr-1"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"
      />
    </svg>
    Book Virtual Appointment
  </button>
</div>

        </div>
      </div>
    </div>
  </section>
  )
}

export default DepressionDetails