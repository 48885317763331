import React, { useEffect, useState } from 'react';
import { FaEdit, FaTrash } from 'react-icons/fa';
import Swal from 'sweetalert2';
import { FiSearch } from 'react-icons/fi';
import { AiFillMessage } from "react-icons/ai";
import noappointment from '../../../Assets/noappointment.svg'
import { saveAs } from 'file-saver';


function AdminAppointment() {
  const [appointments, setAppointments] = useState([]);
  const [searchQuery, setSearchQuery] = useState(''); // New state for search
  const [currentAppointmentId, setCurrentAppointmentId] = useState(null);
  const [editFormData, setEditFormData] = useState({
    fullName: '',
    phoneNumber: '',
    emailAddress: '',
    age: '',
  });
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20; // Number of items to show per page

  useEffect(() => {
    fetchAppointments();
  }, []);

  /**
   * The fetchAppointments function fetches appointment data from a specified URL and sets the
   * retrieved data to the Appointments state variable, while the handleDelete function prompts the
   * user with a confirmation dialog before proceeding with the deletion of a specific appointment by
   * its ID.
   */
  const fetchAppointments = () => {
    fetch("https://phoebe-compass-point-560accfa31d6.herokuapp.com/appointments")
      .then((response) => response.json())
      .then((data) => setAppointments(data))
      .catch((error) => console.error("Error fetching appointments:", error));
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "This will permanently delete the appointment.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        fetch(`https://phoebe-compass-point-560accfa31d6.herokuapp.com/delete_Appointment/${id}`, {
          method: 'DELETE',
        })
          .then((response) => response.json())
          .then(() => {
            fetchAppointments(); // Refresh the list
            Swal.fire('Deleted!', 'The appointment has been deleted.', 'success');
          })
          .catch((error) => console.error("Error deleting appointment:", error));
      }
    });
  };

  const handleEdit = (appointment) => {
    setCurrentAppointmentId(appointment.AppointmentID);
    setEditFormData({
      fullName: appointment.FullName,
      phoneNumber: appointment.PhoneNumber,
      emailAddress: appointment.EmailAddress,
      age: appointment.Age,
    });
    setIsModalOpen(true); // Open the modal
  };

  const handleUpdate = () => {
    const { fullName, phoneNumber, emailAddress, age } = editFormData;
  
    if (!fullName || !phoneNumber || !emailAddress || !age) {
      Swal.fire('Error!', 'Please fill out all fields.', 'error');
      return;
    }
  
    fetch(`https://phoebe-compass-point-560accfa31d6.herokuapp.com/update_Appointment/${currentAppointmentId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        FullName: fullName,
        PhoneNumber: phoneNumber,
        EmailAddress: emailAddress,
        Age: age,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then(err => {
            throw new Error(err.message || 'Failed to update appointment');
          });
        }
        return response.json();
      })
      .then((data) => {
        if (data.success) {
          fetchAppointments(); // Refresh the list of appointments
          setIsModalOpen(false); // Close the modal
          Swal.fire('Updated!', 'The appointment has been updated.', 'success');
        }
      })
      .catch((error) => {
        console.error("Error updating appointment:", error);
        Swal.fire('Error!', error.message || 'There was an error updating the appointment.', 'error');
      });
  };
  
  // Filtered appointments based on search query
  const filteredAppointments = appointments.filter((appointment) =>
    appointment.FullName.toLowerCase().includes(searchQuery.toLowerCase()) ||
    appointment.EmailAddress.toLowerCase().includes(searchQuery.toLowerCase()) ||
    appointment.PhoneNumber.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Pagination calculations
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentAppointments = filteredAppointments.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredAppointments.length / itemsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };


  const handleSendSMS = (phoneNumber) => {
    const message = "PhoebeCompassPoint A Mental health partner Visit Our Site https://phoebecompasspoint.com";
  
    fetch("https://phoebe-compass-point-560accfa31d6.herokuapp.com/send-sms", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ message, to: phoneNumber }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          Swal.fire('Success!', 'Message sent successfully.', 'success');
        } else {
          Swal.fire('Error!', 'Failed to send the message.', 'error');
        }
      })
      .catch((error) => {
        console.error("Error sending SMS:", error);
        Swal.fire('Error!', 'An error occurred while sending the message.', 'error');
      });
  };

 // Function to download CSV
 const downloadCSV = () => {
  const csvData = [
    ["Appointment ID", "Full Name", "Age", "Phone Number", "Email Address"],
    ...filteredAppointments.map(appointment => [
      appointment.AppointmentID,
      appointment.FullName,
      appointment.Age,
      appointment.PhoneNumber,
      appointment.EmailAddress,
    ]),
  ];
  
  const csvContent = csvData.map(e => e.join(",")).join("\n");
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  saveAs(blob, 'appointments.csv'); // Triggering download
};

const isNewAppointment = (appointmentTime) => {
  const appointmentDate = new Date(appointmentTime);
  const now = new Date();
  const diffInHours = (now - appointmentDate) / (1000 * 60 * 60);
  return diffInHours < 5;
};


  return (
    <div className="container mt-8 mx-auto p-6">
      {/* Table header with search bar */}
      <div className="flex items-center justify-between mb-4">
        <h1 className="text-3xl font-bold text-gray-800">Registered Client for Initial Appointment</h1>
        <div className="relative">
          <input
            type="text"
            className="pl-10 pr-4 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Search by name or email"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <FiSearch className="absolute left-3 top-2 h-5 w-5 text-gray-400" />
        </div>
      </div>

      {/* Table to display appointments */}
      <div className="overflow-x-auto rounded-lg">
        <table className="min-w-full border-collapse border-2 border-gray-200">
          <thead className="bg-gray-100 font-serif">
          <tr>
              {['Appointment ID', 'Full Name', 'Age', 'Phone Number', 'Email Address', 'Date', 'Time','Message', 'Actions'].map(header => (
                <th key={header} className="border border-gray-300 px-4 py-3 text-left text-sm font-semibold text-gray-600">
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {currentAppointments.length > 0 ? (
              currentAppointments.map((appointment) => (
                <tr key={appointment.AppointmentID} className="hover:bg-gray-50 transition duration-150 font-montserrat">
                  <td className="border border-gray-200 px-4 py-2 text-sm text-gray-700">{appointment.AppointmentID}</td>
                  <td className="border border-gray-200 px-4 py-2 text-sm text-gray-700">
                    {appointment.FullName}
                    {isNewAppointment(appointment.created_at) && (
                      <span className="ml-2 px-2 py-1 text-xs font-semibold text-white bg-red-500 rounded">
                        New
                      </span>
                    )}
                  </td>
                  <td className="border border-gray-200 px-4 py-2 text-sm text-gray-700">{appointment.Age}</td>
                  <td className="border border-gray-200 px-4 py-2 text-sm text-gray-700">{appointment.PhoneNumber}</td>
                  <td className="border border-gray-200 px-4 py-2 text-sm text-gray-700">{appointment.EmailAddress}</td>
                  <td className="border border-gray-200 px-4 py-2 text-sm text-gray-700">{appointment.Date}</td>
                  <td className="border border-gray-200 px-4 py-2 text-sm text-gray-700">{appointment.Time}</td>
                  <td className="border border-gray-200 px-4 py-2 text-sm text-gray-700">{appointment.Message}</td>
                  <td className="border border-gray-200 px-4 py-2 text-center">
                  <div className="flex justify-between space-x-3">
                  <button
                        className="bg-green-500 text-white px-2 py-1 rounded-md hover:bg-green-600 transition duration-150"
                        onClick={() => handleSendSMS(appointment.PhoneNumber)}
                      >
                        <AiFillMessage />
                      </button>

  <button
    className="bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300 rounded-md px-3 py-1 transition ease-in-out flex items-center"
    title="Edit Appointment"
    onClick={() => handleEdit(appointment)}
  >
    <FaEdit />
  </button>

  <button
    className="bg-red-500 text-white hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-300 rounded-md px-3 py-1 transition ease-in-out flex items-center"
    title="Delete Appointment"
    onClick={() => handleDelete(appointment.AppointmentID)}
  >
    <FaTrash />
  </button>
</div>


                  </td>
                </tr>
              ))
            ) : (
              <tr>
              <td colSpan="6" className="border border-gray-200 px-4 py-4 text-sm text-center text-gray-700">
  <img
    src={noappointment} // Ensure this path is correct
    alt="No appointments found"
    className="max-w-full max-h-24 mx-auto mb-2 object-contain" // Adjust max height as needed
  />
  <span className="font-montserrat">No appointments found.</span>
</td>


              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* Pagination controls */}
      <div className="flex justify-between items-center mt-2">
      <div className="inline-flex justify-center gap-1">
        <button
          onClick={handlePrevPage}
          disabled={currentPage === 1}
          className={`inline-flex size-8 items-center justify-center rounded border border-gray-100 bg-white text-gray-900 ${currentPage === 1 ? 'cursor-not-allowed opacity-50' : ''}`}
        >
          <span className="sr-only">Prev Page</span>
          <svg xmlns="http://www.w3.org/2000/svg" className="size-3" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
          </svg>
        </button>

        <p className="rounded border border-gray-100 bg-white text-sm font-medium text-gray-900 flex items-center justify-center w-8">
          {currentPage}
        </p>

        <button
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
          className={`inline-flex size-8 items-center justify-center rounded border border-gray-100 bg-white text-gray-900 ${currentPage === totalPages ? 'cursor-not-allowed opacity-50' : ''}`}
        >
          <span className="sr-only">Next Page</span>
          <svg xmlns="http://www.w3.org/2000/svg" className="size-3" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </button>
      </div>
        <button onClick={downloadCSV} className="bg-green-500 text-white hover:bg-green-600 px-4 py-2 rounded-md">
         Export Data as CSV
        </button>
      </div>




     {/* Modal for editing appointments */}
     {isModalOpen && (
  <div className="modal modal-open">
    <div className="modal-box rounded-none">
      <button
        className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
        onClick={() => setIsModalOpen(false)} // Close modal on click
      >
        ✕
      </button>
      <h3 className="font-bold text-lg">Edit Appointment Details</h3>
      <div className="mt-4">
        <label className="block">Full Name</label>
        <input
          type="text"
          value={editFormData.fullName}
          onChange={(e) =>
            setEditFormData({ ...editFormData, fullName: e.target.value })
          }
          className="input input-bordered w-full"
        />
      </div>

      <div className="mt-4">
        <label className="block">Phone Number</label>
        <input
          type="text"
          value={editFormData.phoneNumber}
          onChange={(e) =>
            setEditFormData({ ...editFormData, phoneNumber: e.target.value })
          }
          className="input input-bordered w-full"
        />
      </div>

      <div className="mt-4">
        <label className="block">Email Address</label>
        <input
          type="email"
          value={editFormData.emailAddress}
          onChange={(e) =>
            setEditFormData({ ...editFormData, emailAddress: e.target.value })
          }
          className="input input-bordered w-full"
        />
      </div>

      <div className="mt-4">
        <label className="block">Age</label>
        <input
          type="number"
          value={editFormData.age}
          onChange={(e) => setEditFormData({ ...editFormData, age: e.target.value })}
          className="input input-bordered w-full"
        />
      </div>

      <div className="modal-action flex align-center justify-center">
        <button
          className="btn btn-success text-white rounded-none"
          onClick={handleUpdate}
        >
          Save Appointment Details
        </button>
      </div>
    </div>
  </div>
)}
    </div>
  );
}

export default AdminAppointment;
