import InitialAppointment from '../../components/WebsiteComponents/InitialAppointment';
import Services from '../../components/WebsiteComponents/Services';

export const ServicesPage = () => {
    return (
        <> 
            <Services />
            <InitialAppointment/>
        </>
    );
}
