import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TrumaDetails from '../../../Assets/TrumaDetails.jpg'; // Consider changing this image to one relevant to trauma/PTSD

function TraumaPTSDDetails() {
    const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
  };

  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  const handleVideoPlay = () => {
    setIsVideoPlaying(true);
  };
  return (
    <section className="py-10 bg-white sm:py-16 lg:py-24">
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="grid items-center grid-cols-1 lg:grid-cols-2 gap-x-12 xl:gap-x-24 gap-y-12">
          <div className="relative lg:mb-12">
            <img
              className="absolute -right-0 -bottom-8 xl:-bottom-12 xl:-right-4"
              src="https://cdn.rareblocks.xyz/collection/celebration/images/content/3/dots-pattern.svg"
              alt=""
            />
            <div className="pl-12 pr-6">
              <img className="relative rounded-md" src={TrumaDetails} alt="" />
            </div>
          </div>

          <div className="2xl:pl-16">
            <h2 className="text-xl font-bold text-black sm:text-2xl lg:text-3xl font-serif">What Is Trauma and PTSD?</h2>
            <p className="text-md font-normal text-gray-600 font-montserrat mt-4">
              Trauma refers to an emotional response to a deeply distressing or disturbing event, such as violence, accidents, or natural disasters. Post-Traumatic Stress Disorder (PTSD) is a mental health condition that may develop after experiencing or witnessing a traumatic event.
            </p>
            <p className="mt-6 text-md font-normal text-gray-600 font-montserrat">
              PTSD can lead to symptoms like intrusive thoughts, avoidance, negative mood, and heightened arousal. It is important to understand the impact of trauma and seek appropriate help when needed.
            </p>
          </div>
        </div>
      </div>

      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {/* Left Column */}
          <div className="bg-white p-6 rounded-lg">
            <h2 className="text-2xl font-bold mb-4 font-serif">How Is Trauma and PTSD Important?</h2>
            <p className="text-md font-normal text-gray-600 font-montserrat mb-6 ">
              Trauma can lead to lasting emotional and psychological issues, affecting relationships, work, and overall well-being. Recognizing and addressing PTSD is crucial for recovery and maintaining a healthy life.
            </p>

            <h2 className="text-2xl font-bold mb-4 font-serif">Common Symptoms of PTSD:</h2>
            <ul className="list-disc list-inside text-md font-normal text-gray-600 font-montserrat space-y-2">
              <li>Intrusive memories or flashbacks of the traumatic event.</li>
              <li>Avoidance of reminders or discussions about the trauma.</li>
              <li>Negative thoughts or feelings about oneself or others.</li>
              <li>Heightened reactions, irritability, or difficulty sleeping.</li>
              <li>Physical symptoms like increased heart rate or sweating when reminded of the trauma.</li>
            </ul>

            <h2 className="text-2xl mt-4 font-bold mb-4 font-serif">Causes of PTSD:</h2>
            <p className="text-md font-normal text-gray-600 font-montserrat mb-6">
              PTSD can result from various traumatic experiences. Common causes include:
            </p>
            <ul className="list-disc list-inside text-md font-normal text-gray-600 font-montserrat space-y-2">
              <li>Exposure to violence, accidents, or natural disasters.</li>
              <li>Childhood abuse or neglect.</li>
              <li>Loss of a loved one or sudden bereavement.</li>
              <li>Combat exposure or military experiences.</li>
              <li>Survivor's guilt from a traumatic event.</li>
            </ul>

            <div className="mt-6">
              <h2 className="text-2xl font-bold mb-4 font-serif">Watch this video to learn more:</h2>

              {/* Show the thumbnail before the video is played */}
              {!isVideoPlaying ? (
                <div
                  className="relative cursor-pointer"
                  style={{ paddingBottom: '56.25%', height: 0, overflow: 'hidden', maxWidth: '100%', position: 'relative' }}
                  onClick={handleVideoPlay}
                >
                  {/* YouTube video thumbnail */}
                  <img
                    className="absolute top-0 left-0 w-full h-full object-cover rounded-lg"
                    src="https://img.youtube.com/vi/oIa4RV91T7Q/maxresdefault.jpg"
                    alt="Video Thumbnail"
                  />
                  {/* Play button overlay */}
                  <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="white"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      className="h-16 w-16 opacity-75"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5.25 5.25l13.5 6.75-13.5 6.75V5.25z"
                      />
                    </svg>
                  </div>
                </div>
              ) : (
                // Show the iframe once the user clicks to play the video
                <div className="relative" style={{ paddingBottom: '56.25%', height: 0, overflow: 'hidden', maxWidth: '100%', position: 'relative' }}>
                  <iframe
                    className="absolute top-0 left-0 w-full h-full"
                    src="https://www.youtube.com/embed/oIa4RV91T7Q?autoplay=1"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                  ></iframe>
                </div>
              )}
            </div>
          </div>
          {/* Right Column */}
          <div className="bg-white p-6 rounded-lg">
            <h2 className="text-2xl font-bold mb-4 font-serif">Techniques for Coping with PTSD:</h2>
            <ul className="list-disc list-inside text-md font-normal text-gray-600 font-montserrat space-y-2 mb-6">
              <li>
                <strong>Deep Breathing:</strong> Practicing slow, deep breaths to reduce anxiety and manage stress.
              </li>
              <li>
                <strong>Mindfulness and Grounding Techniques:</strong> Engaging in mindfulness exercises helps increase awareness and control over reactions.
              </li>
              <li>
                <strong>Physical Exercise:</strong> Regular exercise can help alleviate symptoms and improve overall mental health.
              </li>
              <li>
                <strong>Support Groups:</strong> Sharing experiences with others who understand can provide comfort and insight.
              </li>
              <li>
                <strong>Creative Expression:</strong> Activities like art or writing can help process emotions and experiences.
              </li>
            </ul>

            <h2 className="text-2xl font-bold mb-4 font-serif">Therapies for PTSD:</h2>
            <ul className="list-disc list-inside text-md font-normal text-gray-600 font-montserrat space-y-2">
              <li>
                <strong>Cognitive Behavioral Therapy (CBT):</strong> Helps identify and change negative thought patterns and behaviors related to trauma.
              </li>
              <li>
                <strong>Eye Movement Desensitization and Reprocessing (EMDR):</strong> Focuses on processing traumatic memories through guided eye movements.
              </li>
              <li>
                <strong>Group Therapy:</strong> Provides a safe environment to share and learn from others experiencing similar trauma.
              </li>
              <li>
                <strong>Medication:</strong> Antidepressants and anti-anxiety medications may be prescribed to help alleviate symptoms.
              </li>
            </ul>

            <h2 className="text-2xl font-bold mt-6 font-serif">When to Seek Professional Help?</h2>
            <p className="text-md font-normal text-gray-600 font-montserrat">
              If trauma-related symptoms interfere with daily life or relationships, it is important to seek help from a mental health professional. Effective treatments are available to help manage PTSD and promote recovery.
            </p>

            <div className="flex flex-col mt-4 sm:flex-row justify-center lg:justify-start items-center gap-4">
  {/* Book an Appointment Button */}
  <button
    className="btn w-full sm:w-auto border-none bg-green-600 text-white rounded hover:bg-green-700  font-montserrat flex items-center justify-center sm:justify-start"
    onClick={() => handleNavigation('/Contact')}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      className="h-5 w-5 mr-1"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"
      />
    </svg>
    Book Virtual Appointment
  </button>
</div>

          </div>
        </div>
      </div>
    </section>
  )
}

export default TraumaPTSDDetails