import TraumaPTSD from "../../../components/WebsiteComponents/ServicesComponent/TraumaPTSD";
import TraumaPTSDDetails from "../../../components/WebsiteComponents/ServicesComponent/TraumaPTSDDetails";

export const TrumaandPTSDPage = () => {
    return (
        <> 
   <TraumaPTSD/>
   <TraumaPTSDDetails/>
        </>
    );
}
export default TrumaandPTSDPage;