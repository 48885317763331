import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faCalendarAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import Calendar from 'react-calendar';  // Import the calendar component
import 'react-calendar/dist/Calendar.css'; // Import calendar styles

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function Dashboard() {
  const [totalVisitors, setTotalVisitors] = useState(0);
  const [newVisitors, setNewVisitors] = useState(0);
  const [returningVisitors, setReturningVisitors] = useState(0);
  const [appointmentCount, setAppointmentCount] = useState(0);
  const [newsletterCount, setNewsletterCount] = useState(0);
  const [visitorCount, setVisitorCount] = useState(0);
  
  // State for calendar date selection
  const [selectedDate, setSelectedDate] = useState(new Date());

  const [chartData, setChartData] = useState({
    labels: ['Appointments', 'Registered Emails', 'Visitor Count'],
    datasets: [
      {
        label: 'Website Monitoring data ',
        data: [],
        backgroundColor: [
          'rgba(75, 192, 192, 0.5)', // Appointments
          'rgba(255, 99, 132, 0.5)', // Registered Emails
          'rgba(54, 162, 235, 0.5)' // Visitor Count
        ],
        borderColor: [
          'rgb(75, 192, 192)', // Appointments
          'rgb(255, 99, 132)', // Registered Emails
          'rgb(54, 162, 235)' // Visitor Count
        ],
        borderWidth: 1,
      }
    ],
  });

  useEffect(() => {
    const fetchVisitorCounts = async () => {
      const totalVisitors = 5000;
      const newVisitors = 350;
      const returningVisitors = 4650;

      setTotalVisitors(totalVisitors);
      setNewVisitors(newVisitors);
      setReturningVisitors(returningVisitors);
    };

    const fetchCounts = async () => {
      try {
        const response = await axios.get('https://phoebe-compass-point-560accfa31d6.herokuapp.com/api/counts');
        setAppointmentCount(response.data.appointments);
        setNewsletterCount(response.data.newsletters);
        setVisitorCount(response.data.visitors);

        // Update chart data based on fetched counts
        setChartData(prevData => ({
          ...prevData,
          datasets: prevData.datasets.map((dataset) => ({
            ...dataset,
            data: [
              response.data.appointments,
              response.data.newsletters,
              response.data.visitors
            ]
          })),
        }));
      } catch (error) {
        console.error('Error fetching counts:', error);
      }
    };

    fetchVisitorCounts();
    fetchCounts();
  }, []);

  const chartOptions = {
    responsive: true,
    scales: {
      x: {
        beginAtZero: true,
      },
      y: {
        beginAtZero: true,
      }
    }
  };

  return (
    <div className="p-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      {/* Total Visitors */}
      <div className="bg-white shadow-lg rounded-lg p-5 hover:shadow-xl transition-shadow">
        <div className="flex items-center ">
          <FontAwesomeIcon icon={faUsers} className="text-gray-500 bg-yellow-100 px-4 py-4 rounded-full text-2xl mr-4" />
          <h3 className="text-lg font-semibold ">Total Website Visitors</h3>
        </div>
        <p className="text-3xl font-bold mt-2">{visitorCount}</p>
        <p className="text-green-500 mt-1">+12% from last month</p>
      </div>

      {/* Appointments */}
      <div className="bg-white shadow-lg rounded-lg p-5 hover:shadow-xl transition-shadow">
        <div className="flex items-center">
          <FontAwesomeIcon icon={faCalendarAlt} className="text-gray-500 bg-blue-100 px-4 py-4 rounded-full text-2xl mr-4" />
          <h3 className="text-lg font-semibold ">Appointments</h3>
        </div>
        <p className="text-3xl font-bold mt-2">{appointmentCount}</p>
        <p className="text-green-500 mt-1">+5% from last month</p>
      </div>

      {/* Registered Email for Newsletter */}
      <div className="bg-white shadow-lg rounded-lg p-5 hover:shadow-xl transition-shadow">
        <div className="flex items-center">
          <FontAwesomeIcon icon={faEnvelope} className="text-gray-500 bg-red-100 px-4 py-4 rounded-full text-2xl mr-4" />
          <h3 className="text-lg font-semibold ">Registered Emails for Newsletter</h3>
        </div>
        <p className="text-3xl font-bold mt-2">{newsletterCount}</p>
        <p className="text-blue-500 mt-1">+3% from last month</p>
      </div>

      {/* Chart Section */}
      <div className="col-span-2 bg-white shadow-lg rounded-lg p-5 hover:shadow-xl transition-shadow">
        <h3 className="text-lg font-semibold mb-4">Counts Overview</h3>
        <Bar data={chartData} options={chartOptions} />
      </div>

      {/* Full Calendar Section */}
      <div className="bg-white shadow-lg rounded-lg p-5 hover:shadow-xl transition-shadow">
        <h3 className="text-lg font-semibold mb-4">Calendar</h3>
        <div className="max-w-xs mx-auto md:max-w-full">
          <Calendar 
            onChange={setSelectedDate} 
            value={selectedDate} 
            className="w-full"
          />
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
