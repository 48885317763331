
import FormSuccess from '../../components/WebsiteComponents/Success';

export const Success = () => {
    return (
        <> 
            <FormSuccess />
        </>
    );
}
